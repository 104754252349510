import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import logo from "../../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  item: {
    marginBottom: theme.spacing(3),
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
  },
}));

export default function Privacy() {
  const classes = useStyles();

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Container maxWidth="md">
          <Toolbar>
            <img alt="logo" src={logo} style={{ width: 100 }} />
            <Typography variant="h6" className={classes.title}>
              Inflaate
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>

      <Container maxWidth="md">
        <Typography variant="h4" className={classes.title}>
          Privacy Policy
        </Typography>

        <Typography className={classes.title}>
          Your privacy is important to us. It is Inflaate's policy to respect
          your privacy regarding any information we may collect from you across
          our website, http://inflaate.com, and other sites we own and operate.
        </Typography>

        <Typography className={classes.item}>
          We only ask for personal information when we truly need it to provide
          a service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we’re collecting it
          and how it will be used.
        </Typography>
        <Typography className={classes.item}>
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we’ll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorized access, disclosure, copying, use or
          modification.
        </Typography>
        <Typography className={classes.item}>
          We don’t share any personally identifying information publicly or with
          third-parties, except when required to by law.
        </Typography>
        <Typography className={classes.item}>
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and practices
          of these sites, and cannot accept responsibility or liability for
          their respective privacy policies.
        </Typography>
        <Typography className={classes.item}>
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          your desired services.
        </Typography>
        <Typography className={classes.item}>
          Your continued use of our website will be regarded as acceptance of
          our practices around privacy and personal information. If you have any
          questions about how we handle user data and personal information, feel
          free to contact us.
        </Typography>
        <Typography className={classes.item}>
          This policy is effective as of 20 July 2020.
        </Typography>
      </Container>
    </>
  );
}
