import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Report from "../components/Report";
import AutoComplete from "../components/Auto";
import * as dateFns from "date-fns";
import { useLazyQuery, API, useQuery } from "../../../api";
import { formatMoney } from "../../../utils/formatters";

const scheme = {
  name: "scheme 1",
  items: [
    { from: 0, to: 5000, percentage: 1.5 },
    { from: 5000, to: 10000, percentage: 2.5 },
  ],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    width: "60%",
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 0),
  },
  text: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}));

export default function OperatorReport() {
  const [getReport, { data }] = useLazyQuery(API.GET_OPERATOR_REPORT, {
    fetchPolicy: "network-only",
  });
  const { data: operators } = useQuery(API.GET_SALES_REPS);

  const salesOrders = data?.salesOrders;
  const invoices = data?.invoices;
  const payments = data?.payments;
  const chequeReturns = data?.chequeReturns;
  const balance = payments - chequeReturns;

  const operatorOptions = React.useMemo(
    () =>
      operators?.result?.map((t) => ({
        title: t.name,
        subtitle: t.code,
        value: t._id,
      })) ?? [],
    [operators]
  );

  const classes = useStyles();

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
    operator: "",
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const onOperatorChange = (e) => {
    setFilters({ ...filters, operator: e?.value });
  };

  const getCommision = (total) => {
    const result = [];

    for (let item of scheme.items) {
      if (total >= item.to) {
        let value = (item.to * item.percentage) / 100;
        result.push({ ...item, value });
      } else {
        let value = ((total - item.to) * item.percentage) / 100;
        result.push({ ...item, value });
        break;
      }
    }

    return result;
  };

  getCommision();

  return (
    <Report
      title="Operator Report"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
    >
      <AutoComplete
        label="Select Operator"
        options={operatorOptions}
        value={filters.operator}
        onChangeComplete={onOperatorChange}
        width={300}
      />

      <div className={classes.root}>
        <Paper className={classes.content}>
          <Item label="Sales Orders" value={formatMoney(salesOrders)} />
          <Divider />
          <Item label="Invoices" value={formatMoney(invoices)} />
          <Divider />
          <Item label="Payments" value={formatMoney(payments)} />
          <Divider />
          <Item
            label="(-) Cheque Returns"
            value={`(${formatMoney(chequeReturns)})`}
          />
          <Divider />
          <Item label="Balance" value={formatMoney(balance)} />
        </Paper>
      </div>
    </Report>
  );
}

function Item({ label, value }) {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <Typography>{label}</Typography>
      <Typography>{value}</Typography>
    </div>
  );
}
