import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useField } from "formik";
import ButtonBase from "@material-ui/core/ButtonBase";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 50,
  },
  item: {
    display: "flex",
    borderRadius: 100,
    overflow: "hidden",
    margin: theme.spacing(0, 1),
    height: 40,
    width: 40,
  },
  half: {
    height: 40,
    width: 20,
  },
}));

export default function ThemePicker({
  options = [],
  onChange,
  disabled,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = 600,
  ...props
}) {
  const classes = useStyles();

  const [{ value }, , { setValue }] = useField(props);

  const onClick = (option) => {
    setValue(option?.value);
    onChange?.(option);
  };

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <div className={classes.root}>
        {options?.map((option, index) => (
          <ButtonBase
            key={index}
            className={classes.item}
            style={{ border: option?.value === value && "3px solid #8FC7F8" }}
            onClick={() => onClick(option)}
          >
            <div
              className={classes.half}
              style={{ background: option?.main }}
            />
            <div
              className={classes.half}
              style={{ background: option?.accent }}
            />
          </ButtonBase>
        ))}
      </div>
    </div>
  );
}
