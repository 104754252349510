import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useField } from "formik";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 1,
  },
  input: {
    width: 200,
  },
}));

export default function Input({ label, ...props }) {
  const [{ value, onChange }] = useField(props);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span>{label}</span>
      <input
        {...props}
        value={value}
        onChange={onChange}
        className={classes.input}
      ></input>
    </div>
  );
}
