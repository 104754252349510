import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
// import Typography from "@material-ui/core/Typography";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  icon: {
    fontSize: 40,
  },
}));

export default function ImagePicker({
  label,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = undefined,
  hint,
  onPicked,
  ...props
}) {
  const classes = useStyles();

  const [{ value }, , { setValue }] = useField(props);

  const onDrop = React.useCallback(
    ([File]) => {
      setValue(File);
      onPicked?.(File);
    },
    [setValue, onPicked]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getBackgroundImage = () => {
    if (!value) return undefined;

    if (typeof value === "string") {
      return `url(${value})`;
    } else if (typeof value === "object") {
      return `url(${URL.createObjectURL(value)})`;
    }
    return undefined;
  };

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <ButtonBase
        focusRipple
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{ width: 200 }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <span
          className={classes.imageSrc}
          style={{ backgroundImage: getBackgroundImage() }}
        />
        <span className={classes.imageBackdrop} />
        <AddAPhotoIcon className={classes.icon} />
      </ButtonBase>
    </div>
  );
}
