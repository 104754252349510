import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rowSpace: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  col1: {
    width: 340,
    marginRight: theme.spacing(12),
  },
  col2: {
    width: 400,
  },
  item: {
    width: 300,
  },
}));

export default function Usage({
  label,
  caption,
  limit = 500,
  duration,
  usage = 0,
}) {
  const classes = useStyles();

  const percentage = (usage * 100) / limit;
  const formattedPercentage = `${percentage.toFixed(0)}%`;

  const _limit = duration ? `${limit} / ${duration} *` : `${limit} *`;

  return (
    <div className={classes.root}>
      <div className={classes.col1}>
        <div className={classes.rowSpace}>
          <div className={classes.item}>
            <Typography variant="body1">{label}</Typography>
            <Typography variant="caption">{caption}</Typography>
          </div>
          <Typography variant="h6">{formattedPercentage}</Typography>
        </div>
      </div>

      <div className={classes.col2}>
        <div className={classes.rowSpace}>
          <Typography variant="caption">{usage}</Typography>
          <Typography variant="caption">{_limit}</Typography>
        </div>
        <LinearProgress
          variant="determinate"
          value={Math.min(100, percentage)}
        />
      </div>
    </div>
  );
}
