import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.spacing(2),
  },
  menuButton: {
    marginLeft: theme.spacing(0.1),
    color: "#333",
  },
}));

export default function Header({ title, actions = [] }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DialogTitle>{title}</DialogTitle>

      <div>
        {actions.map((action, index) => (
          <Tooltip key={index} title={action?.tooltip}>
            <span>
              <IconButton
                color="inherit"
                edge="start"
                onClick={action.onClick}
                disabled={action.disabled}
                className={classes.menuButton}
              >
                <Icon className={classes.icon}>{action.icon}</Icon>
              </IconButton>
            </span>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
