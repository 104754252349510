import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Input, AutoComplete } from ".";
import { formatMoney } from "../../utils/formatters";
import { getAmount } from "../../utils/orderUtils";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "180px auto 140px 140px 140px 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
    minHeight: 40,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
  selection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      background: "#EFEFEF",
      cursor: "pointer",
    },
  },
}));

export default function MROItems({
  push,
  remove,
  form,
  products = [],
  priceKey,
}) {
  const classes = useStyles();

  const onProductSelected = (product, index) => {
    form.setFieldValue(`items[${index}].cost`, product?.cost);
    form.setFieldValue(`items[${index}].salesPrice`, product?.salesPrice);
    form.setFieldValue(`items[${index}].required`, true);
  };

  const getValue = (item, key) => {
    const product = products.find((t) => t._id === item.product);
    return _.get(product, key) ?? "";
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button">Product Code</Typography>
        <Typography variant="button">Description</Typography>
        <Typography variant="button" className={classes.right}>
          Cost
        </Typography>
        <Typography variant="button" className={classes.center}>
          Qty
        </Typography>
        <Typography variant="button" className={classes.right}>
          Amount
        </Typography>
      </div>

      <Divider />

      {form.values?.items?.map((item, index) => (
        <React.Fragment key={index}>
          <div className={classes.container}>
            <AutoComplete
              name={`items[${index}].product`}
              options={products}
              onChangeComplete={(e) => onProductSelected(e, index)}
              bottom={0}
            />
            <Typography>{getValue(item, "description")}</Typography>

            <Typography className={classes.right}>
              {formatMoney(getValue(item, "cost"))}
            </Typography>

            <Input name={`items[${index}].quantity`} type="number" bottom={0} />

            <Typography className={classes.right}>
              {formatMoney(getAmount(item, priceKey))}
            </Typography>

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => remove(index)}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </div>

          <Divider />
        </React.Fragment>
      ))}

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() => push({ quantity: 0 })}
          startIcon={<AddIcon />}
        >
          Add Lines
        </Button>
      </div>
    </div>
  );
}
