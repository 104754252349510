import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import Icon from "@material-ui/core/Icon";
import SearchBar from "../SearchBar/SearchBar";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { filter } from "../../utils/filters";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  bar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    margin: theme.spacing(1, 0),
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "auto",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(0, 2),
    borderBottom: "1px solid #E4E4E4",
    "&:hover": {
      cursor: "pointer",
      background: "#f1f2f3",
    },
  },
  itemSelected: {
    background: "#ececec",
  },
  leftIcon: {
    marginRight: theme.spacing(2),
    color: "#fff",
    borderRadius: 100,
    fontSize: 28,
  },
  rightIcon: {
    color: theme.palette.success.main,
    fontSize: 32,
  },
}));

export default function SelectList({
  options,
  value,
  onChange,
  showSearchBar = false,
  multiple = false,
  rowHeight = 64,
}) {
  const classes = useStyles();

  const [query, setQuery] = React.useState("");
  const [selectedArr, setSelectedArr] = React.useState(() =>
    Array.isArray(value) ? value : [value]
  );

  const selectedCount = selectedArr.length;

  const filteredOptions = filter(options, query);
  const itemCount = filteredOptions?.length ?? 0;

  const onSelect = (index) => {
    const option = filteredOptions?.[index];

    if (selectedArr?.includes(option.value)) {
      const x = selectedArr.filter((t) => t !== option.value);
      setSelectedArr(x);
      onChange?.(x);
    } else {
      if (multiple) {
        const x = [...selectedArr, option.value];
        setSelectedArr(x);
        onChange?.(x);
      } else {
        const x = [option.value];
        setSelectedArr(x);
        onChange?.(x?.[0]);
      }
    }
  };

  const Row = ({ index, style }) => {
    const option = filteredOptions?.[index];

    const isSelected = selectedArr?.includes(option.value);

    return (
      <div style={style} onClick={() => onSelect(index)}>
        <div className={clsx(classes.item, isSelected && classes.itemSelected)}>
          {Boolean(option?.leftIcon) && (
            <Icon
              className={classes.leftIcon}
              style={{ background: option?.leftIconColor }}
            >
              {option?.leftIcon}
            </Icon>
          )}
          <div style={{ flex: 1 }}>
            <Typography variant="body1">{option?.title}</Typography>
            <Typography variant="body2" color="textSecondary">
              {option?.subtitle}
            </Typography>
          </div>
          {isSelected && (
            <CheckCircleOutlineRoundedIcon className={classes.rightIcon} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {showSearchBar && (
        <Toolbar variant="regular" className={classes.bar}>
          <SearchBar onChange={setQuery} />

          <Typography variant="subtitle2" className={classes.text}>
            {selectedCount} Selected
          </Typography>
        </Toolbar>
      )}

      <div className={classes.list}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              itemCount={itemCount}
              itemSize={rowHeight}
              width={width}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}
