import { useCallback } from "react";
import { useDrop } from "react-dnd";
import { Box } from "./Box";
import update from "immutability-helper";

const styles = {
  position: "relative",
  border: "1px solid black",
};

export const Container = ({ config, onChange, onMouseDown, selectedKey }) => {
  const moveBox = useCallback(
    (id, left, top) => {
      onChange(update(config, { [id]: { $merge: { left, top } } }));
    },
    [config, onChange]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "box",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );

  return (
    <div ref={drop} style={{ ...styles, width: 467.5, height: 605 }}>
      {Object.keys(config).map((key) => {
        const { left, top, width, align, title } = config[key];
        return (
          <Box
            key={key}
            id={key}
            left={left}
            top={top}
            width={width}
            align={align}
            onMouseDown={() => onMouseDown?.(key)}
            selected={selectedKey === key}
          >
            {title}
          </Box>
        );
      })}
    </div>
  );
};
