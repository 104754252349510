import React from "react";
import { useField } from "formik";
import ReactMapGL, { Marker, GeolocateControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "steelblue",
    cursor: "pointer",
  },
  geoControl: {
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: 10,
    marginBottom: 40,
  },
}));

export default function LocationPicker({
  label,
  suggested,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = undefined,
  ...props
}) {
  const classes = useStyles();

  const [{ value }, meta, { setValue }] = useField(props);

  const [open, setOpen] = React.useState(false);

  const onPicked = (e) => {
    const _val = { type: "Point", coordinates: e };
    setValue(_val);
    setOpen(false);
  };

  const onPick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const lng = value?.coordinates?.[0]?.toFixed(5);
  const lat = value?.coordinates?.[1]?.toFixed(5);
  const text = lng && lat ? `[${lng}, ${lat}]` : "Set Location";

  const hasError = meta.touched && meta.error;

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <Link
        className={classes.link}
        style={{ color: hasError ? "#EE4123" : "steelblue" }}
        onClick={onPick}
      >
        <Typography>{text}</Typography>
      </Link>

      <Pop
        open={open}
        onClose={handleClose}
        value={value?.coordinates}
        onPicked={onPicked}
        suggested={suggested}
      />
    </div>
  );
}

function Pop({ open, onClose, value, onPicked, suggested }) {
  const [coords, setCoords] = React.useState(null);

  const onChange = (e) => {
    setCoords(e);
  };

  const onClick = () => {
    onPicked?.(coords);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <Map value={value} onChange={onChange} suggested={suggested} />
      <Button onClick={onClick} disabled={!coords}>
        Confirm
      </Button>
    </Dialog>
  );
}

function Map({ value = [], onChange, suggested }) {
  const classes = useStyles();

  const [viewport, setViewport] = React.useState(() => ({
    longitude: value?.[0] ?? 79.91698302222156,
    latitude: value?.[1] ?? 7.054404486998051,
    zoom: value?.length === 2 ? 13.5 : 9,
    bearing: 0,
    pitch: 0,
  }));
  const [coords, setCoords] = React.useState(null);

  React.useEffect(() => {
    if (!coords && value) setCoords(value);
  }, [coords, value]);

  const onClick = (e) => {
    setCoords(e.lngLat);
    onChange?.(e.lngLat);
  };

  const getCursor = ({ isHovering, isDragging }) => {
    return isDragging ? "grabbing" : isHovering ? "pointer" : "pointer";
  };

  const onDragEnd = React.useCallback((e) => {
    setCoords(e.lngLat);
    onChange?.(e.lngLat);
    // eslint-disable-next-line
  }, []);

  return (
    <ReactMapGL
      {...viewport}
      width="100%"
      height={400}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      onViewportChange={setViewport}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
      onClick={onClick}
      getCursor={getCursor}
    >
      {suggested && (
        <Marker
          longitude={suggested?.longitude}
          latitude={suggested?.latitude}
          offsetTop={-20}
          offsetLeft={-10}
        >
          <Pin size={20} color="#33AB55" />
        </Marker>
      )}
      {coords?.length === 2 && (
        <Marker
          longitude={coords?.[0]}
          latitude={coords?.[1]}
          offsetTop={-20}
          offsetLeft={-10}
          draggable
          onDragEnd={onDragEnd}
        >
          <Pin size={20} color="#d00" />
        </Marker>
      )}

      <GeolocateControl
        className={classes.geoControl}
        positionOptions={{ enableHighAccuracy: true }}
      />
    </ReactMapGL>
  );
}

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const Pin = React.memo(({ size = 20, color = "#d00" }) => {
  return (
    <svg
      height={size}
      viewBox="0 0 24 24"
      style={{ fill: color, stroke: "none" }}
    >
      <path d={ICON} />
    </svg>
  );
});
