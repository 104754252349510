import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    background: "#fff",
  },
  errorIcon: {
    color: "#EE4123",
  },
  errorText: {
    color: "#EE4123",
    marginRight: theme.spacing(2),
  },
}));

export default function Actions({
  newItem,
  onClose,
  dirty,
  error,
  isSubmitting,
  okLabel = "Save",
}) {
  const classes = useStyles();

  return (
    <DialogActions className={classes.root}>
      {Boolean(error) && (
        <>
          <ErrorOutlineIcon className={classes.errorIcon} />
          <Typography className={classes.errorText}>{error}</Typography>
        </>
      )}

      <Button color="primary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        disabled={!dirty || Boolean(error) || isSubmitting}
        color="primary"
        type="submit"
        endIcon={isSubmitting && <CircularProgress size={16} />}
      >
        {Boolean(okLabel) ? okLabel : newItem ? "Save" : "Update"}
      </Button>
    </DialogActions>
  );
}
