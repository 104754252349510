import React from "react";
import Report from "../components/Report";
import { useLazyQuery, API, useQuery } from "../../../api";
import * as dateFns from "date-fns";
import Table from "../../../components/VCTable";

export default function SalesDetails() {
  const [getReport, { data, loading }] = useLazyQuery(API.GET_SALES_DETAILS, {
    fetchPolicy: "network-only",
  });
  const { data: salesReps } = useQuery(API.GET_SALES_REPS);
  const { data: warehouses } = useQuery(API.GET_WAREHOUSES);

  const salesRepOptions = React.useMemo(
    () =>
      salesReps?.result?.map((t) => ({
        title: t?.name,
        value: t?._id,
      })) ?? [],
    [salesReps]
  );

  const warehouseOptions = React.useMemo(
    () =>
      warehouses?.result?.map((t) => ({
        title: t?.name,
        value: t?._id,
      })) ?? [],
    [warehouses]
  );

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
    salesRep: "",
    warehouse: "",
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const columns = [
    {
      name: "customerCode",
      label: "Code",
      options: { align: "center" },
    },
    {
      name: "customerName",
      label: "Customer Name",
      options: { align: "left", width: 300 },
    },
    {
      name: "count",
      label: "Invoice Count",
      options: { align: "center" },
    },
    {
      name: "total",
      label: "Sales",
      options: { align: "right", money: true, showTotal: true },
    },
    {
      name: "average",
      label: "Average Sale",
      options: { align: "right", money: true },
    },
  ];

  return (
    <Report
      title="Sales Details"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={data}
      columns={columns}
      filters={[
        {
          type: "range",
          name: "range",
          label: "Invoice Number Range",
          value: filters?.range,
        },
        {
          type: "autocomplete",
          name: "salesRep",
          label: "Sales Rep",
          options: salesRepOptions,
          value: filters?.salesRep,
        },
        {
          type: "autocomplete",
          name: "warehouse",
          label: "Warehouse",
          options: warehouseOptions,
          value: filters?.warehouse,
        },
      ]}
      onFiltersChange={(e) => setFilters((x) => ({ ...x, ...e }))}
    >
      <Table
        data={data ?? []}
        columns={columns}
        showTotals
        isLoading={loading}
        innerKey="items"
        innerColumns={[
          {
            name: "code",
            label: "Product Code",
            options: { align: "center" },
          },
          {
            name: "description",
            label: "Description",
            options: { align: "left", width: 300 },
          },
          {
            name: "salesPrice",
            label: "Price",
            options: { money: true },
          },
          {
            name: "quantity",
            label: "Quantity",
            options: { align: "center" },
          },
          {
            name: "discount",
            label: "Discount",
            options: { money: true },
          },
          {
            name: "amount",
            label: "Amount",
            options: { money: true },
          },
        ]}
      />
    </Report>
  );
}
