import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useQuery, API } from "../../api";

const drawerWidth = 360;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  item: {
    padding: theme.spacing(2),
    "&:hover": {
      cursor: "pointer",
      background: "#f4f4f7",
    },
  },
}));

export default React.forwardRef((props, ref) => {
  const { data } = useQuery(API.GET_NOTIFICATIONS);

  const messages = data?.result ?? [];

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => setOpen(false);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <div>
        <div className={classes.row}>
          <NotificationsIcon fontSize="default" />
          <Typography className={classes.title} variant="h6">
            Notifications
          </Typography>
        </div>

        <Divider />

        {messages.map((t, i) => (
          <React.Fragment key={i}>
            <Message message={t} />

            {i < messages.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </div>
    </Drawer>
  );
});

const Message = ({ message }) => {
  const classes = useStyles();

  const title = message?.title;
  const body = message?.body;

  return (
    <div className={classes.item}>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="caption">{body}</Typography>
    </div>
  );
};
