import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Slide from "@material-ui/core/Slide";
import { useAuth } from "../providers/Auth";
import { usePrefs } from "../providers/Prefs";
import General from "./General";
import Billing from "./Billing";
import Account from "./Account";
import UsersAndRoles from "./UsersAndRoles";
import NumberFormats from "./NumberFormats";
import Integrations from "./Integrations";
import Features from "./Features";
import InvoicePrint from "./InvoicePrint";
import Printing from "./Printing";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  left: {
    width: 240,
  },
  right: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: theme.spacing(1),
    background: "#F3F5F8",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    overflow: "auto",
  },
  menu: {
    width: 240,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(1, 1, 1, 2),
    overflowY: "auto",
    background: "#F3F5F8",
  },
}));

const SETTINGS = [
  { label: "General", value: "general", requireAccess: true },
  { label: "Usage & Billing", value: "billing", requireAccess: true },
  { label: "My Account", value: "account", requireAccess: false },
  { label: "Users & Roles", value: "usersAndRoles", requireAccess: true },
  { label: "Number Formats", value: "numberFormats", requireAccess: true },
  { label: "Integrations", value: "integrations", requireAccess: true },
  { label: "Features", value: "features", requireAccess: true },
  { label: "Printing", value: "invoicePrint", requireAccess: true },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { isRootUser } = useAuth();
  const { hasPermission } = usePrefs();

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(
    isRootUser ? "general" : "account"
  );

  React.useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const getComponent = () => {
    switch (selectedValue) {
      case "general":
        return <General />;
      case "billing":
        return <Billing />;
      case "account":
        return <Account />;
      case "usersAndRoles":
        return <UsersAndRoles />;
      case "numberFormats":
        return <NumberFormats />;
      case "integrations":
        return <Integrations />;
      case "features":
        return <Features />;
      case "invoicePrint":
        return <InvoicePrint />;
      case "printing":
        return <Printing />;
      default:
        return <Account />;
    }
  };

  const getLabel = () => {
    const found = getMenu().find((t) => t.value === selectedValue);
    return found?.label;
  };

  const getMenu = () => {
    if (!isRootUser)
      return SETTINGS.filter(
        (t) => !t.requireAccess || hasPermission("settings", t.value, "access")
      );

    return SETTINGS;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      fullScreen
      TransitionComponent={Transition}
    >
      <div className={classes.row}>
        <div className={classes.left}>
          <DialogTitle>Settings</DialogTitle>
        </div>
        <div className={classes.right}>
          <DialogTitle>{getLabel()}</DialogTitle>

          <IconButton edge="start" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.menu}>
          <List dense>
            {getMenu().map((t, i) => {
              return (
                <ListItem
                  key={i}
                  button
                  selected={selectedValue === t?.value}
                  onClick={() => setSelectedValue(t?.value)}
                >
                  <ListItemText primary={t.label} />
                </ListItem>
              );
            })}
          </List>
        </div>

        <div className={classes.content}>{getComponent()}</div>
      </div>
    </Dialog>
  );
});
