import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Enable2FA from "./Enable2FA";
import { useLazyQuery, API } from "../../api";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
  },
  button: {
    width: 200,
  },
  successIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1.5),
  },
  errorIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1.5),
  },
}));

function MyForm({ initialValues }) {
  const [enable2Fa, { data: data2FA }] = useLazyQuery(API.ENABLE_2FA, {
    fetchPolicy: "no-cache",
  });

  const enabled = initialValues?.isGoogle2FAEnabled;

  const classes = useStyles();

  const enable2FaRef = React.useRef();

  React.useEffect(() => {
    if (data2FA) enable2FaRef.current.open(data2FA);
  }, [data2FA]);

  const onEnable2FA = () => {
    enable2Fa({ data: null });
  };

  return (
    <>
      <div className={classes.row}>
        <div>
          {enabled ? (
            <CheckCircleIcon className={classes.successIcon} />
          ) : (
            <CancelIcon className={classes.errorIcon} />
          )}
        </div>
        <Typography>{enabled ? "2FA is enabled" : "2FA not set"}</Typography>
      </div>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={onEnable2FA}
      >
        Update 2FA Settings
      </Button>

      <Enable2FA ref={enable2FaRef} maxWidth="sm" />
    </>
  );
}

export default MyForm;
