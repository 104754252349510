export function getPaidTotal(items) {
  if (!items) return 0;

  let total = 0;
  items.forEach((item) => {
    total += item?.amount ?? 0;
  });
  return total;
}

export function getPayable(settlement) {
  if (!settlement) return 0;

  const dueAmount = settlement?.dueAmount ?? 0;
  const discount = settlement?.discount ?? 0;
  return dueAmount - discount;
}

export function getSettledAmount(items, settlements, index) {
  if (!items || !settlements || index === undefined) return 0;

  let remainingTotal = getPaidTotal(items);

  let amount = 0;
  for (let i = 0; i < settlements.length; i++) {
    const payable = getPayable(settlements[i]);
    if (remainingTotal > payable) {
      amount = payable;
      remainingTotal -= payable;
    } else {
      amount = remainingTotal;
      remainingTotal = 0;
    }
    if (i === index) break;
  }

  return amount;
}

export function getMethod(methods) {
  if (!methods) return "";

  let method = "cash";
  if (methods.length === 0) method = "";
  if (methods.length === 1 && methods[0] === "CASH") method = "cash";
  if (methods.length === 1 && methods[0] === "CHEQUE") method = "cheque";
  if (methods.length > 1) method = "both";

  return method;
}

export function getDiscount(settlements) {
  if (!settlements) return 0;

  let total = 0;
  settlements.forEach((settlement) => {
    total += settlement?.discount ?? 0;
  });
  return total;
}
