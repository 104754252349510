import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import { DateRangePicker } from "materialui-daterange-picker";
import * as dateFns from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    width: 160,
  },
}));

export default function RangePicker({ onChange }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [label, setLabel] = React.useState("This Month");

  const onPickDateRange = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onPicked = (e) => {
    const _range = {
      startDate: dateFns.format(e?.startDate, "yyyy-MM-dd"),
      endDate: dateFns.format(e?.endDate, "yyyy-MM-dd"),
    };
    setLabel(e?.label ?? "Custom");
    onChange?.(_range);
    onClose();
  };

  const toggle = () => {
    setOpen(!open);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className={classes.root}
        variant="outlined"
        onClick={onPickDateRange}
        startIcon={<Icon>date_range</Icon>}
        endIcon={<Icon>arrow_drop_down</Icon>}
      >
        <Typography variant="caption">{label}</Typography>
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div>
          <DateRangePicker
            open={Boolean(anchorEl)}
            toggle={toggle}
            onChange={onPicked}
            closeOnClickOutside={true}
          />
        </div>
      </Popover>
    </>
  );
}
