import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input, AccountType } from "../../components/Formik";
import { useLazyQuery, API } from "../../api";
import { useAuth } from "../../providers/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "linear-gradient(147deg, #000000 0%, #2c3e50 100%)",
  },
  paper: {
    padding: theme.spacing(4),
    background: "#fff",
    borderRadius: theme.spacing(1.5),
  },
  content: {
    width: "320px",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  accountId: {
    fontWeight: "bold",
    margin: theme.spacing(2, 0, 2, 0),
  },
  button: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  createButton: {
    width: "100%",
    margin: theme.spacing(2, 0, 2, 0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  forgot: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Login({ history }) {
  const [check, { data: data1, error: error1, loading: loading1 }] =
    useLazyQuery(API.CHECK);
  const [login, { data: data2, error: error2, loading: loading2 }] =
    useLazyQuery(API.LOGIN);
  const [login2FA, { data: data3, error: error3, loading: loading3 }] =
    useLazyQuery(API.LOGIN_2FA);

  const classes = useStyles();

  const auth = useAuth();

  const [step, setStep] = React.useState(0);
  const [isRoot, setIsRoot] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    if (data1) {
      if (data1.redirect) {
        window.location = data1.redirectUrl;
      } else {
        setStep(1);
      }
    }
  }, [data1]);

  React.useEffect(() => {
    if (error1) setErrorMessage(error1?.message ?? "Something went wrong");
  }, [error1]);

  React.useEffect(() => {
    if (data2) {
      if (data2.isGoogle2FAEnabled) {
        setStep(2);
      } else {
        auth.signIn(data2.accessToken, isRoot);
      }
    }
    // eslint-disable-next-line
  }, [data2]);

  React.useEffect(() => {
    if (error2) setErrorMessage(error2?.message ?? "Incorrect password");
  }, [error2]);

  React.useEffect(() => {
    if (data3) {
      auth.signIn(data2.accessToken, isRoot);
    }
    // eslint-disable-next-line
  }, [data3]);

  React.useEffect(() => {
    if (error3) setErrorMessage(error3?.message ?? "Incorrect code");
  }, [error3]);

  const handleEmailSubmit = (values) => {
    setErrorMessage(null);
    setIsRoot(values?.isRoot);
    setEmail(values?.email);
    setUsername(values?.username);
    const data = {
      isRoot: values?.isRoot,
      email: values?.email,
      username: values?.username,
    };
    check({ data });
  };

  const handlePasswordSubmit = (values) => {
    setErrorMessage(null);
    const data = { isRoot, email, username, password: values?.password };
    login({ data });
  };

  const handleCodeSubmit = (values) => {
    setErrorMessage(null);
    const data = { isRoot, email, username, code: values?.code };
    login2FA({ data });
  };

  const onRegister = () => {
    history.push("/register");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Paper className={classes.paper} elevation={0}>
        <div className={classes.content}>
          {Boolean(errorMessage) && (
            <Alert severity="error" className={classes.alert}>
              {errorMessage}
            </Alert>
          )}

          {step === 0 && (
            <EmailForm
              initialValues={{ isRoot: true, email: "", username: "" }}
              onSubmit={handleEmailSubmit}
              onRegister={onRegister}
              isSubmitting={loading1}
            />
          )}

          {step === 1 && (
            <PasswordForm
              initialValues={{ password: "" }}
              onSubmit={handlePasswordSubmit}
              isRoot={isRoot}
              email={email}
              username={username}
              isSubmitting={loading2}
            />
          )}

          {step === 2 && (
            <TwoFAForm
              initialValues={{ code: "" }}
              onSubmit={handleCodeSubmit}
              isRoot={isRoot}
              email={email}
              username={username}
              isSubmitting={loading3}
            />
          )}

          <Typography component="div" variant="caption">
            {"© "}
            {new Date().getFullYear()}, Code 369
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

const EmailForm = ({ initialValues, onSubmit, onRegister, isSubmitting }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        isRoot: Yup.bool().required("Required"),
        email: Yup.string().when("isRoot", {
          is: true,
          then: Yup.string().email().required("Required"),
          otherwise: Yup.string().nullable(),
        }),
        username: Yup.string().when("isRoot", {
          is: false,
          then: Yup.string().required("Required"),
          otherwise: Yup.string().nullable(),
        }),
      })}
    >
      {({ values }) => (
        <Form>
          <Typography className={classes.title} variant="h6">
            Sign In
          </Typography>

          <AccountType name="isRoot" />

          <Typography className={classes.accountId} variant="body2">
            {values?.isRoot
              ? "Root user email address"
              : "Username or account alias"}
          </Typography>

          {values?.isRoot ? (
            <Input name="email" label="Email" autoFocus />
          ) : (
            <Input name="username" label="Username" autoFocus />
          )}

          <Button
            type="submit"
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={16} />}
          >
            Next
          </Button>

          <Typography component="div" variant="caption">
            By continuing, you agree to the{" "}
            <Link color="inherit" href="https://inflaate.com/terms">
              Code 369 Customer Agreement
            </Link>
            , and the{" "}
            <Link color="inherit" href="https://inflaate.com/privacy">
              Privacy Notice
            </Link>
          </Typography>

          <Button
            className={classes.createButton}
            variant="outlined"
            color="primary"
            onClick={onRegister}
          >
            Create a new account
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const PasswordForm = ({
  initialValues,
  onSubmit,
  isRoot,
  email,
  username,
  isSubmitting,
}) => {
  const classes = useStyles();

  const alias = isRoot ? `Email: ${email}` : `Username: ${username}`;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        password: Yup.string().required("Required"),
      })}
    >
      <Form>
        <Typography className={classes.title} variant="h6">
          {isRoot ? "Root user sign in" : "IAM user sign in"}
        </Typography>

        <Typography className={classes.item} variant="subtitle2">
          {alias}
        </Typography>

        <Input name="password" label="Password" type="password" autoFocus />

        <div className={classes.forgot}>
          <Link color="inherit" href="/forgot-password">
            Forgot password?
          </Link>
        </div>

        <Button
          type="submit"
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={16} />}
        >
          Login
        </Button>

        <div className={classes.item}>
          <Link color="inherit" href="/login">
            Sign in to a different account
          </Link>
        </div>

        <div className={classes.item}>
          <Link color="inherit" href="/register">
            Create a new account
          </Link>
        </div>
      </Form>
    </Formik>
  );
};

const TwoFAForm = ({ initialValues, onSubmit, isSubmitting }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        code: Yup.string()
          .required("Required")
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(6, "Must be exactly 6 digits")
          .max(6, "Must be exactly 6 digits"),
      })}
    >
      <Form>
        <Typography className={classes.title} variant="h6">
          Two-factor authentication
        </Typography>

        <Typography className={classes.item} variant="subtitle2">
          Open your authentication app and enter the code for Inflaate.
        </Typography>

        <Input name="code" label="Enter 6-digit code" autoFocus bottom={24} />

        <Button
          type="submit"
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={16} />}
        >
          Verify Code
        </Button>

        <div className={classes.item}>
          <Link color="inherit" href="/login">
            Sign in to a different account
          </Link>
        </div>

        <div className={classes.item}>
          <Link color="inherit" href="/register">
            Create a new account
          </Link>
        </div>
      </Form>
    </Formik>
  );
};
