import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  subItem: {
    height: 32,
    color: "#D2D6DB",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },
  icon: {
    color: "#fff",
    fontSize: 20,
  },
  active: {
    color: "#669DF6",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
  },
}));

export default function SubItem({ rootPath, path, icon, label }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => history.push(`${rootPath}${path}`);

  const isSamePath = location?.pathname === `${rootPath}${path}`;

  return (
    <ListItem
      dense
      button
      onClick={handleClick}
      className={clsx(classes.subItem, isSamePath && classes.active)}
    >
      <ListItemIcon>
        <Icon className={classes.icon}>{icon}</Icon>
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body2">{label}</Typography>
      </ListItemText>
    </ListItem>
  );
}
