import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import SearchBar from "../SearchBar";

const useStyles = makeStyles((theme) => ({
  search: {
    flexGrow: 1,
  },
}));

export default function Title({ onChange }) {
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <SearchBar onChange={onChange} />
    </div>
  );
}
