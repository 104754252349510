import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API, useQuery } from "../../../api";
import AutoComplete from "../components/Auto";
import LineChart from "../components/LineChart";
import Options from "../components/Options";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
}));

export default function ProductAnalysis() {
  const [getReport, { data }] = useLazyQuery(API.GET_PRODUCT_ANALYSIS, {
    fetchPolicy: "network-only",
  });
  const { data: products } = useQuery(API.GET_PRODUCTS);

  const productOptions = React.useMemo(
    () =>
      products?.result?.map((t) => ({
        title: t.description,
        subtitle: t.code,
        value: t._id,
      })) ?? [],
    [products]
  );

  const topCustomers = data?.topCustomers ?? [];
  const sales = data?.sales ?? [];

  const chartData = sales?.map((t) => ({ label: t?.date, value: t?.quantity }));

  const classes = useStyles();

  const [filters, setFilters] = React.useState({ product: "" });
  const [value, setValue] = React.useState("annualDemand");

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onProductChange = (e) => {
    setFilters({ ...filters, product: e?.value });
  };

  const columns = [
    {
      name: "code",
      label: "Code",
      options: { align: "center" },
    },
    { name: "name", label: "Name" },
    {
      name: "quantity",
      label: "Quantity",
      options: { align: "center" },
    },
  ];

  return (
    <Report title="Product Analysis" data={data} columns={columns} hideExport>
      <div className={classes.row}>
        <AutoComplete
          label="Select Product"
          options={productOptions}
          value={filters.product}
          onChangeComplete={onProductChange}
          bottom={0}
          width={300}
        />
      </div>

      <Options
        options={[
          { label: "Annual Demand", value: "annualDemand" },
          { label: "Top Customers", value: "topCustomers" },
        ]}
        value={value}
        onChange={setValue}
      />

      {value === "annualDemand" && <LineChart data={chartData} />}

      {value === "topCustomers" && (
        <Table data={topCustomers} columns={columns} />
      )}
    </Report>
  );
}
