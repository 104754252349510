import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Input, Select } from ".";
import { formatMoney } from "../../utils/formatters";
import { getSubTotal, getDiscount, getNetTotal } from "../../utils/orderUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    width: 500,
    background: "#fff",
    padding: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  end: {
    display: "flex",
    justifyContent: "flex-end",
  },
  item: {
    flex: 1,
    textAlign: "right",
  },
  fontSize: {
    fontSize: 18,
    lineHeight: 1.8,
  },
}));

export default function OrderTotals({ order, priceKey, fixed, remarks }) {
  const classes = useStyles();

  const getFixedDiscount = () => {
    const type = order?.discountType ?? "percent";
    const value = order?.discountValue ?? 0;
    return type === "value" ? "Discount" : `Discount (${value}%)`;
  };

  return (
    <div className={classes.root}>
      <div>
        {remarks && (
          <Input
            name="remarks"
            label="Remarks"
            multiline
            rows={4}
            width={300}
            top={16}
            bottom={0}
          />
        )}
      </div>

      <div className={classes.content}>
        <Item
          label="Sub Total"
          value={getSubTotal(order?.items, priceKey)}
          variant="h5"
        />

        <div className={classes.row}>
          <div className={classes.item}>
            {fixed ? (
              <div className={classes.end}>
                <Typography variant="h5" className={classes.fontSize}>
                  {getFixedDiscount()}
                </Typography>
              </div>
            ) : (
              <div className={classes.end}>
                <Select
                  name="discountType"
                  options={[
                    { label: "Percent", value: "percent" },
                    { label: "Value", value: "value" },
                  ]}
                  style={{ width: 120 }}
                  right={8}
                  top={8}
                  bottom={8}
                />
                <Input
                  name="discountValue"
                  style={{ width: 84 }}
                  type="number"
                  top={8}
                  bottom={8}
                />
              </div>
            )}
          </div>
          <div className={classes.item}>
            <Typography variant="h5" className={classes.fontSize}>
              {`(${formatMoney(getDiscount(order, priceKey))})`}
            </Typography>
          </div>
        </div>

        <Item
          label="Net Total"
          value={getNetTotal(order, priceKey)}
          variant="h6"
        />
      </div>
    </div>
  );
}

const Item = ({ label, value, variant }) => {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.item}>
        <Typography variant={variant} className={classes.fontSize}>
          {label}
        </Typography>
      </div>
      <div className={classes.item}>
        <Typography variant={variant} className={classes.fontSize}>
          {formatMoney(value)}
        </Typography>
      </div>
    </div>
  );
};
