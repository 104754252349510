import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { useSnackbar } from "notistack";

const KEY_CODES = {
  ENTER: 13,
  ESCAPE: 27,
};

const DialogHoc = (component) =>
  React.forwardRef((props, ref) => {
    const Component = component;

    const [open, setOpen] = React.useState(false);
    const [newItem, setNewItem] = React.useState(null);
    const [initialValues, setInitialValues] = React.useState(null);

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setOpen(false);
        }
      });

      return () => document.removeEventListener("keydown", () => {});
    }, []);

    React.useImperativeHandle(ref, () => ({
      open(e) {
        setNewItem(true);
        setInitialValues(e);
        setOpen(true);
      },
      edit(e) {
        setNewItem(false);
        setInitialValues(e);
        setOpen(true);
      },
    }));

    const handleClose = () => {
      setOpen(false);
    };

    const onKeyDown = (keyEvent) => {
      if ((keyEvent.charCode || keyEvent.keyCode) === KEY_CODES.ENTER) {
        const form = keyEvent.target.form;

        const index = Array.prototype.indexOf.call(form, keyEvent.target);
        form.elements?.[index + 1]?.focus();
        keyEvent.preventDefault();
      }
    };

    const snSuccess = (msg) => {
      enqueueSnackbar(msg, { variant: "success" });
    };

    const snError = (msg) => {
      enqueueSnackbar(msg, { variant: "error" });
    };

    return (
      <Dialog
        open={open}
        maxWidth={props?.maxWidth ?? "md"}
        fullWidth
        disableEscapeKeyDown
      >
        <Component
          {...props}
          onClose={handleClose}
          newItem={newItem}
          initialValues={initialValues}
          onKeyDown={onKeyDown}
          snSuccess={snSuccess}
          snError={snError}
        />
      </Dialog>
    );
  });

export default DialogHoc;
