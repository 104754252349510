import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReportHeader from "./ReportHeader";
import ReportCustomizer from "./ReportCustomizer";
import DropdownButton from "./DropdownButton";
import _ from "lodash";
import DateRangePicker from "../../../components/DateRangePicker";
import { exportToCSV } from "../../../utils/export";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - 50px)`,
    background: "#F3F5F8",
  },
  top: {
    display: "flex",
    flexDirection: "column",
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "8px 16px",
    // overflow: "auto",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
}));

export default function Report({
  title,
  children,
  data = [],
  columns = [],
  useDateRange,
  onDateRangeChange,
  startDate,
  endDate,
  hideExport,
  filters,
  onFiltersChange,
  reportName,
}) {
  const classes = useStyles();

  const history = useHistory();
  const goBack = () => history.goBack();

  const toCSV = () => {
    if (data.length === 0) return;

    let _data = data.map((t) => {
      let row = {};
      for (let column of columns) {
        const val = _.get(t, column?.name);
        row[column?.label] = val;
      }
      return row;
    });

    exportToCSV({ data: _data, filename: title });
  };

  const onOptionSelected = (option) => {
    switch (option) {
      case "CSV":
        toCSV();
        break;
      default:
        return;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Toolbar>
          <div className={classes.leftContainer}>
            <IconButton color="inherit" edge="start" onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>

            <ReportHeader
              title={title}
              startDate={startDate}
              endDate={endDate}
            />
          </div>

          {useDateRange && <DateRangePicker onChange={onDateRangeChange} />}

          {filters?.length > 0 && (
            <ReportCustomizer
              filters={filters}
              onFiltersChange={onFiltersChange}
            />
          )}

          {!hideExport && (
            <DropdownButton
              label="Export As"
              options={[{ label: "CSV", value: "CSV" }]}
              onOptionSelected={onOptionSelected}
            />
          )}
        </Toolbar>
      </div>

      <div className={classes.bottom}>{children}</div>
    </div>
  );
}
