import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Input, AutoComplete } from ".";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  header: {
    padding: theme.spacing(1),
  },
  item: {
    padding: theme.spacing(1),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  margin: {
    marginRight: theme.spacing(2),
  },
  icon: {
    width: 40,
  },
  product: {
    width: 200,
  },
  numbers: {
    width: 150,
  },
  money: {
    width: 120,
  },
  moneyText: {
    textAlign: "right",
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    padding: theme.spacing(1),
  },
  right: {
    textAlign: "right",
    paddingRight: 8,
  },
  selection: {
    padding: theme.spacing(2),
    "&:hover": {
      background: "#EFEFEF",
      cursor: "pointer",
    },
  },
}));

export default function JournalItems({ push, remove, form, accounts = [] }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.row, classes.spaceBetween, classes.header)}>
        <div className={classes.row}>
          <div className={clsx(classes.margin, classes.product)}>
            <Typography variant="button">Account</Typography>
          </div>
        </div>

        <div className={classes.row}>
          <div className={clsx(classes.margin, classes.numbers)}>
            <Typography variant="button">Debit</Typography>
          </div>
          <div className={clsx(classes.margin, classes.numbers)}>
            <Typography variant="button">Credit</Typography>
          </div>

          <div className={clsx(classes.margin)}>
            <Typography></Typography>
          </div>
        </div>
      </div>

      <Divider />

      {form.values?.entries?.map((value, index) => (
        <React.Fragment key={index}>
          <div key={index} className={clsx(classes.row, classes.spaceBetween)}>
            <div className={classes.row}>
              <div
                className={clsx(classes.margin, classes.product, classes.item)}
              >
                <AutoComplete
                  name={`entries[${index}].account`}
                  options={accounts}
                  bottom={0}
                  width={400}
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={clsx(classes.margin, classes.numbers)}>
                <Input
                  name={`entries[${index}].debit`}
                  type="number"
                  bottom={0}
                />
              </div>
              <div className={clsx(classes.margin, classes.numbers)}>
                <Input
                  name={`entries[${index}].credit`}
                  type="number"
                  bottom={0}
                />
              </div>

              <div>
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={() => remove(index)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          </div>

          <Divider />
        </React.Fragment>
      ))}

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() => push({ debit: 0, credit: 0 })}
          startIcon={<AddIcon />}
        >
          Add Lines
        </Button>
      </div>
    </div>
  );
}
