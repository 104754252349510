import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  label: {
    width: 200,
    textAlign: "right",
    marginRight: 48,
  },
  input: {
    width: 400,
  },
}));

export default function Item({ name, component }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.label}>
        {name}
      </Typography>
      <div className={classes.input}>{component}</div>
    </div>
  );
}
