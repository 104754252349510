import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { formatMoney } from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
  },
  column: {
    flex: "1 1 auto",
    padding: "0 8px",
  },
}));

export default function Footer({ data = [], columns = [] }) {
  const classes = useStyles();

  const getTotal = ({ name, options = {} }) => {
    const total = data?.reduce((a, b) => a + b[name], 0) ?? 0;

    if (options?.money) return formatMoney(total);

    return total;
  };

  const getAlign = (options = {}) => {
    const { align, money } = options;
    if (money) return "right";
    return align ?? "left";
  };

  return (
    <div className={classes.row}>
      {columns.map((column, index) => (
        <div
          key={index}
          className={classes.column}
          style={{
            width: column?.options?.width ?? 100,
            textAlign: getAlign(column?.options),
          }}
        >
          <Typography noWrap variant="subtitle2">
            {column?.options?.showTotal ? getTotal(column) : ""}
          </Typography>
        </div>
      ))}
    </div>
  );
}
