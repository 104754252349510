import React from "react";
import { io } from "socket.io-client";
import { useAuth } from "../Auth";

let baseURL = "http://127.0.0.1:8080";

if (process.env.NODE_ENV === "production") {
  baseURL = "https://app.inflaate.com";
}

export let socket;

const WebSocketContext = React.createContext(null);

export const useWebSocket = () => React.useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const { accessToken } = useAuth();

  React.useEffect(() => {
    if (!accessToken) return;

    socket = io(baseURL, { auth: { accessToken } });

    socket.on("connect", () => {
      console.info("socket.io connected");
    });

    socket.on("connect_error", (err) => {
      console.error("socket.io", err.message);
    });

    return () => socket.offAny();
  }, [accessToken]);

  return <WebSocketContext.Provider value={socket} children={children} />;
};
