import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./providers/Auth";
import { CacheProvider } from "./api";
import { WebSocketProvider } from "./providers/WebSocket";
import { AlertProvider } from "./providers/Alert";
import { ThemeProvider } from "./providers/Theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider } from "notistack";
import { PrefsProvider } from "./providers/Prefs";
import * as Yup from "yup";

Yup.addMethod(Yup.array, "unique", function ({ key }, message) {
  return this.test("unique", message, function (list) {
    const mapper = (x) => x[key];
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) return true;

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `${this.path}[${idx}][${key}]`,
      message: message,
    });
  });
});

ReactDOM.render(
  <React.StrictMode>
    <CacheProvider>
      <AuthProvider>
        <WebSocketProvider>
          <PrefsProvider>
            <ThemeProvider>
              <AlertProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    autoHideDuration={2000}
                  >
                    <App />
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </AlertProvider>
            </ThemeProvider>
          </PrefsProvider>
        </WebSocketProvider>
      </AuthProvider>
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
