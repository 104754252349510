import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Select } from "../../../components/Formik";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    width: 600,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  left: {
    width: 300,
    marginRight: 48,
  },
}));

export default function Item({ name, label, description, options }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.left}>
          <Typography variant="subtitle2">{label}</Typography>
          <Typography variant="caption">{description}</Typography>
        </div>
        <div>
          <Select name={name} options={options} width={200} bottom={0} />
        </div>
      </div>
      <Divider />
    </div>
  );
}
