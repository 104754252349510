import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { VariableSizeList } from "react-window";
import Popper from "./Popper";

const LISTBOX_PADDING = 8; // px

function filterOptions(options, { inputValue }) {
  const regex = new RegExp(
    `${inputValue.toString().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}`,
    "i"
  );
  return options.filter(
    (t) => t?.title?.search(regex) >= 0 || t?.subtitle?.search(regex) >= 0
  );
}

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: { ...style, top: style.top + LISTBOX_PADDING },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child?.props?.children?.props?.children?.[1]) {
      return 56;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
}));

export default function Virtualize({
  label,
  options = [],
  onChangeComplete,
  disabled,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  inputRef,
  onKeyUp,
  width = undefined,
  hint,
  isLoading,
  autoFocus,
  ...props
}) {
  const [{ value }, meta, { setValue }] = useField(props);

  const classes = useStyles();

  const ref = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (meta.touched && meta.error) {
      setAnchorEl(ref.current);
    } else {
      setAnchorEl(null);
    }
  }, [meta]);

  const onChange = (e, v) => {
    setValue(v?.value ?? null);
    onChangeComplete?.(v);
  };

  const getValue = () => {
    if (!value) return null;
    return options?.find((t) => t?.value === value) ?? null;
  };

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <Autocomplete
        {...props}
        ref={ref}
        loading={isLoading}
        disabled={disabled}
        value={getValue()}
        onChange={onChange}
        disableListWrap
        autoHighlight
        fullWidth
        classes={classes}
        ListboxComponent={ListboxComponent}
        options={options}
        getOptionLabel={(option) => option?.title}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            onKeyUp={onKeyUp}
            label={label}
            variant="outlined"
            size="small"
            fullWidth
            error={(meta.touched && meta.error && true) || false}
            // helperText={meta.touched && meta.error}
            autoFocus={autoFocus}
          />
        )}
        renderOption={(option) => {
          return (
            <div>
              <Typography noWrap variant="body1" color="textPrimary">
                {option?.title}
              </Typography>

              {option?.subtitle && (
                <Typography noWrap variant="body2" color="textSecondary">
                  {option?.subtitle}
                </Typography>
              )}
            </div>
          );
        }}
      />
      {Boolean(hint) && <Typography variant="caption">{hint}</Typography>}
      <Popper anchorEl={anchorEl} message={meta.error} />
    </div>
  );
}
