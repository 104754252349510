import format from "date-fns/format";
import * as dateFns from "date-fns";
import { sentenceCase } from "sentence-case";
import { titleCase } from "title-case";
import { capitalCase } from "capital-case";
import { paramCase } from "param-case";

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.warn(e);
  }
}

export function formatDate(date, _format = "dd MMM yyyy") {
  if (!date) return "";
  return format(new Date(date || null), _format);
}

export function formatTime(date, _format = "h:mm a") {
  if (!date) return "";
  return format(new Date(date || null), _format);
}

export function formatMonth(date, _format) {
  if (!date) return "";
  return format(new Date(date || null), "yyyy MMMM");
}

export function formatDateTime(date, _format = "dd MMM yyyy h:mm a") {
  if (!date) return "";
  return format(new Date(date || null), _format);
}

export function parseISODateString(date) {
  if (!date) return "";
  return dateFns.parseISO(date);
}

export function toSentenceCase(value) {
  if (!value) return "";
  return sentenceCase(value);
}

export function toTitleCase(value) {
  if (!value) return "";
  return titleCase(value);
}

export function toCapitalCase(value) {
  if (!value) return "";
  return capitalCase(value);
}

export function toParamCase(value) {
  if (!value) return "";
  return paramCase(value);
}

export function roundTo2(value) {
  if (!value) return 0;
  return Math.round(+value * 100) / 100;
}

export function abbreviateNumber(number) {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  // if zero, we don't need a suffix
  if (tier === 0) return number;
  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  // scale the number
  const scaled = number / scale;
  // format number and add suffix
  return scaled.toFixed(0) + suffix;
}

export function dateAgo(date) {
  const _date = dateFns.parseISO(date);
  const diff = dateFns.differenceInSeconds(new Date(), _date);

  if (isNaN(diff)) return "";

  if (diff < 60) {
    return `${Math.floor(diff)}s ago`;
  } else if (diff < 60 * 60) {
    return `${Math.floor(diff / 60)}m ago`;
  } else if (diff < 60 * 60 * 24) {
    return `${Math.floor(diff / (60 * 60))}h ago`;
  } else {
    return `${Math.floor(diff / (60 * 60 * 24))}d ago`;
  }
}

export function formatNumber(e) {
  if (!e) return 0;
  return Number.isInteger(e) ? e : Number(e)?.toFixed(3);
}
