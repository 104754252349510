import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { DRAWER_WIDTH } from "./config";
import { useHotkeys } from "react-hotkeys-hook";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    overflow: "auto",
    background: theme.palette.primary.main,
  },
  sidebar: {
    overflowY: "auto",
    overflowX: "hidden",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sidebarClose: {
    width: 50,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
    borderRadius: theme.spacing(2),
    overflow: "hidden",
    background: "#F3F5F8",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "auto",
  },
}));

export default function Console({ children }) {
  const classes = useStyles();
  const history = useHistory();

  useHotkeys("alt+o", () => {
    history.push("/sales/sales-orders", { open: true, _id: undefined });
  });

  useHotkeys("alt+i", () => {
    history.push("/sales/invoices", { open: true, _id: undefined });
  });

  useHotkeys("alt+p", () => {
    history.push("/sales/payments", { open: true, _id: undefined });
  });

  useHotkeys("alt+h", () => {
    history.push("/purchases/purchases-orders", { open: true, _id: undefined });
  });

  useHotkeys("alt+g", () => {
    history.push("/purchases/grns", { open: true, _id: undefined });
  });

  useHotkeys("alt+j", () => {
    history.push("/purchases/payments", { open: true, _id: undefined });
  });

  const [open, setOpen] = React.useState(true);

  const toggle = React.useCallback(() => setOpen((e) => !e), []);

  if (isMobile) {
    return <div> This content is unavailable on mobile</div>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={clsx(classes.sidebar, !open && classes.sidebarClose)}>
        <Sidebar open={open} toggle={toggle} />
      </div>

      <div className={classes.content}>
        <Header toggle={toggle} />
        <div className={classes.container}>{children}</div>
      </div>
    </div>
  );
}
