import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Formik, Form, FieldArray } from "formik";
import { Select, Input } from "../../components/Formik";
import * as Yup from "yup";
import { PDFViewer } from "@react-pdf/renderer";
import { useQuery, useLazyQuery, API } from "../../api";
import Doc from "./Doc";
import Labels from "./Labels";
import Items from "./Items";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  left: {
    height: 600,
    overflow: "auto",
  },
}));

export default function IP() {
  const { data } = useQuery(API.GET_BUSINESS);
  const [updateBusiness] = useLazyQuery(API.UPDATE_BUSINESS, {
    refetchQueries: [{ query: API.GET_BUSINESS }],
  });

  const classes = useStyles();

  const invoicePrint = data?.invoicePrint;

  const labels = Object.values(invoicePrint?.labels ?? {});
  const columns = Object.values(invoicePrint?.columns ?? {});

  const initialValues = {
    template: invoicePrint?.template ?? "",
    pageSize: invoicePrint?.pageSize ?? "A4",
    fontSize: invoicePrint?.fontSize ?? 10,
    font: invoicePrint?.font ?? "Receipt",
    labels,
    container: invoicePrint?.container ?? {
      top: 100,
      left: 5,
      width: 200,
      height: 50,
    },
    columns,
  };

  const onSubmit = (values) => {
    const data = { invoicePrint: values };
    updateBusiness({ data });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        template: Yup.string(),
        pageSize: Yup.string(),
        fontSize: Yup.number(),
        font: Yup.string(),
        labels: Yup.array(),
        container: Yup.object(),
        columns: Yup.array(),
      })}
    >
      {({ values, dirty, isValid, resetForm }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <div className={classes.left}>
                <DialogContentText>Template</DialogContentText>
                <Select
                  name="template"
                  label="Invoice Template"
                  options={[
                    { label: "Template 1", value: "template1" },
                    { label: "Custom", value: "custom" },
                  ]}
                  width={300}
                />

                <DialogContentText>General</DialogContentText>
                <div className={classes.row}>
                  <Select
                    name="pageSize"
                    label="Page Size"
                    options={[
                      { label: "A4", value: "A4" },
                      { label: "LETTER", value: "LETTER" },
                    ]}
                    width={100}
                    right={8}
                  />
                  <Input
                    name="fontSize"
                    label="Font Size"
                    type="number"
                    width={100}
                    right={8}
                  />
                  <Select
                    name="font"
                    label="Font"
                    options={[
                      { label: "Receipt", value: "Receipt" },
                      { label: "Bank", value: "Bank" },
                      { label: "Merchant", value: "Merchant" },
                      { label: "Calibri", value: "Calibri" },
                      { label: "TimesNewRoman", value: "TimesNewRoman" },
                    ]}
                    width={100}
                    right={8}
                  />
                </div>

                <FieldArray
                  name="labels"
                  render={(props) => <Labels {...props} />}
                />

                <DialogContentText>Container</DialogContentText>
                <div className={classes.row}>
                  <Input
                    name="container.top"
                    label="Top"
                    type="number"
                    width={100}
                    right={8}
                  />
                  <Input
                    name="container.left"
                    label="Left"
                    type="number"
                    width={100}
                    right={8}
                  />
                  <Input
                    name="container.width"
                    label="Width"
                    type="number"
                    width={100}
                    right={8}
                  />
                  <Input
                    name="container.height"
                    label="Height"
                    type="number"
                    width={100}
                  />
                </div>

                <FieldArray
                  name="columns"
                  render={(props) => <Items {...props} />}
                />

                <Button color="primary" onClick={resetForm} disabled={!dirty}>
                  Reset
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  Save
                </Button>
              </div>
            </Grid>
            <Grid item md={8}>
              <PDFViewer width="100%" height={560}>
                <Doc config={values} isPreview={true} />
              </PDFViewer>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
