import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Formik, Form } from "formik";
import { Input, Select, Checkbox } from "../../components/Formik";
import * as Yup from "yup";
import { Actions } from "../../components/Dialogs";
import { COLUMN_OPTIONS } from "./constants";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  space: {
    justifyContent: "space-between",
  },
}));

export default function Items({ push, remove, form }) {
  const classes = useStyles();

  const picker = React.useRef();

  const onAdd = async () => {
    try {
      const values = await picker.current.open({ name: "", align: "left" });
      push({ ...values });
    } catch (err) {}
  };

  const onEdit = async (e, i) => {
    try {
      const values = await picker.current.edit({ ...e });
      form.setFieldValue(`columns[${i}]`, values);
    } catch (err) {}
  };

  const getName = (item) => {
    if (item?.isCustom) return item?.customText;
    const found = COLUMN_OPTIONS.find((t) => t.value === item.name);
    return found?.label ?? "";
  };

  return (
    <div>
      <div className={clsx(classes.row, classes.space)}>
        <DialogContentText>Items</DialogContentText>
      </div>

      {form?.values?.columns?.map((item, index) => (
        <div key={index} className={clsx(classes.row, classes.space)}>
          <Typography>{getName(item)}</Typography>

          <div className={classes.row}>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => onEdit(item, index)}
            >
              <EditIcon fontSize="small" />
            </IconButton>

            <IconButton
              color="inherit"
              size="small"
              onClick={() => remove(index)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      ))}

      <Button
        color="primary"
        onClick={onAdd}
        startIcon={<AddIcon />}
        size="small"
      >
        Add Item
      </Button>

      <Picker ref={picker} />
    </div>
  );
}

const Picker = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [newItem, setNewItem] = React.useState(null);
  const [initialValues, setInitialValues] = React.useState(null);

  const promiseRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    open(e) {
      setNewItem(true);
      setInitialValues(e);
      setOpen(true);
      return new Promise((resolve, reject) => {
        promiseRef.current = { resolve, reject };
      });
    },
    edit(e) {
      setNewItem(false);
      setInitialValues(e);
      setOpen(true);
      return new Promise((resolve, reject) => {
        promiseRef.current = { resolve, reject };
      });
    },
  }));

  const onClose = () => {
    setOpen(false);
    promiseRef.current.reject();
  };

  const onSubmit = (values) => {
    setOpen(false);
    promiseRef.current.resolve(values);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnChange={true}
        validationSchema={Yup.object({
          isCustom: Yup.bool(),
          customText: Yup.string().when("isCustom", {
            is: true,
            then: Yup.string().required("Required"),
            otherwise: Yup.string().nullable(),
          }),
          name: Yup.string().when("isCustom", {
            is: false,
            then: Yup.string().required("Required"),
            otherwise: Yup.string().nullable(),
          }),
          width: Yup.number().min(0).required("Required"),
          align: Yup.string().required("Required"),
        })}
      >
        {({ dirty, isValid, values }) => (
          <Form>
            <DialogTitle>{`${newItem ? "New" : "Edit"} Item`}</DialogTitle>

            <DialogContent className={classes.formContent}>
              <Checkbox name="isCustom" label="Custom Text" />
              {values?.isCustom ? (
                <Input name="customText" label="Custom Text" />
              ) : (
                <Select name="name" label="Option" options={COLUMN_OPTIONS} />
              )}
              <Input name="width" label="Width" type="number" />
              <Select
                name="align"
                label="Text align"
                options={[
                  { label: "Left", value: "left" },
                  { label: "Center", value: "center" },
                  { label: "Right", value: "right" },
                ]}
              />
            </DialogContent>

            <Actions
              newItem={newItem}
              onClose={onClose}
              dirty={dirty}
              isValid={isValid}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});
