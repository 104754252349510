import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
  },
}));

export default function DropdownButton({
  label,
  options = [],
  onOptionSelected,
}) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onPick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSelected = (value) => {
    onOptionSelected && onOptionSelected(value);
  };

  return (
    <>
      <Button
        className={classes.root}
        variant="outlined"
        onClick={onPick}
        endIcon={<Icon>arrow_drop_down</Icon>}
      >
        <Typography variant="caption">{label}</Typography>
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <List dense>
          {options.map((option, index) => (
            <ListItem
              key={index}
              button
              onClick={() => onSelected(option?.value)}
              style={{ minWidth: 120 }}
            >
              <ListItemText primary={option?.label} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
