import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
}));

function Action({ onClick, icon, label, disabled }) {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      startIcon={icon && <Icon>{icon}</Icon>}
      className={classes.button}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

export default React.memo(Action);
