import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import { useField } from "formik";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    border: "1px solid black",
    "&:hover": {
      border: "1px solid #0075FF",
    },
    cursor: "pointer",
    marginBottom: theme.spacing(1),
  },
  active: {
    border: "1px solid #1976D2",
    background: "#F0F9FF",
  },
  body: {
    marginLeft: theme.spacing(1),
  },
}));

export default function AccountType(props) {
  const [{ value }, meta, { setValue }] = useField(props);

  return (
    <>
      <Radio
        onClick={() => setValue(true)}
        value={value}
        title="Root User"
        body="Account owner that performs tasks requiring unrestricted access."
      />
      <Radio
        onClick={() => setValue(false)}
        value={!value}
        title="IAM User"
        body="User within an account that performs daily tasks."
      />

      {meta.error && <div>Error</div>}
    </>
  );
}

function Radio({ onClick, value, title, body }) {
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      className={clsx(classes.row, value && classes.active)}
    >
      <Icon>{value ? "radio_button_checked" : "radio_button_unchecked"}</Icon>
      <div className={classes.body}>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="caption">{body}</Typography>
      </div>
    </div>
  );
}
