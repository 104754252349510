import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

export default function Address({ contact }) {
  const classes = useStyles();

  const priceList = contact?.priceList?.name ?? "Default Price List";
  const name = contact?.name ?? "";
  const line1 = contact?.addressLine1 ?? " ";
  const line2 = contact?.addressLine2 ?? " ";
  const city = contact?.city ?? "";
  const province = contact?.province ?? "";
  const postal = contact?.postal ?? "";
  const phone = contact?.phone ?? "";
  const mobile = contact?.mobile ?? "";
  const email = contact?.email ?? "";

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2">{`${priceList}`}</Typography>
      <Typography>{`${name}`}</Typography>

      <Typography variant="button">Billing Address</Typography>
      <Typography>{line1}</Typography>
      <Typography>{line2}</Typography>
      <Typography>{`${city}${province && ","}  ${province}${
        postal && ","
      } ${postal}`}</Typography>
      <Typography>{`${phone}${mobile && " /"}  ${mobile}`}</Typography>
      <Typography>{email}</Typography>
    </div>
  );
}
