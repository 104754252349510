import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "linear-gradient(147deg, #000000 0%, #2c3e50 100%)",
  },
  paper: {
    padding: theme.spacing(4),
    background: "#fff",
  },
  content: {
    width: "360px",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Gmail() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <Typography className={classes.title} variant="h6">
            Successfully linked Gmail account!
          </Typography>

          <Typography className={classes.message} variant="body2">
            Please continue
          </Typography>
        </div>
      </Paper>
    </div>
  );
}
