import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input } from "../../components/Formik";
import { useLazyQuery, API } from "../../api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "linear-gradient(147deg, #000000 0%, #2c3e50 100%)",
  },
  paper: {
    padding: theme.spacing(4),
    background: "#fff",
  },
  content: {
    width: "360px",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Forgot({ history }) {
  const [forgot, { data, error, loading }] = useLazyQuery(API.FORGOT);

  const classes = useStyles();

  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    if (data) {
      setSuccessMessage("A verification link has been sent to your email.");
    }
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    if (error) {
      setErrorMessage("Account with this email does not exist.");
    }
  }, [error]);

  const handleSubmit = ({ password2, ...values }) => {
    setErrorMessage(null);
    forgot({ data: values });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <div className={classes.content}>
          {Boolean(errorMessage) && (
            <Alert severity="error" className={classes.alert}>
              {errorMessage}
            </Alert>
          )}

          {Boolean(successMessage) && (
            <Alert severity="success" className={classes.alert}>
              {successMessage}
            </Alert>
          )}

          <Typography className={classes.title} variant="h6">
            Forgot password?
          </Typography>

          <Typography className={classes.message} variant="body2">
            Don't worry! Just fill in your email and we'll send you a link to
            reset your password.
          </Typography>

          <ForgotForm
            onSubmit={handleSubmit}
            initialValues={{ email: "" }}
            isLoading={loading}
          />

          <div className={classes.item}>
            <Link color="inherit" href="/login">
              Sign in to a different account
            </Link>
          </div>

          <div className={classes.item}>
            <Link color="inherit" href="/register">
              Create a new account
            </Link>
          </div>

          <Typography component="div" variant="caption">
            {"© "}
            {new Date().getFullYear()}, Code 369
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

const ForgotForm = ({ initialValues, onSubmit, isLoading }) => {
  const classes = useStyles();

  const startIcon = isLoading && <CircularProgress size={16} color="#fff" />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        email: Yup.string().email().required("Required"),
      })}
    >
      <Form>
        <Input name="email" label="Email" />

        <Button
          disabled={isLoading}
          type="submit"
          className={classes.button}
          variant="contained"
          color="primary"
          startIcon={startIcon}
        >
          Next
        </Button>
      </Form>
    </Formik>
  );
};
