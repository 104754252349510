import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  LocationPicker,
  ThemePicker,
} from "../../components/Formik";
import Item from "./components/Item";
import { INDUSTRY_OPTIONS, MONTHS_OPTIONS } from "./constants";
import { useDropzone } from "react-dropzone";
import { useQuery, useLazyQuery, API } from "../../api";

const placeholderURL =
  "https://livejones.com/wp-content/uploads/2020/05/logo-Placeholder.png";

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 584,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: 600,
  },
  companyLogo: {
    borderRadius: 4,
    objectFit: "contain",
    cursor: "pointer",
  },
}));

export default function General() {
  const { data } = useQuery(API.GET_BUSINESS);
  const [update] = useLazyQuery(API.UPDATE_BUSINESS, {
    refetchQueries: [{ query: API.GET_BUSINESS }],
  });
  const [updateLogo] = useLazyQuery(API.UPDATE_LOGO, {
    refetchQueries: [{ query: API.GET_BUSINESS }],
  });

  const general = data?.general;

  const classes = useStyles();

  const logoURL = data?.logoURL ?? placeholderURL;

  const onSubmit = (values) => {
    const data = { general: values };
    update({ data });
  };

  const onDrop = React.useCallback(
    ([File]) => {
      const fd = new FormData();
      fd.append("logoURL", File, File.name);
      updateLogo({ data: fd });
    },
    [updateLogo]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop }); //isDragActive

  return (
    <Formik
      initialValues={{
        name: general?.name ?? "",
        industry: general?.industry ?? "",
        addressLine1: general?.addressLine1 ?? "",
        addressLine2: general?.addressLine2 ?? "",
        city: general?.city ?? "",
        province: general?.province ?? "",
        zipCode: general?.zipCode ?? "",
        phone: general?.phone ?? "",
        mobile: general?.mobile ?? "",
        website: general?.website ?? "",
        primaryEmail: general?.primaryEmail ?? "",
        fiscalYearStart: general?.fiscalYearStart ?? "",
        dateFormat: general?.dateFormat ?? "",
        businessIdNo: general?.businessIdNo ?? "",
        location: general?.location,
        theme: general?.theme,
      }}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validateOnChange={true}
      validationSchema={Yup.object({
        name: Yup.string(),
        industry: Yup.string(),
        addressLine1: Yup.string(),
        addressLine2: Yup.string(),
        city: Yup.string(),
        province: Yup.string(),
        zipCode: Yup.string(),
        phone: Yup.string(),
        mobile: Yup.string(),
        website: Yup.string(),
        primaryEmail: Yup.string(),
        fiscalYearStart: Yup.string(),
        dateFormat: Yup.string(),
        businessIdNo: Yup.string(),
        location: Yup.object().nullable(),
        theme: Yup.string().nullable(),
      })}
    >
      {({ dirty, isValid, resetForm }) => (
        <Form>
          <Item
            name="Company Logo"
            component={
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <img
                  src={logoURL}
                  alt="company-logo"
                  width={100}
                  height={100}
                  className={classes.companyLogo}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = placeholderURL;
                  }}
                />
              </div>
            }
          />

          <Item
            name="Company Name"
            component={<Input label="Company Name" name="name" />}
          />

          <Item
            name="Industry"
            component={
              <Select
                label="Industry"
                name="industry"
                options={INDUSTRY_OPTIONS}
              />
            }
          />

          <Item
            name="Company Address"
            component={
              <>
                <div className={classes.item}>
                  <Input label="Address Line 1" name="addressLine1" />
                </div>
                <div className={classes.item}>
                  <Input label="Address Line 2" name="addressLine2" />
                </div>
                <div className={classes.row}>
                  <div className={classes.item}>
                    <Input label="City" name="city" />
                  </div>
                  <div className={classes.item}>
                    <Input label="Province" name="province" />
                  </div>
                  <div className={classes.item}>
                    <Input label="Zip Code" name="zipCode" />
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.item}>
                    <Input label="Phone" name="phone" />
                  </div>
                  <div className={classes.item}>
                    <Input label="Mobile" name="mobile" />
                  </div>
                  <div className={classes.item}>
                    <Input label="Website" name="website" />
                  </div>
                </div>
              </>
            }
          />

          <Item
            name="Primary Email"
            component={<Input label="Primary Email" name="primaryEmail" />}
          />

          <Item
            name="First Month of Fiscal Year"
            component={
              <Select
                label="First Month of Fiscal Year"
                name="fiscalYearStart"
                options={MONTHS_OPTIONS}
              />
            }
          />

          <Item
            name="Date Format"
            component={
              <Select
                label="Date Format"
                name="dateFormat"
                options={[{ label: "DD MMM YYYY", value: "DD MMM YYYY" }]}
              />
            }
          />

          <Item
            name="Business Reg No"
            component={<Input label="Business Reg No" name="businessRegNo" />}
          />

          <Item
            name="Location"
            component={<LocationPicker name="location" label="Location" />}
          />

          <Item
            name="Theme"
            component={
              <ThemePicker
                name="theme"
                options={[
                  { main: "#122C44", accent: "#2B373D", value: "#122C44" },
                  { main: "#455861", accent: "#2B373D", value: "#455861" },
                  { main: "#A0144F", accent: "#660D33", value: "#A0144F" },
                  { main: "#A71C1C", accent: "#6D1212", value: "#A71C1C" },
                  { main: "#285E2C", accent: "#18391A", value: "#285E2C" },
                  { main: "#055C63", accent: "#02393E", value: "#055C63" },
                  { main: "#14539A", accent: "#0D3562", value: "#14539A" },
                  { main: "#6E42A2", accent: "#462A68", value: "#6E42A2" },
                ]}
              />
            }
          />

          <Item
            name=""
            component={
              <>
                <Button color="primary" onClick={resetForm} disabled={!dirty}>
                  Reset
                </Button>
                <Button
                  color="primary"
                  autoFocus
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  Save
                </Button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
}
