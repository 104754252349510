import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Header from "./Header";
import Row from "./Row";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 16,
    background: "#fff",
    borderRadius: 8,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

export default function VCTable({
  isLoading,
  hasMore = false,
  loadMore,
  data = [],
  columns = [],
  innerKey,
  innerColumns = [],
  rowHeight = 42,
}) {
  const classes = useStyles();

  const itemCount = hasMore ? data.length + 20 : data.length;

  const isItemLoaded = (index) => !hasMore || index < data.length;

  const [rowSizes, setRowSizes] = React.useState(
    new Array(1000).fill(true).reduce((acc, item, i) => {
      acc[i] = rowHeight;
      return acc;
    }, {})
  );

  const listRef = React.useRef();

  const toggleSize = (i) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(i);
    }
    const numRows = data?.[i]?.[innerKey]?.length;
    const size = (numRows + 2) * rowHeight;
    setRowSizes((prevState) => ({
      ...prevState,
      [i]: prevState[i] === 42 ? size : 42,
    }));
  };

  const getSize = (i) => rowSizes[i];

  return (
    <Paper className={classes.root} elevation={0}>
      <Header columns={columns} />

      <div className={classes.list}>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMore}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  ref={(r) => {
                    listRef.current = r;
                    r = ref;
                  }}
                  onItemsRendered={onItemsRendered}
                  height={height}
                  itemCount={itemCount}
                  itemSize={getSize}
                  width={width}
                >
                  {(props) => (
                    <Row
                      {...props}
                      toggleSize={toggleSize}
                      getSize={getSize}
                      data={data}
                      columns={columns}
                      innerKey={innerKey}
                      innerColumns={innerColumns}
                      isItemLoaded={isItemLoaded}
                      isLoading={isLoading}
                    />
                  )}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    </Paper>
  );
}
