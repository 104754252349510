import React from "react";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

export default function CustomCellRenderer({ render, ...props }) {
  switch (render) {
    case "priority":
      return <Priority {...props} />;
    case "status":
      return <Status {...props} />;
    case "bill":
      return <Bill {...props} />;
    case "submit":
      return <Submit {...props} />;
    case "billStatus":
      return <BillStatus {...props} />;
    case "method":
      return <Method {...props} />;
    case "cheque":
      return <Cheque {...props} />;
    case "returns":
      return <Returns {...props} />;
    case "transfer":
      return <Transfer {...props} />;
    case "inventory":
      return <Inventory {...props} />;
    case "mro":
      return <MRO {...props} />;
    case "customerIssue":
      return <CustomerIssue {...props} />;
    default:
      return null;
  }
}

const Priority = ({ value }) => {
  if (value?.toUpperCase() === "URGENT") {
    return (
      <Chip
        size="small"
        label={value}
        style={{ background: "#EE4123", color: "#fff" }}
      />
    );
  } else {
    return null;
  }
};

const Status = ({ value }) => {
  const color = value === "closed" ? "#8C9ABC" : "#586DE0";
  return (
    <Typography style={{ color, fontSize: 13 }} variant="button">
      {value?.toUpperCase()}
    </Typography>
  );
};

const Bill = ({ value }) => {
  let color = "#8C9ABC";
  if (value === "billed") {
    color = "#729E8A";
  } else if (value === "partial") {
    color = "#DC8806";
  }
  return (
    <Typography style={{ color, fontSize: 13 }} variant="button">
      {value?.toUpperCase()}
    </Typography>
  );
};

const Submit = ({ value }) => {
  let text = value ? "Submitted" : "Not Submitted";
  let background = value ? "#4CAF50" : "#FAAD14";

  return (
    <Chip
      size="small"
      label={text?.toUpperCase()}
      style={{ background, color: "#fff" }}
    />
  );
};

const BillStatus = ({ value }) => {
  let background = "#EE4123";
  if (value === "paid") {
    background = "#4CAF50";
  } else if (value === "partial") {
    background = "#FAAD14";
  }

  return (
    <Chip
      size="small"
      label={value?.toUpperCase()}
      style={{ background, color: "#fff" }}
    />
  );
};

const Method = ({ value = [] }) => {
  const getBackground = (t) => {
    if (t === "CASH") {
      return "#4CAF50";
    } else if (t === "CHEQUE") {
      return "#1890FF";
    }
  };

  return (
    <div>
      {value?.map((t, i) => (
        <Chip
          key={t}
          size="small"
          label={t?.toUpperCase()}
          style={{
            background: getBackground(t),
            color: "#fff",
            marginRight: i < value?.length - 1 && 4,
          }}
        />
      ))}
    </div>
  );
};

const Cheque = ({ value }) => {
  let text =
    value === "inHand"
      ? "In Hand"
      : value === "deposited"
      ? "Deposited"
      : "Returned";
  let background =
    value === "inHand"
      ? "#FAAD14"
      : value === "deposited"
      ? "#4CAF50"
      : "#EE4123";

  return (
    <Chip
      size="small"
      label={text?.toUpperCase()}
      style={{ background, color: "#fff" }}
    />
  );
};

const Returns = ({ value }) => {
  let background = "#4CAF50";
  if (value?.toUpperCase() === "DAMAGE") {
    background = "#EE4123";
  }
  return (
    <Chip size="small" label={value} style={{ background, color: "#fff" }} />
  );
};

const Transfer = ({ value }) => {
  let text = value ? "Completed" : "Pending";
  let background = value ? "#4CAF50" : "#FAAD14";

  return (
    <Chip
      size="small"
      label={text?.toUpperCase()}
      style={{ background, color: "#fff" }}
    />
  );
};

const Inventory = ({ row }) => {
  const totalInStock =
    Object.values(row?.inStock)?.reduce((a, b) => a + (b?.default ?? 0), 0) ??
    0;
  const reorderLevel = row?.reorderLevel ?? 0;

  if (totalInStock <= 0) {
    return (
      <Chip
        size="small"
        label="Out of Stock"
        style={{ background: "#EE4123", color: "#fff" }}
      />
    );
  }

  if (reorderLevel < totalInStock) return null;

  return (
    <Chip
      size="small"
      label="Low Stock"
      style={{ background: "#FAAD14", color: "#fff" }}
    />
  );
};

const MRO = ({ value }) => {
  let background = "#4CAF50";
  if (value?.toUpperCase() === "OUT") {
    background = "#EE4123";
  }
  return (
    <Chip
      size="small"
      label={value}
      style={{ background, color: "#fff", minWidth: 50 }}
    />
  );
};

const CustomerIssue = ({ value }) => {
  let background = "#EE4123";
  let text = "OPEN";
  if (!!value) {
    background = "#4CAF50";
    text = "RESOLVED";
  }
  return (
    <Chip
      size="small"
      label={text}
      style={{ background, color: "#fff", minWidth: 50 }}
    />
  );
};
