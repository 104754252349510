import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input } from "../../components/Formik";
import { useLazyQuery, API } from "../../api";
import { useAuth } from "../../providers/Auth";

const getUrlParameter = (name) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "linear-gradient(147deg, #000000 0%, #2c3e50 100%)",
  },
  paper: {
    padding: theme.spacing(4),
    background: "#fff",
  },
  content: {
    width: "360px",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Setup() {
  const [setupAccount, { data, error }] = useLazyQuery(API.SETUP_ACCOUNT);

  const classes = useStyles();

  const auth = useAuth();

  const [successMessage, setSuccessMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    if (data) {
      setSuccessMessage("Successfully updated your password.");
      auth.signIn(data.accessToken, data.isRoot);
    }
  }, [auth, data]);

  React.useEffect(() => {
    if (error) setErrorMessage(error?.message);
  }, [error]);

  const handleSubmit = ({ password2, ...values }) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    const uid = getUrlParameter("uid");
    const token = getUrlParameter("token");
    setupAccount({ data: { ...values, uid, token } });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <div className={classes.content}>
          {Boolean(errorMessage) && (
            <Alert severity="error" className={classes.alert}>
              {errorMessage}
            </Alert>
          )}

          {Boolean(successMessage) && (
            <Alert severity="success" className={classes.alert}>
              {successMessage}
            </Alert>
          )}

          <Typography className={classes.title} variant="h6">
            Setup your account
          </Typography>

          <Typography className={classes.message} variant="body2">
            Enter a new password
          </Typography>

          <SetupForm
            onSubmit={handleSubmit}
            initialValues={{ password: "", password2: "" }}
          />

          <div className={classes.item}>
            <Link color="inherit" href="/register">
              Create a new account
            </Link>
          </div>

          <Typography component="div" variant="caption">
            {"© "}
            {new Date().getFullYear()}, Code 369
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

const SetupForm = ({ initialValues, onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        password: Yup.string().required("Required"),
        password2: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Required"),
      })}
    >
      <Form>
        <Input name="password" label="Password" type="password" />

        <Input name="password2" label="Confirm Password" type="password" />

        <Button
          type="submit"
          className={classes.button}
          variant="contained"
          color="primary"
        >
          Next
        </Button>
      </Form>
    </Formik>
  );
};
