import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Report from '../components/Report'
import Table from '../../../components/TableX'
import { useLazyQuery, API, useQuery } from '../../../api'
import AutoComplete from '../components/Auto'
import { formatMoney } from '../../../utils/formatters'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  }
}))

export default function CustomerHistory () {
  const [getReport, { data }] = useLazyQuery(API.GET_CUSTOMER_HISTORY, {
    fetchPolicy: 'network-only'
  })
  const { data: customers } = useQuery(API.GET_CUSTOMERS)

  const customerOptions = React.useMemo(
    () =>
      customers?.result?.map(t => ({
        title: t.name,
        subtitle: t.code,
        value: t._id
      })) ?? [],
    [customers]
  )

  const balance = data?.reduce(
    (a, b) => a + (b?.debit ?? 0) - (b?.credit ?? 0),
    0
  )

  const classes = useStyles()

  const [filters, setFilters] = React.useState({ customer: '' })

  React.useEffect(() => {
    getReport({ data: filters })
    // eslint-disable-next-line
  }, [filters])

  const onCustomerChange = e => {
    setFilters({ ...filters, customer: e?.value })
  }

  const columns = [
    {
      name: 'date',
      label: 'Date',
      options: { align: 'center', date: true }
    },
    {
      name: 'number',
      label: 'Number',
      options: { align: 'center' }
    },
    {
      name: 'type',
      label: 'Type',
      options: { align: 'center' }
    },
    {
      name: 'debit',
      label: 'Debit',
      options: { align: 'right', money: true, showTotal: true }
    },
    {
      name: 'credit',
      label: 'Credit',
      options: { align: 'right', money: true, showTotal: true }
    }
  ]

  return (
    <Report title='Customer History' data={data} columns={columns}>
      <div className={classes.row}>
        <AutoComplete
          label='Select Customer'
          options={customerOptions}
          value={filters.customer}
          onChangeComplete={onCustomerChange}
          bottom={0}
          width={300}
        />

        <Typography variant='h6'>Balance: {formatMoney(balance)}</Typography>
      </div>

      <Table data={data} columns={columns} showTotals />
    </Report>
  )
}
