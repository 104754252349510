import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import logo from "../../../assets/images/logo192.png";
import { DRAWER_WIDTH } from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1.8),
    transition: "0.9s",
  },
  appName: {
    marginLeft: theme.spacing(2),
    color: "#fff",
    letterSpacing: "0.2em",
  },
}));

export default function Header({ open }) {
  const classes = useStyles();

  const width = open ? DRAWER_WIDTH : 50;

  return (
    <div className={classes.root} style={{ width }}>
      <img src={logo} alt="app-icon" width={24} height={24} />
      {open && (
        <Typography variant="h6" className={classes.appName}>
          Inflaate
        </Typography>
      )}
    </div>
  );
}
