import { usePrefs } from "../../providers/Prefs";

export const DRAWER_WIDTH = 240;

export function GetMenu() {
  const { withLoans, withManufacturing, withFleetTracking, hasPermission } =
    usePrefs();

  const MENU = [
    { path: "/", label: "Dashboard", icon: "dashboard" },
    {
      path: "/inventory",
      label: "Inventory",
      icon: "shopping_basket",
      subMenus: [
        { path: "/finished-goods", label: "Finished Goods" },
        { path: "/mro-inventory", label: "MRO Inventory" },
        { path: "/price-lists", label: "Price Lists" },
        { path: "/transfers", label: "Stock Transfers" },
        { path: "/adjustments", label: "Inventory Adjustments" },
      ],
    },
    {
      path: "/contacts",
      label: "Contacts",
      icon: "contacts",
      subMenus: [
        { path: "/customers", label: "Customers" },
        { path: "/suppliers", label: "Suppliers" },
      ],
    },
    {
      path: "/sales",
      label: "Sales",
      icon: "attach_money",
      subMenus: [
        { path: "/sales-orders", label: "Sales Orders" },
        { path: "/invoices", label: "Invoices" },
        { path: "/payments", label: "Payments Received" },
        { path: "/sales-returns", label: "Sales Returns" },
      ],
    },
    {
      path: "/purchases",
      label: "Purchases",
      icon: "shop",
      subMenus: [
        { path: "/purchases-orders", label: "Purchases Orders" },
        { path: "/grns", label: "GRNs" },
        { path: "/payments", label: "Payments Made" },
        { path: "/purchases-returns", label: "Purchases Returns" },
      ],
    },
    {
      path: "/operations",
      label: "Operations",
      icon: "double_arrow",
      subMenus: [
        { path: "/packages", label: "Packages" },
        { path: "/control-panel", label: "Control Panel" },
        { path: "/task-monitor", label: "Task Monitor" },
        { path: "/expenses", label: "Expenses" },
        { path: "/mro", label: "MRO" },
        { path: "/customer-issues", label: "Customer Issues" },
      ],
    },
    {
      path: "/accounting",
      label: "Accounting",
      icon: "menu_book",
      subMenus: [
        { path: "/accounts", label: "Chart of Accounts" },
        { path: "/manual-journals", label: "Manual Journals" },
      ],
    },
    {
      path: "/manage",
      label: "Manage",
      icon: "build",
      subMenus: [
        { path: "/warehouses", label: "Warehouses" },
        { path: "/routes", label: "Routes" },
        { path: "/sales-team", label: "Sales Team" },
        { path: "/alarms", label: "Alarms" },
      ],
    },
    { path: "/reports", label: "Reports", icon: "assessment" },
  ];

  if (withLoans) {
    const index = MENU.findIndex((t) => t.path === "/operations");
    MENU?.[index]?.subMenus?.push({
      path: "/sales-on-trust",
      label: "Sales on Trust",
    });
  }

  if (withFleetTracking) {
    const _fleet = {
      path: "/fleet",
      label: "Fleet",
      icon: "local_shipping",
      subMenus: [
        { path: "/vehicles", label: "Vehicles" },
        { path: "/tracking", label: "Fleet Tracker" },
      ],
    };
    const index = MENU.findIndex((t) => t.path === "/purchases");
    MENU.splice(index + 1, 0, _fleet);
  }

  if (withManufacturing) {
    const index1 = MENU.findIndex((t) => t.path === "/inventory");
    const m = [
      { path: "/work-in-process", label: "Work In Process" },
      { path: "/raw-materials", label: "Raw Materials" },
    ];
    MENU?.[index1]?.subMenus?.splice(1, 0, ...m);
    const _manufacturing = {
      path: "/manufacturing",
      label: "Manufacturing",
      icon: "gavel",
      subMenus: [
        { path: "/recipes", label: "Recipes" },
        { path: "/workflows", label: "Workflows" },
      ],
    };
    const index2 = MENU.findIndex((t) => t.path === "/purchases");
    MENU.splice(index2 + 1, 0, _manufacturing);
  }

  // hide modules for which the user does not have permissions
  MENU.forEach((item) => {
    item.subMenus = item?.subMenus?.filter((t) =>
      hasPermission(item?.path?.slice(1), t?.path?.slice(1), "read")
    );
    if (item?.subMenus?.length === 0) item.subMenus = undefined;
  });

  return MENU.filter((t) => {
    const has = hasPermission(t?.path?.slice(1), "read");
    const hasChildren = t?.subMenus?.length > 0;
    return has || hasChildren;
  });
}
