import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

const Emitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),
  off: (event, fn) => eventEmitter.off(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;

// const event = {
//   list: new Map(),

//   on(eventType, eventAction) {
//     this.list.has(eventType) || this.list.set(eventType, []);
//     if (this.list.get(eventType)) this.list.get(eventType).push(eventAction);
//     return this;
//   },

//   emit(eventType, ...args) {
//     this.list.get(eventType) &&
//       this.list.get(eventType).forEach((cb) => {
//         cb(...args);
//       });
//   },
// };

// Object.freeze(event);

// export default event;
