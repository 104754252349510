import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input } from "../../components/Formik";
import { useLazyQuery, API } from "../../api";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 200,
  },
}));

function MyForm({ initialValues }) {
  const [update] = useLazyQuery(API.UPDATE_USER, {
    refetchQueries: [{ query: API.GET_AUTH_USER }],
  });

  const classes = useStyles();

  const onSubmit = ({ _id, ...values }) => {
    update({ params: { _id }, data: values });
  };

  return (
    <Formik
      initialValues={Object.assign(
        { firstName: "", lastName: "" },
        initialValues
      )}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
      })}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Input name="firstName" label="First Name" width={350} />
          <Input name="lastName" label="Last Name" width={350} />

          <Button
            disabled={!dirty || !isValid}
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.button}
          >
            Update Info
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default MyForm;
