import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { useQuery, API } from "../../api";

const useStyles = makeStyles((theme) => ({
  content: {
    textAlign: "right",
    marginRight: theme.spacing(1.2),
  },
  name: {
    lineHeight: 1.0,
    fontSize: 13,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  email: {
    lineHeight: 1.0,
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  square: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: 30,
    height: 30,
  },
}));

export default function Account() {
  const { data } = useQuery(API.GET_AUTH_USER);

  const firstName = data?.firstName ?? " ";
  const lastName = data?.lastName ?? " ";
  const fullName = `${firstName} ${lastName}`;
  const initials = `${firstName?.[0]}${lastName?.[0]}`;
  const isRoot = data?.isRoot;
  const email = data?.email;
  const username = data?.username;
  const account = isRoot ? email : username;

  const classes = useStyles();

  return (
    <>
      <div className={classes.content}>
        <Typography
          variant="subtitle2"
          className={classes.name}
          color="textPrimary"
        >
          {fullName}
        </Typography>
        <Typography
          variant="body2"
          className={classes.email}
          color="textPrimary"
        >
          {account}
        </Typography>
      </div>

      <Avatar className={classes.square} size="small">
        {initials}
      </Avatar>
    </>
  );
}
