import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Switch from "./components/Switch";
import Select from "./components/Select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useQuery, useLazyQuery, API } from "../../api";

const useStyles = makeStyles((theme) => ({
  buttons: {
    margin: theme.spacing(2, 0, 0, 0),
  },
}));
export default function Features() {
  const { data } = useQuery(API.GET_BUSINESS);
  const [updateBusiness] = useLazyQuery(API.UPDATE_BUSINESS, {
    refetchQueries: [{ query: API.GET_BUSINESS }],
  });

  const features = data?.features;

  const classes = useStyles();

  const onSubmit = (values) => {
    const data = { features: values };
    updateBusiness({ data });
  };

  return (
    <Formik
      initialValues={{
        withBasic: features?.withBasic ?? true,
        withLoans: features?.withLoans ?? false,
        withManufacturing: features?.withManufacturing ?? false,
        withFleetTracking: features?.withFleetTracking ?? false,
        discountPolicy: features?.discountPolicy ?? "",
      }}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validateOnChange={true}
      validationSchema={Yup.object({
        withBasic: Yup.boolean(),
        withLoans: Yup.boolean(),
        withManufacturing: Yup.boolean(),
        withFleetTracking: Yup.boolean(),
        discountPolicy: Yup.string(),
      })}
    >
      {({ dirty, isValid, resetForm }) => (
        <Form>
          <Switch
            disabled={true}
            name="withBasic"
            label="Basic Features"
            description="Basic features for your accounting needs"
          />
          <Switch
            name="withLoans"
            label="Manage Loans"
            description="Manage loans in your business"
          />
          <Switch
            name="withManufacturing"
            label="Manufacturing"
            description="Use a manufacturing model"
          />
          <Switch
            name="withFleetTracking"
            label="Fleet Tracking"
            description="Track your fleet"
          />
          <Select
            name="discountPolicy"
            label="Default Discount Policy"
            description="Percent / Value"
            options={[
              { label: "Percent", value: "percent" },
              { label: "Value", value: "value" },
            ]}
          />

          <div className={classes.buttons}>
            <Button color="primary" onClick={resetForm} disabled={!dirty}>
              Reset
            </Button>
            <Button
              color="primary"
              autoFocus
              type="submit"
              disabled={!dirty || !isValid}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
