import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import * as dateFns from "date-fns";
import { useLazyQuery, API } from "../../../api";

export default function ActivityLog() {
  const [getReport, { data, loading }] = useLazyQuery(API.GET_ACTIVITY_LOG, {
    fetchPolicy: "network-only",
  });

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const columns = [
    {
      name: "createdAt",
      label: "Date/Time",
      options: { align: "center", datetime: true },
    },
    {
      label: "Description",
      options: {
        align: "left",
        width: 400,
        getValue: (row) =>
          `${row?.description}${row?.link ? `: ${row?.link}` : ""}`,
      },
    },
    {
      label: "User",
      options: {
        align: "center",
        getValue: ({ user } = {}) =>
          `${user?.firstName ?? ""} ${user?.lastName ?? ""}`,
      },
    },
  ];

  return (
    <Report
      title="Activity Log"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={data}
      columns={columns}
    >
      <Table data={data} columns={columns} isLoading={loading} />
    </Report>
  );
}
