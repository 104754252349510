import React from "react";
import Emitter from "./emitter";

const CacheContext = React.createContext({});

export const useCache = () => React.useContext(CacheContext);

export const CacheProvider = ({ children }) => {
  const cache = React.useRef({});

  const get = (key) => {
    return cache.current?.[key];
  };

  const set = (key, value) => {
    cache.current[key] = value;
    Emitter.emit("cacheUpdated", key);
  };

  return <CacheContext.Provider value={{ get, set }} children={children} />;
};
