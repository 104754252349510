import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/Menu";
import Account from "./Account";
import { useQuery, API } from "../../api";
import { useAuth } from "../../providers/Auth";
import { useAlert } from "../../providers/Alert";
import Settings from "../../settings";
import Notifications from "./Notifications";
import QuickAdd from "./QuickAdd";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
  },
  businessName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    color: "#476282",
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  companyLogo: {
    width: 30,
    height: 30,
    borderRadius: 100,
    objectFit: "cover",
    marginRight: theme.spacing(2),
  },
}));

export default function Header({ toggle }) {
  const { data } = useQuery(API.GET_BUSINESS);

  const logoURL = data?.logoURL;

  const classes = useStyles();
  const auth = useAuth();
  const alert = useAlert();

  const settings = React.useRef();
  const notifications = React.useRef();

  const onSettings = () => settings.current.open();

  const onNotifications = () => notifications.current.open();

  const onLogout = async () => {
    try {
      const confirmed = await alert({
        title: "Are you sure?",
        message: "Are you sure you want to logout from the application?",
        variant: "warning",
      });
      if (confirmed) {
        auth.signOut();
      }
    } catch (err) {}
  };

  return (
    <>
      <Paper square className={classes.paper} elevation={0}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            edge="start"
            onClick={toggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          {Boolean(logoURL) && (
            <img
              src={data?.logoURL}
              alt="logo"
              className={classes.companyLogo}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://i-concept.com.sg/wp-content/uploads/2020/01/placeholder.png";
              }}
            />
          )}

          <div className={classes.businessName}>
            <Typography variant="h6" color="textPrimary">
              {data?.general?.name}
            </Typography>

            <QuickAdd />
          </div>

          <Account />

          <Notifications />

          <Tooltip title="Notifications">
            <IconButton
              size="small"
              onClick={onNotifications}
              className={classes.iconButton}
            >
              <Icon fontSize="small" color="primary">
                notifications
              </Icon>
            </IconButton>
          </Tooltip>

          <Tooltip title="Settings">
            <IconButton
              size="small"
              onClick={onSettings}
              className={classes.iconButton}
            >
              <Icon fontSize="small" color="primary">
                settings
              </Icon>
            </IconButton>
          </Tooltip>

          <Tooltip title="Logout">
            <IconButton
              size="small"
              onClick={onLogout}
              className={classes.iconButton}
            >
              <Icon fontSize="small" color="primary">
                exit_to_app
              </Icon>
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Paper>

      <Settings ref={settings} />

      <Notifications ref={notifications} />
    </>
  );
}
