import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../../components/VTable";
import Form from "./Form";
import Button from "@material-ui/core/Button";
import { useFetcher, useLazyQuery, API } from "../../api";
import { useAlert } from "../../providers/Alert";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    margin: theme.spacing(1, 0, 1, 2),
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

export default function Users() {
  const { data, loading, hasMore, loadMore } = useFetcher(API.GET_USERS, {
    params: { sort: "createdAt" },
  });
  const [remove] = useLazyQuery(API.DELETE_USER, {
    refetchQueries: [{ query: API.GET_USERS }],
    onCompleted: () =>
      enqueueSnackbar("Successfully Deleted User", { variant: "success" }),
    onError: (err) => enqueueSnackbar(err?.message, { variant: "error" }),
  });

  const classes = useStyles();

  const form = useRef(null);
  const alert = useAlert();
  const { enqueueSnackbar } = useSnackbar();

  const onAdd = () => form.current.open();

  const onEdit = (e) => {
    form.current.edit({ ...e, role: e?.role?._id, salesRep: e?.salesRep?._id });
  };

  const onDelete = async ({ _id }) => {
    try {
      const confirmed = await alert({
        title: "Are you sure?",
        message:
          "Are you sure you want to delete this user? This operation cannot be undone.",
        variant: "warning",
      });
      if (confirmed) {
        remove({ params: { _id } });
      }
    } catch (e) {}
  };

  const getName = (row) => {
    const result = [];
    if (row?.firstName) result.push(row?.firstName);
    if (row?.lastName) result.push(row?.lastName);
    return result.join(" ");
  };

  const getDevice = (device) => {
    const result = [];
    if (device?.browser) result.push(device?.browser);
    if (device?.os) result.push(device?.os);
    return result.join(", ");
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        <Button variant="outlined" color="primary" onClick={onAdd}>
          Add User
        </Button>
      </div>

      <div className={classes.tableContainer}>
        <Table
          isLoading={loading}
          hasMore={hasMore}
          loadMore={loadMore}
          data={data}
          columns={[
            {
              name: "lastName",
              label: "Name",
              options: { getValue: (_, row) => getName(row) },
            },
            { name: "role.name", label: "Role", options: { align: "center" } },
            {
              name: "lastLoginAt",
              label: "Last Login At",
              options: { datetime: true, align: "center" },
            },
            {
              name: "ip",
              label: "IP Address",
              options: { align: "center" },
            },
            {
              name: "geoip.city",
              label: "Region",
              options: { align: "center" },
            },
            {
              name: "device",
              label: "Device",
              options: {
                align: "center",
                width: 220,
                getValue: (device) => getDevice(device),
              },
            },
          ]}
          actions={[
            { icon: "edit", onClick: onEdit },
            { icon: "delete", onClick: onDelete },
          ]}
        />
      </div>

      <Form ref={form} maxWidth="md" />
    </>
  );
}
