import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import NumberFormatItem from "./components/NumberFormatItem";
import { useQuery, useLazyQuery, API } from "../../api";

const useStyles = makeStyles((theme) => ({
  buttons: {
    margin: theme.spacing(2, 0, 0, 1),
  },
}));

export default function NumberFormats() {
  const { data } = useQuery(API.GET_BUSINESS, { fetchPolicy: "network-only" });
  const [update] = useLazyQuery(API.UPDATE_BUSINESS, {
    refetchQueries: [{ query: API.GET_BUSINESS }],
  });

  const classes = useStyles();

  const numberFormats = data?.numberFormats;
  const numbers = data?.numbers;

  const getInitialValues = (nf = {}, n = {}) => {
    const _nf = JSON.parse(JSON.stringify(nf));
    const keys = Object.keys(_nf);
    keys.forEach((key) => {
      delete _nf[key].offset;
      _nf[key].next = n[key];
    });
    return _nf;
  };

  const mapBefore = (values) => {
    const _nf = JSON.parse(JSON.stringify(values));
    const _n = {};
    const keys = Object.keys(_nf);
    keys.forEach((key) => {
      _n[key] = _nf[key].next;
      delete _nf[key].next;
    });
    return { numberFormats: _nf, numbers: _n };
  };

  const onSubmit = (values) => {
    const data = mapBefore(values);
    update({ data });
  };

  return (
    <Formik
      initialValues={getInitialValues(numberFormats, numbers)}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validateOnChange={true}
      validationSchema={Yup.object({
        invoice: Yup.object(),
      })}
    >
      {({ dirty, isValid, resetForm, values }) => (
        <Form>
          <NumberFormatItem
            name="salesOrder"
            label="Sales Order"
            values={values}
          />
          <NumberFormatItem name="invoice" label="Invoice" values={values} />
          <NumberFormatItem
            name="payment"
            label="Payments Received"
            values={values}
          />
          <NumberFormatItem
            name="salesReturn"
            label="Sales Return"
            values={values}
          />
          <NumberFormatItem
            name="purchasesOrder"
            label="Purchases Order"
            values={values}
          />
          <NumberFormatItem name="grn" label="GRN" values={values} />
          <NumberFormatItem
            name="grnPayment"
            label="Payments Made"
            values={values}
          />
          <NumberFormatItem
            name="purchasesReturn"
            label="Purchases Return"
            values={values}
          />

          <div className={classes.buttons}>
            <Button color="primary" onClick={resetForm} disabled={!dirty}>
              Reset
            </Button>
            <Button
              color="primary"
              autoFocus
              type="submit"
              disabled={!dirty || !isValid}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
