export const getRequestParams = (query, variables) => {
  const params = query.split(/(\s+)/).filter((e) => e.trim().length > 0);

  // extract useful bits from the query string
  let method = params[0];
  let url = params[1];
  let args = params.slice(2);

  // replace varialbel in url if there is any
  args.forEach((arg) => {
    url = url.replace(arg, variables?.[arg.slice(1)]);
  });

  const key = `${method}_${url}`;

  const type = ["GET"].includes(method.toUpperCase()) ? "query" : "mutation";

  return { key, url, method, type };
};

export const getRequestParams2 = (uri, path, query) => {
  const params = uri.split(/(\s+)/).filter((e) => e.trim().length > 0);

  // extract useful bits from the uri
  let method = params[0];
  let url = params[1];
  let args = params.slice(2);

  // replace varialbel in url if there is any
  args.forEach((arg) => {
    url = url.replace(arg, path?.[arg.slice(1)]);
  });

  const filter = query?.filter;
  const q = query?.q;
  const sort = query?.sort;
  const offset = query?.offset;
  const limit = query?.limit;

  const i = q?.split(":"); // get length of searched text
  const r = i?.[1]?.length; //

  let urlQuery = "";
  if (isDefined(filter)) urlQuery = `${urlQuery}&filter=${filter}`;
  if (isDefined(q) && r > 0) urlQuery = `${urlQuery}&q=${q}`;
  if (isDefined(sort)) urlQuery = `${urlQuery}&sort=${sort}`;
  if (isDefined(offset)) urlQuery = `${urlQuery}&offset=${offset}`;
  if (isDefined(limit)) urlQuery = `${urlQuery}&limit=${limit}`;
  urlQuery = urlQuery.slice(1);

  const cacheKey = `${method}_${url}`;

  let key = `${method}_${url}`;
  if (isDefined(q)) key = `${key}_${q}`;
  if (isDefined(sort)) key = `${key}_${sort}`;

  if (urlQuery.length > 0) url = `${url}?${urlQuery}`;

  const type = ["GET"].includes(method.toUpperCase()) ? "query" : "mutation";

  return { key, url, method, type, cacheKey };
};

function isDefined(val) {
  return val !== null && val !== undefined;
}
