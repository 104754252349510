import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
}));

function ActionGroup({ onClick, icon, label, extraOptions = [] }) {
  const classes = useStyles();

  const anchorRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setAnchorEl(null);
    option?.onClick();
  };

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        <Button
          onClick={onClick}
          variant="contained"
          color="primary"
          startIcon={icon && <Icon>{icon}</Icon>}
        >
          {label}
        </Button>

        <Button color="primary" size="small" onClick={handlePopoverOpen}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={classes.popoverRoot}>
          <MenuList>
            {extraOptions.map((option, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(option)}>
                {option?.label}
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </Popover>
    </>
  );
}

export default React.memo(ActionGroup);
