import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    marginTop: theme.spacing(2),
  },
}));

export default function Splash() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <CircularProgress size={80} />
        <Typography variant="h6" className={classes.text}>
          App Loading...
        </Typography>
      </div>
    </div>
  );
}
