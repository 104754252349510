import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Checkbox, AutoComplete } from ".";

const useStyles = makeStyles((theme) => ({
  items: {
    background: "#fff",
  },
  costing: {
    background: "#fff",
    marginTop: theme.spacing(1),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "auto 80px 80px 80px 80px 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
}));

export default function PermissionItems({ push, remove, form, modules = [] }) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.items}>
        <div className={classes.container}>
          <Typography variant="button">Module</Typography>
          <Typography variant="button" className={classes.center}>
            Read
          </Typography>
          <Typography variant="button" className={classes.center}>
            Create
          </Typography>
          <Typography variant="button" className={classes.center}>
            Update
          </Typography>
          <Typography variant="button" className={classes.center}>
            Delete
          </Typography>
        </div>

        <Divider />

        {form.values?.items?.map((item, index) => (
          <React.Fragment key={index}>
            <div className={classes.container}>
              <AutoComplete
                name={`items[${index}].module`}
                options={modules}
                bottom={0}
                width={300}
              />

              <div className={classes.center}>
                <Checkbox name={`items[${index}].read`} bottom={0} />
              </div>

              <div className={classes.center}>
                <Checkbox name={`items[${index}].create`} bottom={0} />
              </div>

              <div className={classes.center}>
                <Checkbox name={`items[${index}].update`} bottom={0} />
              </div>

              <div className={classes.center}>
                <Checkbox name={`items[${index}].delete`} bottom={0} />
              </div>

              <IconButton
                color="inherit"
                edge="start"
                onClick={() => remove(index)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </div>

            <Divider />
          </React.Fragment>
        ))}

        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            onClick={() =>
              push({
                module: undefined,
                read: true,
                create: false,
                update: false,
                delete: false,
              })
            }
            startIcon={<AddIcon />}
          >
            Add Module
          </Button>
        </div>
      </div>
    </div>
  );
}
