import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input } from "../../components/Formik";
import { useLazyQuery, API } from "../../api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 200,
  },
}));

function MyForm() {
  const { enqueueSnackbar } = useSnackbar();
  const formRef = React.useRef();

  const [changePassword] = useLazyQuery(API.CHANGE_PASSWORD, {
    refetchQueries: [{ query: API.GET_AUTH_USER }],
    onCompleted: (res) => {
      enqueueSnackbar(res?.message, { variant: "success" });
      formRef.current.resetForm();
    },
    onError: (err) => enqueueSnackbar(err?.message, { variant: "error" }),
  });

  const classes = useStyles();

  const onSubmit = ({ password2, ...values }) => {
    changePassword({ data: values });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ oldPassword: "", password: "", password2: "" }}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        oldPassword: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
        password2: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Required"),
      })}
    >
      {({ dirty }) => (
        <Form>
          <Input
            name="oldPassword"
            label="Current Password"
            type="password"
            width={350}
          />
          <Input
            name="password"
            label="New Password"
            type="password"
            width={350}
          />
          <Input
            name="password2"
            label="Confirm New Password"
            type="password"
            width={350}
          />

          <Button
            disabled={!dirty}
            variant="outlined"
            color="primary"
            type="submit"
            className={classes.button}
          >
            Change Password
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default MyForm;
