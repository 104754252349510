import React from "react";
import { api } from "./request";
import { useCache } from "./useCache";
import { getRequestParams } from "./utils";
import Emitter from "./emitter";

type Options = {
  fetchPolicy?: "cache-first" | "network-only" | "no-cache",
  pollInterval?: number,
  onCompleted?: () => void,
  onError?: () => void,
};

type Result = {
  data: any,
  loading: boolean,
  error: any,
  fetchMore: () => void,
};

export const useQuery = (query, options: Options): Result => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [error, setError] = React.useState(null);

  const cache = useCache();

  const fetchPolicy = options?.fetchPolicy ?? "cache-first";
  const onCompleted = options?.onCompleted;
  const onError = options?.onError;

  const { key } = getRequestParams(query, []);

  React.useEffect(() => {
    Emitter.on("cacheUpdated", (updatedKey) => {
      if (updatedKey === key) {
        const result = cache.get(key);
        setData(result);
      }
    });
  }, [cache, key]);

  React.useEffect(() => {
    const call = async () => {
      setData(null);
      setError(null);
      setLoading(true);

      try {
        const { type, ...rest } = getRequestParams(query, []);

        let result = null;

        if (type === "query") {
          result = await executeQuery(rest);
        }

        setData(result);
        onCompleted?.();
      } catch (err) {
        setError(err?.response?.data);
        onError?.(err?.response?.data);
      }

      setLoading(false);
    };

    call();
    // eslint-disable-next-line
  }, []);

  const fetchMore = async (qp) => {
    setError(null);
    setLoading(true);

    try {
      const { type, ...rest } = getRequestParams(query, []);

      let url = `${rest.url}?`;
      let key = `${rest.key}_`;

      Object.keys(qp).forEach((q) => {
        url = url + `${q}=${qp[q]}&`;
        key = key + `${q}=${qp[q]}_`;
      });

      url = url.slice(0, -1);
      key = key.slice(0, -1);

      let result = null;

      if (type === "query") {
        result = await executeQuery({ ...rest, url, key });
      }

      setData(result);
      onCompleted?.();
    } catch (err) {
      setError(err?.response?.data);
      onError?.(err?.response?.data);
    }

    setLoading(false);
  };

  const executeQuery = async ({ key, url, method }) => {
    let result = null;

    if (fetchPolicy === "cache-first") {
      result = cache.get(key);

      if (!result) {
        result = await api(url, method);
        cache.set(key, result);
      }
    } else if (fetchPolicy === "network-only") {
      result = await api(url, method);
      cache.set(key, result);
    } else if (fetchPolicy === "no-cache") {
      result = await api(url, method);
    }

    return result;
  };

  return { data, loading, error, fetchMore };
};
