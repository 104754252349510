import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, useQuery, API } from "../../../api";
import * as dateFns from "date-fns";

export default function SalesByProduct() {
  const [getReport, { data, loading }] = useLazyQuery(
    API.GET_SALES_BY_PRODUCT,
    { fetchPolicy: "network-only" }
  );

  const { data: categories } = useQuery(API.GET_CATEGORIES);
  const { data: salesReps } = useQuery(API.GET_SALES_REPS);

  const categoryOptions = React.useMemo(
    () => categories?.result?.map((t) => ({ title: t?.name, value: t?._id })),
    [categories]
  );
  const salesRepOptions = React.useMemo(
    () => salesReps?.result?.map((t) => ({ title: t?.name, value: t?._id })),
    [salesReps]
  );

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
    category: "",
    salesRep: "",
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const columns = [
    {
      name: "code",
      label: "Item Code",
      options: { align: "center" },
    },
    {
      name: "description",
      label: "Description",
      options: { align: "left", width: 300 },
    },
    {
      name: "quantitySold",
      label: "Quantity Sold",
      options: { align: "center" },
    },
    {
      name: "amount",
      label: "Amount",
      options: { align: "right", money: true, showTotal: true },
    },
    {
      name: "averagePrice",
      label: "Average Price",
      options: { align: "right", money: true },
    },
  ];

  return (
    <Report
      title="Sales By Product"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={data}
      columns={columns}
      filters={[
        {
          type: "autocomplete",
          name: "category",
          label: "Category",
          options: categoryOptions,
          value: filters?.category,
        },
        {
          type: "autocomplete",
          name: "salesRep",
          label: "Sales Rep",
          options: salesRepOptions,
          value: filters?.salesRep,
        },
      ]}
      onFiltersChange={(e) => setFilters((x) => ({ ...x, ...e }))}
    >
      <Table data={data} columns={columns} showTotals isLoading={loading} />
    </Report>
  );
}
