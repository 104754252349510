export const INVENTORY = [
  { title: "Finished Goods", value: "inventory.finished-goods" },
  { title: "Work In Process", value: "inventory.work-in-process" },
  { title: "Raw Materials", value: "inventory.raw-materials" },
  { title: "MRO Inventory", value: "inventory.mro-inventory" },
  { title: "Price Lists", value: "inventory.price-lists" },
  { title: "Categories", value: "inventory.categories" },
  { title: "Stock Transfers", value: "inventory.transfers" },
  { title: "Inventory Adjustments", value: "inventory.adjustments" },
];

export const CONTACTS = [
  { title: "Customers", value: "contacts.customers" },
  { title: "Suppliers", value: "contacts.suppliers" },
];

export const SALES = [
  { title: "Sales Orders", value: "sales.sales-orders" },
  { title: "Invoices", value: "sales.invoices" },
  { title: "Payments Received", value: "sales.payments" },
  { title: "Sales Returns", value: "sales.sales-returns" },
];

export const PURCHASES = [
  { title: "Purchases Orders", value: "purchases.purchases-orders" },
  { title: "GRNs", value: "purchases.grns" },
  { title: "Payments Made", value: "purchases.payments" },
  { title: "Purchases Returns", value: "purchases.purchases-returns" },
];

export const OPERATIONS = [
  { title: "Task monitor", value: "operations.task-monitor" },
  { title: "Expenses", value: "operations.expenses" },
  { title: "MRO", value: "operations.mro" },
];

export const ACCOUNTING = [
  { title: "Chart of Accounts", value: "accounting.accounts" },
  { title: "Manual Journals", value: "accounting.manual-journals" },
];

export const MANAGE = [
  { title: "Warehouses", value: "manage.warehouses" },
  { title: "Routes", value: "manage.routes" },
  { title: "Sales Reps", value: "manage.sales-reps" },
  { title: "Alarms", value: "manage.alarms" },
];

export const MANUFACTURING = [
  { title: "Recipes", value: "manufacturing.recipes" },
  { title: "Workflows", value: "manufacturing.workflows" },
];

export const FLEET = [
  { title: "Vehicles", value: "fleet.vehicles" },
  { title: "Fleet Tracker", value: "fleet.tracking" },
];
