import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API } from "../../../api";
import * as dateFns from "date-fns";
import Typography from "@material-ui/core/Typography";
import { getMethod } from "../../../utils/paymentUtils";

export default function PaymentsMade() {
  const [getReport, { data, loading }] = useLazyQuery(API.GET_PAYMENTS_MADE, {
    fetchPolicy: "network-only",
  });

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
    numberRange: { from: -1, to: -1 },
    sortBy: "paymentNo",
    sortOrder: 1,
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const getPaymentMethod = (r) => {
    const method = getMethod(r.items);
    let value = "";
    if (method === "cash") {
      value = "Cash";
    } else if (method === "cheque") {
      value = "Cheque";
    } else if (method === "both") {
      value = "Cash / Cheque";
    }
    return value;
  };

  const renderPaymentMethod = (value) => {
    let color = "";
    if (value === "Cash") {
      color = "#4CAF50";
    } else if (value === "Cheque") {
      color = "#1890FF";
    } else if (value === "Cash / Cheque") {
      color = "#FF9800";
    }
    return (
      <Typography style={{ color, fontSize: 13 }} variant="button">
        {value.toUpperCase()}
      </Typography>
    );
  };

  const getData = () => {
    if (!data) return [];
    return data.map((t) => ({ ...t, method: getPaymentMethod(t) }));
  };

  const columns = [
    {
      name: "date",
      label: "Payment Date",
      options: { align: "center", date: true },
    },
    {
      name: "grnPaymentNo",
      label: "Payment #",
      options: { align: "center" },
    },
    {
      name: "supplier.name",
      label: "Supplier Name",
      options: { align: "left", width: 300 },
    },
    {
      name: "method",
      label: "Method",
      options: {
        align: "center",
        customBodyRender: (v) => renderPaymentMethod(v),
      },
    },
    {
      name: "discount",
      label: "Discount",
      options: { align: "right", money: true, showTotal: true },
    },
    {
      name: "amount",
      label: "Amount",
      options: { align: "right", money: true, showTotal: true },
    },
  ];

  return (
    <Report
      title="Payments Made"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={getData()}
      columns={columns}
    >
      <Table
        data={getData()}
        columns={columns}
        showTotals
        isLoading={loading}
      />
    </Report>
  );
}
