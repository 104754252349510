import React from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    // borderRadius: theme.shape.borderRadius,
    borderRadius: 1000,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 250,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
    },
  },
}));

export default function SearchBar({
  onChange,
  placeholder = "Search...",
  autoFocus,
}) {
  const classes = useStyles();

  const _onChange = (e) => {
    const text = e?.target?.value;
    const trimmedText = text?.trim();
    if (text === "" || trimmedText.length > 1) onChange?.(text);
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        onChange={_onChange}
        placeholder={placeholder}
        classes={{ root: classes.inputRoot, input: classes.inputInput }}
        autoFocus={autoFocus}
      />
    </div>
  );
}
