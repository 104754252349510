import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Items from "./Items";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function SideBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header {...props} />

      <Items {...props} />

      <Footer {...props} />
    </div>
  );
}

export default React.memo(SideBar);
