import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { Input } from ".";
import { formatMoney, formatDate } from "../../utils/formatters";
import { getPaidTotal } from "../../utils/paymentUtils";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    marginTop: theme.spacing(2),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "16px 150px 150px auto 180px 180px 180px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
    minHeight: 40,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
    paddingRight: theme.spacing(4),
  },
  buttonContainer: {
    padding: 4,
  },
  sorting: {
    zIndex: theme.zIndex.modal + 100,
    background: "#fff",
    padding: theme.spacing(0.25, 0),
  },
  empty: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const DragHandle = sortableHandle(() => <DragIndicatorIcon />);

const SortableContainer = sortableContainer(({ children }) => (
  <div>{children}</div>
));

const SortableItem = sortableElement(
  ({ idx, value, payable, amount, type }) => {
    const classes = useStyles();

    return (
      <>
        <div className={classes.sorting}>
          <div className={classes.container}>
            <DragHandle />
            <Typography className={classes.center}>
              {formatDate(value.date)}
            </Typography>
            <Typography className={classes.center}>
              {value[`${type}No`]}
            </Typography>
            <Typography className={classes.right}>
              {formatMoney(value.dueAmount)}
            </Typography>
            <Input
              name={`settlements[${idx}].discount`}
              type="number"
              bottom={0}
              inputProps={{ style: { textAlign: "center" } }}
            />
            <Typography className={classes.right}>
              {formatMoney(payable)}
            </Typography>
            <Typography className={classes.right}>
              {formatMoney(amount)}
            </Typography>
          </div>
        </div>
        <Divider />
      </>
    );
  }
);

export default function PayableBills({ form, type }) {
  const classes = useStyles();

  const settlements = form?.values?.settlements ?? [];

  const getPayable = (index) => {
    const settlement = form?.values?.settlements[index];
    const dueAmount = settlement?.dueAmount ?? 0;
    const discount = settlement?.discount ?? 0;
    return dueAmount - discount;
  };

  const getSettledAmount = (index) => {
    let remainingTotal = getPaidTotal(form?.values?.items);
    const settlements = form?.values?.settlements ?? [];
    let amount = 0;
    for (let i = 0; i < settlements.length; i++) {
      const payable = getPayable(i);
      if (remainingTotal > payable) {
        amount = payable;
        remainingTotal -= payable;
      } else {
        amount = remainingTotal;
        remainingTotal = 0;
      }
      if (i === index) break;
    }
    return amount;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const items = JSON.parse(JSON.stringify(form?.values?.settlements ?? []));
    form.setFieldValue("settlements", arrayMove(items, oldIndex, newIndex));
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography></Typography>
        <Typography variant="button" className={classes.center}>
          Date
        </Typography>
        <Typography variant="button" className={classes.center}>
          {type} No
        </Typography>
        <Typography variant="button" className={classes.right}>
          Due Amount
        </Typography>
        <Typography variant="button" className={classes.center}>
          Discount
        </Typography>
        <Typography variant="button" className={classes.right}>
          Payable
        </Typography>
        <Typography variant="button" className={classes.right}>
          Settled Amount
        </Typography>
      </div>

      <Divider />

      {settlements?.length === 0 && (
        <div className={classes.empty}>
          <Typography variant="body2">Nothing to show</Typography>
        </div>
      )}

      <SortableContainer useDragHandle onSortEnd={onSortEnd}>
        {settlements?.map((value, index) => (
          <SortableItem
            key={index}
            index={index}
            value={value}
            idx={index}
            form={form}
            type={type}
            payable={getPayable(index)}
            amount={getSettledAmount(index)}
          />
        ))}
      </SortableContainer>
    </div>
  );
}
