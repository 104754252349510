import { api } from "../api/request";

export async function isProductUnique(payload) {
  try {
    const response = await api("/product/unique", "POST", payload);
    return Boolean(response);
  } catch (err) {
    return false;
  }
}

export async function isCustomerUnique(payload) {
  try {
    const response = await api("/customer/unique", "POST", payload);
    return Boolean(response);
  } catch (err) {
    return false;
  }
}

export async function isSupplierUnique(payload) {
  try {
    const response = await api("/supplier/unique", "POST", payload);
    return Boolean(response);
  } catch (err) {
    return false;
  }
}

export async function isRecipeUnique(payload) {
  try {
    const response = await api("/recipe/unique", "POST", payload);
    return Boolean(response);
  } catch (err) {
    return false;
  }
}

export async function isUsernameExists(payload) {
  try {
    const response = await api("/auth/check-username", "POST", payload);
    return Boolean(response);
  } catch (err) {
    return false;
  }
}
