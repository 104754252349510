import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API } from "../../../api";
import * as dateFns from "date-fns";

export default function SalesByCustomer() {
  const [getReport, { data, loading }] = useLazyQuery(
    API.GET_SALES_BY_CUSTOMER,
    { fetchPolicy: "network-only" }
  );

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const columns = [
    {
      name: "code",
      label: "Code",
      options: { align: "center" },
    },
    {
      name: "customer",
      label: "Customer Name",
      options: { align: "left", width: 300 },
    },
    {
      name: "count",
      label: "Invoice Count",
      options: { align: "center" },
    },
    {
      name: "total",
      label: "Sales",
      options: { align: "right", money: true, showTotal: true },
    },
    {
      name: "average",
      label: "Average Sale",
      options: { align: "right", money: true },
    },
  ];

  return (
    <Report
      title="Sales By Customer"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={data}
      columns={columns}
    >
      <Table data={data} columns={columns} showTotals isLoading={loading} />
    </Report>
  );
}
