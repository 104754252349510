import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Section from "../components/Section";
import { useLazyQuery, useQuery, API } from "../../api";
import { useAlert } from "../../providers/Alert";

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Gmail() {
  const { data } = useQuery(API.GET_BUSINESS);
  const [linkGmail, { data: link_data }] = useLazyQuery(API.LINK_GMAIL);
  const [revoke, { data: revoke_data }] = useLazyQuery(API.REVOKE_GMAIL, {
    refetchQueries: [{ query: API.GET_BUSINESS }],
  });

  const classes = useStyles();
  const alert = useAlert();

  const gmail = data?.gmail;
  const linked = gmail?.sendEmail ? true : false;

  React.useEffect(() => {
    if (link_data) window.open(link_data);
  }, [link_data]);

  React.useEffect(() => {
    if (!revoke_data) return;

    alert({
      title: "Success",
      message: "Successfully revoked access to your Gmail account.",
      variant: "success",
      type: "alert",
    }).catch(console.warn);
    // eslint-disable-next-line
  }, [revoke_data]);

  const onRevoke = async () => {
    try {
      const confirmed = await alert({
        title: "Are you sure?",
        message: "Do you want to revoke access to your Gmail account?",
        variant: "warning",
      });
      if (confirmed) {
        revoke();
      }
    } catch (err) {}
  };

  const onLinkGmail = () => {
    linkGmail();
  };

  return (
    <div>
      <Section
        title="Gmail"
        description="Connect your Gmail account to easily send emails."
      >
        <div>
          <Typography className={classes.text}>
            Account: {linked ? gmail?.sendEmail : "Not linked"}
          </Typography>

          {linked ? (
            <Button variant="outlined" color="primary" onClick={onRevoke}>
              Revoke Access
            </Button>
          ) : (
            <Button variant="outlined" color="primary" onClick={onLinkGmail}>
              Link Gmail Account
            </Button>
          )}
        </div>
      </Section>
    </div>
  );
}
