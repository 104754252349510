import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  Input,
  Select,
  DatePicker,
  AutoComplete,
} from "../../components/Formik";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "180px 180px 180px 240px 240px 180px auto 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
}));

export default function PaymemtItems({
  name = "items",
  push,
  remove,
  replace,
  form,
  hasCredit,
  banks = [],
  branches = [],
}) {
  const items = form?.values?.[name] ?? [];

  const classes = useStyles();

  const getMethodOptions = () => {
    const opts = [
      { label: "Cash", value: "CASH" },
      { label: "Cheque", value: "CHEQUE" },
    ];
    if (hasCredit) opts.push({ label: "Credit", value: "CREDIT" });

    return opts;
  };

  const isCheque = (item) => {
    return item?.method === "CHEQUE";
  };

  const hasCheque = () => {
    for (let item of items) {
      if (isCheque(item)) return true;
    }
    return false;
  };

  const onBankChange = (item, index) => {
    if (!item.branch) return;

    // replace(index, { ...item, branch: "" });
  };

  const getBranchesForBank = (item) => {
    const bankCode = item.bank;
    return branches?.filter((t) => t.bankCode === bankCode) ?? [];
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button" className={classes.center}>
          Payment Method
        </Typography>
        <Typography variant="button" className={classes.center}>
          Amount
        </Typography>
        {hasCheque() && (
          <>
            <Typography variant="button" className={classes.center}>
              Cheque No
            </Typography>
            <Typography variant="button" className={classes.center}>
              Bank
            </Typography>
            <Typography variant="button" className={classes.center}>
              Branch
            </Typography>
            <Typography variant="button" className={classes.center}>
              Cheque Date
            </Typography>
          </>
        )}
      </div>

      <Divider />

      {items?.map((item, index) => (
        <React.Fragment key={index}>
          <div className={classes.container}>
            <Select
              name={`${name}[${index}].method`}
              options={getMethodOptions()}
              bottom={0}
            />

            <Input name={`${name}[${index}].amount`} type="number" bottom={0} />

            {isCheque(item) ? (
              <>
                <Input name={`${name}[${index}].chequeNo`} bottom={0} />
                <AutoComplete
                  name={`${name}[${index}].bank`}
                  options={banks}
                  onChangeComplete={() => onBankChange(item, index)}
                  bottom={0}
                />
                <AutoComplete
                  name={`${name}[${index}].branch`}
                  options={getBranchesForBank(item)}
                  bottom={0}
                />
                <DatePicker name={`${name}[${index}].chequeDate`} bottom={0} />
              </>
            ) : (
              <>
                <div />
                <div />
                <div />
                <div />
              </>
            )}

            <div />
            <IconButton
              color="inherit"
              edge="start"
              onClick={() => remove(index)}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </div>

          <Divider />
        </React.Fragment>
      ))}

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() =>
            push({
              method: "CASH",
              amount: 0,
              chequeNo: "",
              bank: "",
              branch: "",
              chequeDate: null,
            })
          }
          startIcon={<AddIcon />}
        >
          Add Lines
        </Button>
      </div>
    </div>
  );
}
