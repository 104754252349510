import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API } from "../../../api";

export default function CustomerTimeToPay() {
  const [getReport, { data, loading }] = useLazyQuery(
    API.GET_CUSTOMER_TIME_TO_PAY,
    { fetchPolicy: "network-only" }
  );

  React.useEffect(() => {
    getReport();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: "code",
      label: "Customer Code",
      options: { align: "center" },
    },
    {
      name: "name",
      label: "Customer Name",
      options: { align: "left", width: 400 },
    },
    {
      name: "avg",
      label: "Average Time To Pay (Days)",
      options: { align: "center" },
    },
  ];

  return (
    <Report title="Customer Time To Pay" data={data} columns={columns}>
      <Table data={data} columns={columns} showTotals isLoading={loading} />
    </Report>
  );
}
