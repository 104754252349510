import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { GetMenu } from "../config";
import { DRAWER_WIDTH } from "../config";
import Item from "./Item";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  menu: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    // backgroundImage: "url(../../assets/images/sidebar.png)",
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    transition: "0.9s",
    zIndex: 100,
  },
  container: {
    position: "absolute",
    width: "240px",
    height: `calc(100% - 0px)`,
    transition: "0.9s",
  },
  triangle1: {
    position: "absolute",
    bottom: 0,
    borderTop: "240px solid transparent",
    borderBottom: "190px solid #1111",
    borderLeft: "240px solid #1111",
    height: "0px",
    width: "0px",
    transition: "0.8s",
  },
  triangle2: {
    position: "absolute",
    bottom: 0,
    borderTop: "160px solid transparent",
    borderBottom: "80px solid #2222",
    borderRight: "240px solid #2222",
    height: "0px",
    width: "0px",
    transition: "0.8s",
  },
  container_closed: {
    position: "absolute",
    width: "50px",
    height: `calc(100% - 0px)`,
  },
  triangle1_closed: {
    position: "absolute",
    bottom: 0,
    borderTop: "50px solid transparent",
    borderBottom: "190px solid #1111",
    borderLeft: "50px solid #1111",
    height: "0px",
    width: "0px",
  },
  triangle2_closed: {
    position: "absolute",
    bottom: 0,
    borderTop: "160px solid transparent",
    borderBottom: "80px solid #2222",
    borderRight: "50px solid #2222",
    height: "0px",
    width: "0px",
  },
}));

export default function Items({ open }) {
  const classes = useStyles();

  const menu = GetMenu();

  const width = open ? DRAWER_WIDTH : 50;

  return (
    <>
      <div className={open ? classes.container : classes.container_closed}>
        <div className={open ? classes.triangle1 : classes.triangle1_closed} />
        <div className={open ? classes.triangle2 : classes.triangle2_closed} />
      </div>

      <List style={{ width }} className={classes.menu}>
        {menu.map((menu) => {
          return <Item {...menu} key={menu.path} open={open} />;
        })}
      </List>
    </>
  );
}
