import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Options from "./components/Options";
import Users from "../Users";
import Roles from "../Roles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  tabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();

  const [value, setValue] = React.useState("users");

  return (
    <div className={classes.root}>
      <Options
        options={[
          { label: "Users", value: "users" },
          { label: "Roles", value: "roles" },
        ]}
        value={value}
        onChange={setValue}
      />

      {value === "users" && <Users />}
      {value === "roles" && <Roles />}
    </div>
  );
}
