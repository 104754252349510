import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API } from "../../../api";
import * as dateFns from "date-fns";

export default function InvoiceDetails() {
  const [getReport, { data, loading }] = useLazyQuery(API.GET_EXPENSES_REPORT, {
    fetchPolicy: "network-only",
  });
  console.log(data);
  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const columns = [
    {
      name: "date",
      label: "Invoice Date",
      options: { align: "center", date: true },
    },
    {
      name: "expenseAccount",
      label: "Expense Account",
      options: { align: "left", width: 200 },
    },
    {
      name: "journalNo",
      label: "Journal #",
      options: { align: "center" },
    },
    {
      name: "description",
      label: "Description",
      options: { align: "left", width: 400 },
    },
    {
      name: "amount",
      label: "Amount",
      options: { align: "right", money: true, showTotal: true },
    },
  ];

  return (
    <Report
      title="Expenses Report"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={data ?? []}
      columns={columns}
    >
      <Table
        data={data ?? []}
        columns={columns}
        showTotals
        isLoading={loading}
      />
    </Report>
  );
}
