import React from "react";
import Typography from "@material-ui/core/Typography";
import UserDetails from "./UserDetails";
import PhoneNumber from "./PhoneNumber";
import TwoFA from "./TwoFA";
import ChangePassword from "./ChangePassword";
import Section from "../components/Section";
import { useQuery, API } from "../../api";

export default function Account() {
  const { data: user } = useQuery(API.GET_AUTH_USER);

  const isRoot = user?.isRoot;
  const email = user?.email;
  const username = user?.username;
  const account = isRoot ? `Email: ${email}` : `Username: ${username}`;

  return (
    <div>
      <Typography variant="h4">{`${user?.firstName} ${user?.lastName} (${user?.role?.name})`}</Typography>
      <Typography>{account}</Typography>

      <Section title="User Details">
        <UserDetails initialValues={user} />
      </Section>

      <Section
        title="Phone Number"
        description="You will need this number to access two factor authentication enabled accounts."
      >
        <PhoneNumber />
      </Section>

      <Section
        title="Enable Two-Factor Authentication (2FA)"
        description="Two-factor authentication (2FA) requires users to enter a one-time verification code sent using your preferred channel in order to access your account."
      >
        <TwoFA initialValues={user} />
      </Section>

      <Section title="Change Password">
        <ChangePassword />
      </Section>
    </div>
  );
}
