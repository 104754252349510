import React from "react";
import { useField } from "formik";
import Switch from "@material-ui/core/Switch";

function MySwitch({
  label,
  options = [],
  onChangeComplete,
  disabled,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = undefined,
  ...props
}) {
  const [field] = useField({ ...props, type: "checkbox" });

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <Switch disabled={disabled} {...field} />
    </div>
  );
}

export default MySwitch;
