import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Input } from "../../../components/Formik";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

export default function RangeInput({
  label,
  name,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = undefined,
}) {
  const classes = useStyles();

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <Typography variant="body2">{label}</Typography>

      <div className={classes.row}>
        <Input name={`${name}.from`} label="from" type="number" right={8} />
        <Input name={`${name}.to`} label="to" type="number" />
      </div>
    </div>
  );
}
