import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
  },
  column: {
    flex: "1 1 auto",
    padding: "0 8px",
  },
  icon: {
    width: 40,
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Header({ columns = [] }) {
  const classes = useStyles();

  const getAlign = (options = {}) => {
    const { align, money } = options;
    if (money) return "right";
    return align ?? "left";
  };

  return (
    <>
      <div className={classes.row}>
        <div className={classes.icon} />

        {columns.map((t, i) => (
          <div
            key={i}
            className={classes.column}
            style={{
              width: t?.options?.width ?? 100,
              textAlign: getAlign(t?.options),
            }}
          >
            <Typography variant="subtitle2" noWrap>
              {t?.label}
            </Typography>
          </div>
        ))}
      </div>
      <Divider />
    </>
  );
}
