import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { formatMoney, formatDate } from "../../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    background: "#E8EDF2",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(1),
  },
}));

export default function Metric({ label, value, money, date }) {
  const classes = useStyles();

  let _value = value ?? " ";

  if (money) _value = formatMoney(value);
  if (date) _value = formatDate(value);

  return (
    <div className={classes.root}>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography>{Boolean(_value) ? _value : "n/a"}</Typography>
    </div>
  );
}
