import React from "react";
import { useQuery, API } from "../../api";

const PrefsContext = React.createContext(null);

export const usePrefs = () => React.useContext(PrefsContext);

export const DISCOUNT_POLICIES = { VALUE: "value", PERCENT: "percent" };

export const PrefsProvider = ({ children }) => {
  const { data: business } = useQuery(API.GET_BUSINESS);
  const { data: user } = useQuery(API.GET_AUTH_USER);

  const features = React.useMemo(() => business?.features, [business]);
  const preferences = React.useMemo(() => user?.preferences, [user]);

  const hasPermission = React.useCallback(
    (_module, _subModule, _operation = "read") => {
      // TODO fix loading state of user
      if (!user) return true;
      const role = user?.role;
      // if admin give access
      if (role?.type === "ADMIN") return true;
      // give access to dashboard
      if (_module === "" || _module === "dashboard") return true;
      const fullAccess = user?.role?.fullAccess ?? {};
      const modules = user?.role?.modules ?? {};
      // check full access
      if (fullAccess?.[_module]) return true;
      // check sub module access
      if (_subModule) {
        if (modules?.[_module]?.[_subModule]) return true;
      } else {
        if (modules?.[_module]) return true;
      }

      return false;
    },
    [user]
  );

  const value = React.useMemo(
    () => ({
      discountPolicy: features?.discountPolicy ?? DISCOUNT_POLICIES.PERCENT,
      withLoans: features?.withLoans ?? false,
      withManufacturing: features?.withManufacturing ?? false,
      withFleetTracking: features?.withFleetTracking ?? false,
      pages: preferences?.pages,

      hasPermission,
    }),

    [features, preferences, hasPermission]
  );

  return <PrefsContext.Provider value={value} children={children} />;
};
