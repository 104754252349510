import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: theme.spacing(2.5),
  },
  icon: {
    minWidth: "auto",
    fontSize: 28,
  },
}));

export default function AlertDialog({
  open,
  type,
  variant,
  title,
  message,
  onSubmit,
  onClose,
}) {
  const classes = useStyles();

  let icon = "check_circle_outline";
  let color = "#5CB660";

  if (variant === "error") {
    icon = "warning";
    color = "#F55448";
  } else if (variant === "warning") {
    icon = "warning";
    color = "#FFA117";
  } else if (variant === "info") {
    icon = "error_outline";
    color = "#35A0F4";
  }

  if (!open) return null;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <div className={classes.root}>
        <Icon style={{ color }} className={classes.icon}>
          {icon}
        </Icon>
        <DialogTitle className={classes.title}>{title}</DialogTitle>
      </div>

      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>

      {type === "alert" ? (
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            CLOSE
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            CANCEL
          </Button>
          <Button color="primary" onClick={onSubmit} autoFocus>
            CONFIRM
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
