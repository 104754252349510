import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Block({ title, icon, list = [], onClick }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.row}>
        <Icon className={classes.icon}>{icon}</Icon>
        <Typography variant="button">{title}</Typography>
      </div>

      <List dense>
        {list.map((t, i) => (
          <ListItem
            key={i}
            button
            onClick={() => onClick?.(t.value)}
            disabled={t.disabled}
          >
            <ListItemText primary={t.label} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
