import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useField } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Popper from "./Popper";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
}));

function MyCheckbox({
  label,
  hint,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = undefined,
  ...props
}) {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  const classes = useStyles();

  const ref = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (meta.touched && meta.error) {
      setAnchorEl(ref.current);
    } else {
      setAnchorEl(null);
    }
  }, [meta]);

  const onChange = (e) => {
    field.onChange(e);
    props?.onChange?.(e);
  };

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <FormControlLabel
        ref={ref}
        control={
          <Checkbox {...props} {...field} onChange={onChange} color="primary" />
        }
        label={label}
      />
      {Boolean(hint) && (
        <div className={classes.row}>
          <ErrorOutlineIcon className={classes.icon} />
          <Typography variant="caption">{hint}</Typography>
        </div>
      )}
      <Popper anchorEl={anchorEl} message={meta.error} />
    </div>
  );
}

export default MyCheckbox;
