import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ErrorIcon from "@material-ui/icons/Error";
import { AutoComplete } from ".";
import _ from "lodash";
import convert from "../../utils/convert-units";
import { formatNumber } from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    marginTop: theme.spacing(2),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "200px auto 200px 140px 140px 30px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
    minHeight: 40,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  errorIcon: {
    display: "flex",
    color: "#EE4123",
  },
  empty: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

export default function WorkflowPreview({
  form,
  inputProducts = [],
  warehouses = [],
}) {
  const classes = useStyles();

  const inputs = form.values?.inputs ?? [];

  const getValue = (item, key) => {
    const found = inputProducts?.find((t) => t._id === item?.product);
    return _.get(found, key);
  };

  const getInStock = (item) => {
    const inStock = getValue(item, `inStock.${item.warehouse}.default`) ?? 0;
    const unit = getValue(item, "unit");
    const quantity = formatNumber(inStock);
    return `${quantity} ${unit}`;
  };

  const getRequiredQty = (item) => {
    const quantity = item?.quantity;
    const unit = item?.unit;
    const best = convert(quantity).from(unit).toBest();
    const qty = best?.val;
    const val = Number.isInteger(qty) ? qty : qty?.toFixed(3);
    return `${val} ${best?.unit}`;
  };

  const isOutOfStock = (item) => {
    const inStock = getValue(item, `inStock.${item.warehouse}.default`) ?? 0;
    const unit = getValue(item, "unit");
    const requiredQuantity = convert(item?.quantity).from(item?.unit).to(unit);
    return inStock < requiredQuantity;
  };

  const getWarehouseOptions = (item) => {
    const type = getValue(item, "type");
    return warehouses?.filter((t) => t.type === type);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button" className={classes.center}>
          Code
        </Typography>
        <Typography variant="button">Description</Typography>
        <Typography variant="button" className={classes.center}>
          Warehouse
        </Typography>
        <Typography variant="button" className={classes.right}>
          In Stock
        </Typography>
        <Typography variant="button" className={classes.right}>
          Required Qty
        </Typography>
      </div>

      <Divider />

      {inputs?.length === 0 && (
        <div className={classes.empty}>
          <Typography variant="body2">Nothing to show</Typography>
        </div>
      )}

      {inputs?.map((item, index) => (
        <React.Fragment key={index}>
          <div className={classes.container}>
            <Typography className={classes.center}>
              {getValue(item, "code")}
            </Typography>
            <Typography>{getValue(item, "description")}</Typography>
            <AutoComplete
              name={`inputs[${index}].warehouse`}
              options={getWarehouseOptions(item)}
              bottom={0}
            />
            <Typography className={classes.right}>
              {getInStock(item)}
            </Typography>
            <Typography className={classes.right}>
              {getRequiredQty(item)}
            </Typography>
            {isOutOfStock(item) && <ErrorIcon className={classes.errorIcon} />}
          </div>

          <Divider />
        </React.Fragment>
      ))}
    </div>
  );
}
