export const filter = (dataSource, query) => {
  if (query === "") return dataSource;

  const regex = new RegExp(
    `${query.toString().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}`,
    "i"
  );
  const result = dataSource.filter((t) => {
    if (JSON.stringify(t).search(regex) >= 0) {
      return true;
    }
    return false;
  });

  return result;
};

export const getDuplicates = (input) => {
  const duplicates = input.reduce(function (acc, el, i, arr) {
    if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
    return acc;
  }, []);
  return duplicates;
};
