import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Skeleton from "@material-ui/lab/Skeleton";
import InnerTable from "./InnerTable";
import _ from "lodash";
import {
  formatMoney,
  formatDate,
  formatDateTime,
  formatMonth,
  toSentenceCase,
} from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
      background: "#f1f2f3",
    },
  },
  column: {
    flex: "1 1 auto",
    padding: "0 8px",
  },
  skeleton: {
    height: 24,
    flex: 1,
  },
  icon: {
    width: 40,
    display: "flex",
    justifyContent: "center",
  },
  innerTable: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    background: "#f6f7f6",
  },
}));

export default function Row({
  index,
  style,
  toggleSize,
  getSize,
  data,
  columns = [],
  innerKey,
  innerColumns = [],
  rowHeight = 42,
  isItemLoaded,
  isLoading,
}) {
  const classes = useStyles();

  const row = data?.[index];
  const isOpened = getSize?.(index) > rowHeight;

  const _toggleSize = () => {
    if (Boolean(innerKey) && row?.[innerKey]?.length > 0) {
      toggleSize?.(index);
    }
  };

  const getValue = (column) => {
    const { name, options } = column;

    const value = _.get(row, name) ?? "";

    if (options?.getValue) return options?.getValue?.(value, row);
    if (options?.sentenceCase) return toSentenceCase(value);
    if (options?.date) return formatDate(value);
    if (options?.datetime) return formatDateTime(value);
    if (options?.month) return formatMonth(value);
    if (options?.money) return formatMoney(value);

    return value;
  };

  const getAlign = (options = {}) => {
    const { align, money } = options;
    if (money) return "right";
    return align ?? "left";
  };

  if (!isItemLoaded(index) || isLoading) {
    return (
      <div style={style}>
        <div className={classes.row}>
          <Skeleton className={classes.skeleton} />
        </div>
        <Divider />
      </div>
    );
  }

  return (
    <div style={style} className={classes.root}>
      <div
        className={classes.row}
        style={{ height: rowHeight }}
        onClick={_toggleSize}
      >
        <div className={classes.icon}>
          <IconButton size="small">
            {isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
        {columns?.map((column, index) => (
          <div
            key={index}
            className={classes.column}
            style={{
              width: column?.options?.width ?? 100,
              textAlign: getAlign(column?.options),
            }}
          >
            <Typography variant="body2" noWrap component="div">
              {getValue(column)}
            </Typography>
          </div>
        ))}
      </div>

      <Divider />

      {isOpened && (
        <div className={classes.innerTable}>
          <InnerTable data={row?.[innerKey]} columns={innerColumns} />
        </div>
      )}
    </div>
  );
}
