import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import { Input, Checkbox } from ".";
import _ from "lodash";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  container: {
    display: "grid",
    gridTemplateColumns: "60px 160px auto 100px 120px 120px 120px 120px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
    minHeight: 40,
    // "&:hover": {
    //   cursor: "pointer",
    //   background: "#f4f4f4",
    // },
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
}));

export default function ReorderItems({ form, products = [] }) {
  const classes = useStyles();

  const items = form.values?.items ?? [];
  const itemCount = items.length;
  const itemSize = 50;

  const getValue = (item, key) => {
    const product = products.find((t) => t._id === item.product);
    return _.get(product, key);
  };

  const getTotalInStock = (item) => {
    return item?.inStock;
  };

  const Status = ({ item }) => {
    const totalInStock = getTotalInStock(item) ?? 0;
    const reorderLevel = getValue(item, "reorderLevel") ?? 0;

    if (reorderLevel < totalInStock) return <div />;

    const label = totalInStock <= 0 ? "Out of Stock" : "Low Stock";
    const background = totalInStock <= 0 ? "#EE4123" : "#FAAD14";

    return (
      <div style={{ textAlign: "center" }}>
        <Chip
          size="small"
          label={label}
          style={{ background, color: "#fff" }}
        />
      </div>
    );
  };

  const Item = ({ index, style }) => {
    const item = items?.[index];

    return (
      <div style={style}>
        <div className={classes.container}>
          <div className={classes.center}>
            <Checkbox
              name={`items[${index}].required`}
              options={products}
              bottom={0}
            />
          </div>
          <Typography className={classes.center}>
            {getValue(item, "code")}
          </Typography>
          <Typography>{getValue(item, "description")}</Typography>
          <Status item={item} />
          <Typography className={classes.center}>
            {getValue(item, "reorderLevel")}
          </Typography>
          <Typography className={classes.center}>
            {getTotalInStock(item)}
          </Typography>
          <Typography className={classes.center}>
            {getValue(item, "maxCapacity")}
          </Typography>
          <Input
            name={`items[${index}].quantity`}
            type="number"
            bottom={0}
            inputProps={{ style: { textAlign: "center" } }}
          />
        </div>

        <Divider />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography></Typography>
        <Typography variant="button" className={classes.center}>
          Product Code
        </Typography>
        <Typography variant="button">Description</Typography>
        <Typography variant="button" className={classes.center}>
          Status
        </Typography>
        <Typography variant="button" className={classes.center}>
          Reorder Level
        </Typography>
        <Typography variant="button" className={classes.center}>
          In Stock
        </Typography>
        <Typography variant="button" className={classes.center}>
          Max Capacity
        </Typography>
        <Typography variant="button" className={classes.center}>
          Reorder Qty
        </Typography>
      </div>

      <Divider />

      <div className={classes.list}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={itemCount}
              itemSize={itemSize}
              width={width}
            >
              {Item}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}
