import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "60px",
    background: "#fff",
  },
  errorIcon: {
    color: "#EE4123",
  },
  errorText: {
    color: "#EE4123",
    marginRight: theme.spacing(2),
  },
  warningIcon: {
    color: "#FFA117",
  },
  warningText: {
    color: "#FFA117",
    marginRight: theme.spacing(2),
  },
  button: {
    minWidth: 100,
  },
}));

export default function FormActions({
  disabled,
  newItem = true,
  onClose,
  error,
  warning,
  isSubmitting,
  confirmText,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DialogActions>
        {Boolean(error) && (
          <>
            <ErrorOutlineIcon className={classes.errorIcon} />
            <Typography className={classes.errorText}>{error}</Typography>
          </>
        )}
        {Boolean(warning) && (
          <>
            <ErrorOutlineIcon className={classes.warningIcon} />
            <Typography className={classes.warningText}>{warning}</Typography>
          </>
        )}

        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          disabled={disabled || Boolean(error) || isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
          endIcon={isSubmitting && <CircularProgress size={16} />}
          className={classes.button}
        >
          {confirmText || `${newItem ? "Save" : "Update"}`}
        </Button>
      </DialogActions>
    </div>
  );
}
