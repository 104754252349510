import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useLazyQuery, API } from "../../api";
import Usage from "./components/Usage";
import * as dateFns from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  usage: {
    margin: theme.spacing(2, 0),
  },
}));

export default function Billing() {
  const [create] = useLazyQuery(API.CREATE_PAYMENT_SESSION, {
    fetchPolicy: "no-cache",
    onCompleted: (res) => window.open(res?.url),
  });
  const [getReport, { data }] = useLazyQuery(API.GET_USAGE_REPORT);

  const classes = useStyles();

  const plan = data?.plan;
  const status = data?.status;
  const trialExpireAt = data?.trialExpireAt;
  const subscriptionExpireAt = data?.subscriptionExpireAt;

  const quota = data?.quota;
  const usage = data?.usage;

  React.useEffect(() => {
    getReport();
    // eslint-disable-next-line
  }, []);

  const getDifference = (date) => {
    const days = dateFns.differenceInDays(dateFns.parseISO(date), new Date());
    return isNaN(days) ? "" : days;
  };

  const getStatusMessage = () => {
    if (status === "TRIAL") {
      const days = getDifference(trialExpireAt);
      return `You are currently on Free Trial. Your Trial expires in ${days} days.`;
    } else if (status === "SUBSCRIBED") {
      const days = getDifference(subscriptionExpireAt);
      return `You are currently on ${plan} plan. Your subscription expires in ${days} days.`;
    }
  };

  const onUpgrade = () => {
    window.open("/subscribe");
    create();
  };

  return (
    <div>
      <Typography>{getStatusMessage()}</Typography>

      <button onClick={onUpgrade}>Upgrade</button>

      <div className={classes.usage}>
        <Typography variant="h6">Current Usage</Typography>
      </div>

      <Usage
        label="Users"
        caption="Users limit of your account (including the Admin account)"
        limit={quota?.users}
        usage={usage?.users}
      />
      <Usage
        label="Customers"
        caption="Customers limit of your account"
        limit={quota?.customers}
        usage={usage?.customers}
      />
      <Usage
        label="Invoices"
        caption="Monthly invoice limit of your account"
        limit={quota?.invoices}
        duration="month"
        usage={usage?.invoices}
      />
    </div>
  );
}
