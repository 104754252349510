import {
  getItemDiscount,
  getAmount,
  getSubTotal,
  getDiscount,
  getNetTotal,
} from "../../utils/orderUtils";
import { formatMoney, formatDate, formatTime } from "../../utils/formatters";

export const LABEL_OPTIONS = [
  { label: "Date", value: "date", format: (t) => formatDate(t) },
  { label: "Time", value: "time", format: (t, i) => formatTime(i?.date) },
  { label: "Customer Name", value: "customer.name" },
  { label: "Customer Code", value: "customer.code" },
  { label: "Address Line 1", value: "customer.addressLine1" },
  { label: "Address Line 2", value: "customer.addressLine2" },
  { label: "City", value: "customer.city" },
  { label: "Province", value: "customer.province" },
  { label: "Postal", value: "customer.postal" },
  { label: "Phone", value: "customer.phone" },
  { label: "Mobile", value: "customer.mobile" },
  { label: "Route Name", value: "customer.route.name" },
  { label: "Route Code", value: "customer.route.code" },
  { label: "Sales Rep", value: "salesRep.name" },
  { label: "Invoice No", value: "invoiceNo" },
  { label: "Sales Order No", value: "salesOrder.salesOrderNo" },
  { label: "Remarks", value: "remarks" },
  {
    label: "Sub Total",
    value: "subTotal",
    map: (t, i) => getSubTotal(i?.items ?? [], "salesPrice"),
    format: (t) => formatMoney(t),
  },
  {
    label: "Discount",
    value: "discount",
    map: (t, i) => getDiscount(i, "salesPrice"),
    format: (t) => formatMoney(t),
  },
  {
    label: "Net Total",
    value: "netTotal",
    map: (t, i) => getNetTotal(i, "salesPrice"),
    format: (t) => formatMoney(t),
  },
];

export const COLUMN_OPTIONS = [
  { label: "Code", value: "product.code" },
  { label: "Description", value: "product.description" },
  { label: "SKU", value: "product.sku" },
  { label: "Quantity", value: "quantity" },
  { label: "Sales Price", value: "salesPrice", format: (t) => formatMoney(t) },
  { label: "Cost", value: "cost", format: (t) => formatMoney(t) },
  {
    label: "Discount",
    value: "discount",
    map: (v, i) => getItemDiscount(i, "salesPrice"),
    format: (t) => formatMoney(t),
  },
  {
    label: "Total",
    value: "total",
    map: (v, i) => getAmount(i, "salesPrice"),
    format: (t) => formatMoney(t),
  },
];
