import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Report from "../components/Report";
import { formatMoney } from "../../../utils/formatters";
import { useLazyQuery, API } from "../../../api";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
  },
  header: {
    borderTop: "1px solid #e4e4e4",
    borderBottom: "1px solid #e4e4e4",
    padding: theme.spacing(0.5, 0, 0.5, 0),
    color: "#999",
    marginBottom: theme.spacing(2),
  },
  col: {
    flex: 1,
    textAlign: "right",
  },
  bold: {
    fontWeight: "bold",
  },
}));

export default function BalanceSheet() {
  const [getReport, { data }] = useLazyQuery(API.GET_BALANCE_SHEET, {
    fetchPolicy: "network-only",
  });

  const classes = useStyles();

  React.useEffect(() => {
    getReport();
    // eslint-disable-next-line
  }, []);

  const assets = data?.filter((t) => t.type === "ASSET") ?? [];
  const liabilities = data?.filter((t) => t.type === "LIABILITY") ?? [];
  const equity = data?.filter((t) => t.type === "EQUITY") ?? [];

  const liabilitiesAndEquity = [...liabilities, ...equity];

  const assetSum = assets?.reduce((a, b) => a + b.balance, 0);
  const liabilitiesAndEquitySum = liabilitiesAndEquity?.reduce(
    (a, b) => a + b.balance,
    0
  );

  return (
    <Report title="Balance Sheet">
      <Container maxWidth="lg">
        <Paper className={classes.content} elevation={0}>
          <Header />
          <Item label="ASSETS" bold />
          {assets?.map((t, i) => (
            <Item key={i} label={t.account} col3={t.balance} />
          ))}
          <br />
          <Item label="Total" col3={assetSum} />
          <br />
          <br />
          <Item label="LIABILITIES" bold />
          {liabilities?.map((t, i) => (
            <Item key={i} label={t.account} col3={t.balance} />
          ))}
          <br />
          <br />
          <Item label="EQUITY" bold />
          {equity?.map((t, i) => (
            <Item key={i} label={t.account} col3={t.balance} />
          ))}
          <br />
          <Item label="Total" col3={liabilitiesAndEquitySum} />
        </Paper>
      </Container>
    </Report>
  );
}

function Header() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.row, classes.header)}>
      <Typography className={classes.text1}>Description</Typography>
    </div>
  );
}

function Item({ label, col1, col2, col3, bracket, bold }) {
  const classes = useStyles();

  const getText = (c) => {
    if (c === undefined || c === null) return "";
    const value = formatMoney(c);
    return bracket ? `(${value})` : value;
  };

  const getTextStyle = () => {
    if (bold) {
      return classes.bold;
    }
  };

  return (
    <div className={classes.row}>
      <div style={{ flex: 1 }}>
        <Typography className={getTextStyle()}>{label}</Typography>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className={classes.col}>
          <Typography className={getTextStyle()}>{getText(col1)}</Typography>
        </div>
        <div className={classes.col}>
          <Typography className={getTextStyle()}>{getText(col2)}</Typography>
        </div>
        <div className={classes.col}>
          <Typography className={getTextStyle()}>{getText(col3)}</Typography>
        </div>
      </div>
    </div>
  );
}
