import React from "react";
import {
  Chart,
  LineController,
  LineElement,
  BarController,
  BarElement,
  PointElement,
  LinearScale,
  Title,
  TimeScale,
  Tooltip,
  ArcElement,
  DoughnutController,
} from "chart.js";
import "chartjs-adapter-date-fns";

Chart.register(
  LineController,
  LineElement,
  BarController,
  BarElement,
  PointElement,
  LinearScale,
  Title,
  TimeScale,
  Tooltip,
  ArcElement,
  DoughnutController
);

export default function MyChart({ type, data, options }) {
  const chartRef = React.useRef();
  let _chart = React.useRef();

  React.useLayoutEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");

    if (typeof _chart.current !== "undefined") _chart.current.destroy();

    const config = { type, data, options };

    _chart.current = new Chart(myChartRef, config);
  }, [type, data, options]);

  return <canvas ref={chartRef} />;
}
