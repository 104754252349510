import { useDrag } from "react-dnd";

const style = {
  position: "absolute",
  padding: "1px 1px",
  cursor: "move",
  borderRadius: 2,
};

export const Box = ({
  id,
  left,
  top,
  width,
  align,
  children,
  onMouseDown,
  selected,
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "box",
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  );

  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div
      ref={drag}
      className="font"
      style={{
        ...style,
        border: selected ? "2px solid blue" : "2px solid gray",
        left,
        top,
        width,
        textAlign: align,
      }}
      // eslint-disable-next-line
      role="Box"
      onMouseDown={onMouseDown}
    >
      {children}
    </div>
  );
};
