import React from "react";
import { makeStyles, lighten } from "@material-ui/core/styles";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    background: "#fff",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  menuButton: {
    marginLeft: theme.spacing(0.1),
  },
}));

function TableToolbar({ numSelected, selected, batchActions = [] }) {
  const classes = useStyles();

  return (
    <Collapse in={Boolean(selected.length)} timeout={{ enter: 400, exit: 400 }}>
      <Toolbar
        variant="dense"
        className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })}
      >
        {numSelected > 0 && (
          <Typography
            className={classes.title}
            color="inherit"
            variant="body1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        )}

        {numSelected > 0 &&
          batchActions?.map((action, index) => (
            <Tooltip key={index} title={action?.tooltip ?? ""}>
              <IconButton
                color="inherit"
                edge="start"
                onClick={() => action?.onClick(selected)}
                disabled={action?.disabled}
                className={classes.menuButton}
              >
                <Icon className={classes.icon}>{action?.icon}</Icon>
              </IconButton>
            </Tooltip>
          ))}
      </Toolbar>
    </Collapse>
  );
}

export default React.memo(TableToolbar);
