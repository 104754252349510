import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Input, AutoComplete } from ".";
import _ from "lodash";
import { formatMoney } from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "200px auto 140px 140px 140px 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
}));

export default function ContactItems({ push, remove, form, contacts = [] }) {
  const classes = useStyles();

  const getValue = (index, key) => {
    const item = form?.values?.contacts?.[index];
    const contact = contacts.find((t) => t._id === item.contact);
    return _.get(contact, key);
  };

  const onContactSelected = (contact, index) => {
    form.setFieldValue(`contacts[${index}].systemBalance`, contact?.balance);
  };

  const getAmount = (index) => {
    const item = form?.values?.contacts?.[index];
    const actualBalance = item?.actualBalance ?? 0;
    const systemBalance = item?.systemBalance ?? 0;
    return actualBalance - systemBalance;
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button" className={classes.center}>
          Code
        </Typography>
        <Typography variant="button">Name</Typography>
        <Typography variant="button" className={classes.right}>
          System Balance
        </Typography>
        <Typography variant="button" className={classes.right}>
          Actual Balance
        </Typography>
        <Typography variant="button" className={classes.right}>
          Change
        </Typography>
      </div>

      <Divider />

      {form.values?.contacts?.map((value, index) => (
        <React.Fragment key={index}>
          <div className={classes.container}>
            <AutoComplete
              name={`contacts[${index}].contact`}
              options={contacts}
              onChangeComplete={(e) => onContactSelected(e, index)}
              bottom={0}
            />

            <Typography>{getValue(index, "name")}</Typography>

            <div className={classes.right}>
              <Typography>{formatMoney(getValue(index, "balance"))}</Typography>
            </div>

            <Input
              name={`contacts[${index}].actualBalance`}
              type="number"
              bottom={0}
            />

            <div className={classes.right}>
              <Typography>{formatMoney(getAmount(index))}</Typography>
            </div>

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => remove(index)}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </div>

          <Divider />
        </React.Fragment>
      ))}

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() => push({ quantity: 0 })}
          startIcon={<AddIcon />}
        >
          Add Lines
        </Button>
      </div>
    </div>
  );
}
