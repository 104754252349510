import React from "react";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Popper from "./Popper";

export default function Input({
  label,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = undefined,
  hint,
  onChangeText,
  ...props
}) {
  const [{ value, onChange }, meta] = useField(props);

  const ref = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (meta.touched && meta.error) {
      setAnchorEl(ref.current);
    } else {
      setAnchorEl(null);
    }
  }, [meta]);

  const _onChange = (e) => {
    onChange(e);
    onChangeText?.(e?.target?.value);
  };

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <TextField
        ref={ref}
        fullWidth
        label={label}
        variant="outlined"
        size="small"
        value={value ?? ""}
        onChange={_onChange}
        {...props}
        error={(meta.touched && meta.error && true) || false}
        // helperText={meta.touched && meta.error}
      />
      {Boolean(hint) && <Typography variant="caption">{hint}</Typography>}
      <Popper anchorEl={anchorEl} message={meta.error} />
    </div>
  );
}
