import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Report from "../components/Report";
import { formatMoney, formatDate } from "../../../utils/formatters";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import * as dateFns from "date-fns";
import { useLazyQuery, API } from "../../../api";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 1 auto",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "auto 140px 140px",
    alignItems: "center",
    columnGap: 16,
    padding: 6,
    minHeight: 40,
    background: "#fff",
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  footer: {
    background: "#F0F0F0",
  },
  loading: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function JournalReport() {
  const [getReport, { data, loading }] = useLazyQuery(API.GET_JOURNAL_REPORT);

  const classes = useStyles();

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const getTotal = (entries, key) => {
    return entries?.reduce((a, b) => a + (b?.[key] ?? 0), 0);
  };

  const getItemSize = (index) => {
    const item = data?.[index];
    const l = item?.entries?.length ?? 0;
    return (l + 2) * 40 + 20;
  };

  const Row = ({ index, style }) => {
    const item = data?.[index];
    const entries = item?.entries ?? [];

    const journalNo = item?.journalNo;
    const date = item?.date;
    const description = item?.description;

    const text = `${formatDate(date)}  - ${journalNo} - ${description}`;

    return (
      <div style={style}>
        <div className={classes.container}>
          <Typography variant="button">{text}</Typography>
          <Typography variant="button" className={classes.right}>
            DEBIT
          </Typography>
          <Typography variant="button" className={classes.right}>
            CREDIT
          </Typography>
        </div>
        <Divider />
        {entries.map((t, j) => (
          <React.Fragment key={j}>
            <div className={classes.container}>
              <Typography variant="body1">{t?.account?.name}</Typography>
              <Typography variant="body1" className={classes.right}>
                {formatMoney(t?.debit)}
              </Typography>
              <Typography variant="body1" className={classes.right}>
                {formatMoney(t?.credit)}
              </Typography>
            </div>
            {j < entries.length - 1 && <Divider />}
          </React.Fragment>
        ))}
        <div className={clsx(classes.container, classes.footer)}>
          <div />
          <Typography variant="button" className={classes.right}>
            {formatMoney(getTotal(entries, "debit"))}
          </Typography>
          <Typography variant="button" className={classes.right}>
            {formatMoney(getTotal(entries, "credit"))}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Report
      title="Journal Report"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      hideExport
    >
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress size={48} />{" "}
        </div>
      ) : (
        <div className={classes.root}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="List"
                height={height}
                width={width}
                itemCount={data?.length ?? 0}
                itemSize={getItemSize}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        </div>
      )}
    </Report>
  );
}
