export const BUSINESS_OVERVIEW = [
  { label: "Profit and Loss", value: "/profit-and-loss" },
  { label: "Balance Sheet", value: "/balance-sheet" },
  { label: "Cash Flow Statement", value: "/cash-flow-statement" },
];

export const SALES = [
  { label: "Sales by Customer", value: "/sales-by-customer" },
  { label: "Sales by Product", value: "/sales-by-product" },
  { label: "Sales Details", value: "/sales-details" },
];

export const INVENTORY = [
  { label: "Inventory Summary", value: "/inventory-summary" },
  {
    label: "Inventory Valuation Summary",
    value: "/inventory-valuation-summary",
  },
  { label: "Product Sales Report", value: "/product-sales-report" },
  { label: "Bin Card", value: "/bin-card" },
];

export const RECEIVABLES = [
  { label: "Customer Balances", value: "/customer-balances" },
  { label: "Aging Summary", value: "/aging-summary" },
  { label: "Aging Details", value: "/aging-details" },
  { label: "Invoice Details", value: "/invoice-details" },
  // "Sales Order Details"
  { label: "Payments Received", value: "/payments-received" },
  { label: "Cheque Returns", value: "/cheque-returns" },
  { label: "Sales Return Details", value: "/sales-return-details" },
];

export const PAYABLES = [
  { label: "Supplier Balances", value: "/supplier-balances" },
  { label: "GRN Details", value: "/grn-details" },
  { label: "Payments Made", value: "/payments-made" },
];

export const ACCOUNTANT = [
  // "Account Transactions",
  // "Account Type Summary",
  { label: "General Ledger", value: "/general-ledger" },
  { label: "Journal Report", value: "/journal-report" },
  { label: "Expenses Report", value: "/expenses-report" },
  // "Trial Balance"
];

export const ACTIVITY = [{ label: "Activity Log", value: "/activity-log" }];

export const ANALYTICS = [
  { label: "Customer History", value: "/customer-history" },
  { label: "Customer Profile", value: "/customer-profile" },
  { label: "Customer Time To Pay", value: "/customer-time-to-pay" },
  { label: "Customer Last Visit", value: "/customer-last-visit" },
  { label: "Product Analysis", value: "/product-analysis" },
  { label: "Operator Report", value: "/operator-report" },
];
