import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import { useQuery, API } from "../../api";

const ThemeContext = React.createContext(null);

export const useTheme = () => React.useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const { data: business } = useQuery(API.GET_BUSINESS);

  const primaryTheme = business?.general?.theme ?? "#122C44";

  const theme = createMuiTheme({
    palette: {
      primary: { main: primaryTheme },
      secondary: { main: "#19324A" },
      error: { main: "#D32F2F" },
      warning: { main: "#ff9800" },
      info: { main: "#2196f3" },
      success: { main: "#43A047" },
    },
    // typography: {
    //   fontFamily:
    //     'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji',
    // },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*": {
            "scrollbar-width": "thin",
          },
          "*::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
            background: "#EAEAEA",
          },
          "*::-webkit-scrollbar-track": {
            background: "#EAEAEA",
          },
          "*::-webkit-scrollbar-thumb": {
            background: "#BCBCBC",
            borderRadius: "48px",
            border: "1px solid #BCBCBC",
          },
        },
      },
    },
  });

  return (
    <ThemeContext.Provider value={theme}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
