import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API, useQuery } from "../../../api";
import AutoComplete from "../components/Auto";
import Metric from "../components/Metric";
import Options from "../components/Options";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  text: {
    margin: theme.spacing(1, 0),
  },
}));

export default function CustomerProfile() {
  const [getReport, { data }] = useLazyQuery(API.GET_CUSTOMER_PROFILE_V2, {
    fetchPolicy: "network-only",
  });
  const { data: customers } = useQuery(API.GET_CUSTOMERS);

  const dueInvoices = data?.dueInvoices ?? [];
  const topItems = data?.topItems ?? [];

  const averageSale = data?.averageSale;
  const balanceDue = data?.balanceDue;
  const invoiceFrequency = data?.invoiceFrequency
    ? `${data?.invoiceFrequency} Days`
    : "";
  const lastInvoiced = data?.lastInvoiced;
  const averageTimeToPay = data?.averageTimeToPay
    ? `${data?.averageTimeToPay} Days`
    : "";

  const customerOptions = React.useMemo(
    () =>
      customers?.result?.map((t) => ({
        title: t.name,
        subtitle: t.code,
        value: t._id,
      })) ?? [],
    [customers]
  );

  const classes = useStyles();

  const [filters, setFilters] = React.useState({ customer: "" });
  const [value, setValue] = React.useState("invoices");

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onCustomerChange = (e) => {
    setFilters({ ...filters, customer: e?.value });
  };

  const invoicesColumns = [
    {
      name: "date",
      label: "Invoice Date",
      options: { align: "center", date: true },
    },
    {
      name: "invoiceNo",
      label: "Invoice #",
      options: { align: "center" },
    },
    {
      name: "netTotal",
      label: "Total",
      options: { align: "right", money: true, showTotal: true },
    },
    {
      name: "dueAmount",
      label: "Balance",
      options: { align: "right", money: true, showTotal: true },
    },
  ];

  const topProductsColumns = [
    {
      name: "code",
      label: "Product Code",
      options: { align: "center" },
    },
    {
      name: "description",
      label: "Product Description",
      options: { align: "left" },
    },
    {
      name: "quantity",
      label: "Quantity",
      options: { align: "center" },
    },
  ];

  return (
    <Report title="Customer Profile" hideExport>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <AutoComplete
          label="Select Customer"
          options={customerOptions}
          value={filters.customer}
          onChangeComplete={onCustomerChange}
          width={300}
        />
        <div className={classes.row}>
          <Metric label="Average Sale" value={averageSale} money />
          <Metric label="Balance Due" value={balanceDue} money />
          <Metric label="Invoice Frequency" value={invoiceFrequency} />
          <Metric label="Last Invoiced" value={lastInvoiced} date />
          <Metric label="Time To Pay" value={averageTimeToPay} />
        </div>

        <Options
          options={[
            { label: "Invoices", value: "invoices" },
            { label: "Top Products", value: "topProducts" },
          ]}
          value={value}
          onChange={setValue}
        />

        {value === "invoices" && (
          <Table data={dueInvoices} columns={invoicesColumns} showTotals />
        )}

        {value === "topProducts" && (
          <Table data={topItems} columns={topProductsColumns} showTotals />
        )}
      </div>
    </Report>
  );
}
