import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ImagePicker } from ".";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function ImageItems({ push, remove, form, products = [] }) {
  const classes = useStyles();

  const onImageAdded = () => {
    push(null);
  };

  return (
    <div className={classes.root}>
      {form.values?.images?.map((image, index) => (
        <ImagePicker
          key={index}
          name={`images[${index}]`}
          onPicked={onImageAdded}
          right={16}
        />
      ))}
    </div>
  );
}
