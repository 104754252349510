import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Header from "./Header";
import Row from "./Row";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    background: "#fff",
    borderRadius: 10,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  column: {
    flexGrow: 1,
    padding: theme.spacing(1, 2, 1, 2),
    borderTop: "1px solid #F0F0F0",
  },
}));

export default function TableX({
  isLoading,
  data,
  columns,
  showTotals,
  itemSize = 42,
}) {
  const classes = useStyles();

  const itemCount = isLoading ? 20 : data?.length ?? 0;

  return (
    <Paper className={classes.root} elevation={0}>
      <Header columns={columns} />

      <div className={classes.table}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              itemCount={itemCount}
              itemSize={itemSize}
            >
              {(props) => (
                <Row
                  {...props}
                  data={data}
                  columns={columns}
                  isLoading={isLoading}
                />
              )}
            </List>
          )}
        </AutoSizer>
      </div>

      <Footer data={data} columns={columns} />
    </Paper>
  );
}
