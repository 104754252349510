import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";

const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.error.main,
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
    color: "#fff",
    marginTop: 4,
  },
  popper: {
    zIndex: 1500,
  },
}));

export default function Input({ anchorEl, message }) {
  const classes = useStyles();

  const open = Boolean(anchorEl);

  return (
    <Popper open={open} anchorEl={anchorEl} className={classes.popper}>
      <div className={classes.paper}>{message}</div>
    </Popper>
  );
}
