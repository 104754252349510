export const API = {
  CHECK: "post /auth/check",
  LOGIN: "post /auth/login",
  LOGIN_2FA: "post /auth/login-2fa",
  REGISTER: "post /auth/register",
  REGISTER_IAM: "post /auth/register-iam",
  FORGOT: "post /auth/forgot",
  RESET: "post /auth/reset",
  UPDATE: "post /auth/update",
  SETUP_ACCOUNT: "post /auth/setup-account",
  CHANGE_PASSWORD: "post /auth/change-password",
  ENABLE_2FA: "post /auth/enable-2fa",
  VERIFY_2FA: "post /auth/verify-2fa",

  GET_BUSINESS: "get /business",
  UPDATE_BUSINESS: "put /business",
  UPDATE_LOGO: "post /business/logo",

  LINK_GMAIL: "post /google/allow",
  REVOKE_GMAIL: "patch /google/revoke",

  GET_NOTIFICATIONS: "get /notification",

  GET_USERS: "get /user",
  UPDATE_USER: "put /user/$_id $_id",
  DELETE_USER: "delete /user/$_id $_id",
  GET_AUTH_USER: "get /user/auth",
  UPDATE_PREFERENCES: "patch /user/preferences",

  GET_ROLES: "get /role",
  CREATE_ROLE: "post /role",
  UPDATE_ROLE: "put /role/$_id $_id",
  DELETE_ROLE: "delete /role/$_id $_id",

  GET_PRODUCTS: "get /product",
  GET_PRODUCT: "get /product/$_id $_id",
  CREATE_PRODUCT: "post /product",
  UPDATE_PRODUCT: "put /product/$_id $_id",
  DELETE_PRODUCT: "delete /product/$_id $_id",
  IMPORT_PRODUCTS: "post /product/import",
  UPLOAD_IMAGE: "post /product/image",

  ADJUST_STOCK: "post /product/stock",
  GET_STOCK_ADJUSTMENTS: "get /product/adjustment",
  GET_STOCK_ADJUSTMENT: "get /product/adjustment/$_id $_id",

  GET_RECIPES: "get /recipe",
  GET_RECIPE: "get /recipe/$_id $_id",
  CREATE_RECIPE: "post /recipe",
  UPDATE_RECIPE: "put /recipe/$_id $_id",
  DELETE_RECIPE: "delete /recipe/$_id $_id",

  GET_WORKFLOWS: "get /workflow",
  GET_WORKFLOW: "get /workflow/$_id $_id",
  CREATE_WORKFLOW: "post /workflow",
  UPDATE_WORKFLOW: "put /workflow/$_id $_id",
  DELETE_WORKFLOW: "delete /workflow/$_id $_id",
  COMPLETE_WORKFLOW: "post /workflow/complete",

  GET_WAREHOUSES: "get /warehouse",
  CREATE_WAREHOUSE: "post /warehouse",
  UPDATE_WAREHOUSE: "put /warehouse/$_id $_id",
  DELETE_WAREHOUSES: "delete /warehouse",
  GET_VEHICLE: "get /warehouse/vehicles", // << todo

  GET_VEHICLES: "get /vehicle",
  CREATE_VEHICLE: "post /vehicle",
  UPDATE_VEHICLE: "put /vehicle/$_id $_id",
  DELETE_VEHICLES: "delete /vehicle",
  GET_FLEET: "post /vehicle/fleet",
  GET_FLEET_TRACKING: "post /vehicle/fleet-tracking",

  GET_GROUPS: "get /group",
  CREATE_GROUP: "post /group",
  UPDATE_GROUP: "put /group/$_id $_id",
  DELETE_GROUPS: "delete /group",

  GET_PRICE_LISTS: "get /price-list",
  GET_PRICE_LIST: "get /price-list/$_id $_id",
  CREATE_PRICE_LIST: "post /price-list",
  UPDATE_PRICE_LIST: "put /price-list/$_id $_id",
  DELETE_PRICE_LIST: "delete /price-list/$_id $_id",

  GET_ALARMS: "get /alarm",
  CREATE_ALARM: "post /alarm",
  UPDATE_ALARM: "put /alarm/$_id $_id",
  DELETE_ALARMS: "delete /alarm",

  GET_TARGET: "post /target/get",
  SET_TARGET: "post /target/set",

  GET_CUSTOMERS: "get /customer",
  GET_CUSTOMER: "get /customer/$_id $_id",
  CREATE_CUSTOMER: "post /customer",
  UPDATE_CUSTOMER: "put /customer/$_id $_id",
  DELETE_CUSTOMER: "delete /customer/$_id $_id",
  IMPORT_CUSTOMERS: "post /customer/import",
  GET_DUE_CUSTOMERS: "get /customer/due",
  GET_CUSTOMER_LOCATIONS: "get /customer/locations",

  ADJUST_CUSTOMER_BALANCE: "post /customer/balance",
  GET_CUSTOMER_BALANCE_ADJUSTMENTS: "get /customer/adjustment",
  GET_CUSTOMER_BALANCE_ADJUSTMENT: "get /customer/adjustment/$_id $_id",

  GET_CATEGORIES: "get /category",
  CREATE_CATEGORY: "post /category",
  UPDATE_CATEGORY: "put /category/$_id $_id",
  DELETE_CATEGORY: "delete /category/$_id $_id",

  GET_SUPPLIERS: "get /supplier",
  GET_SUPPLIER: "get /supplier/$_id $_id",
  CREATE_SUPPLIER: "post /supplier",
  UPDATE_SUPPLIER: "put /supplier/$_id $_id",
  DELETE_SUPPLIER: "delete /supplier/$_id $_id",
  IMPORT_SUPPLIERS: "post /supplier/import",
  GET_DUE_SUPPLIERS: "get /supplier/due",

  ADJUST_SUPPLIER_BALANCE: "post /supplier/balance",
  GET_SUPPLIER_BALANCE_ADJUSTMENTS: "get /supplier/adjustment",
  GET_SUPPLIER_BALANCE_ADJUSTMENT: "get /supplier/adjustment/$_id $_id",

  GET_ROUTES: "get /route",
  CREATE_ROUTE: "post /route",
  UPDATE_ROUTE: "put /route/$_id $_id",
  DELETE_ROUTES: "delete /route",
  GET_SUGGESTED_ROUTE: "post /route/suggested",

  GET_SALES_REPS: "get /sales-rep",
  CREATE_SALES_REP: "post /sales-rep",
  UPDATE_SALES_REP: "put /sales-rep/$_id $_id",
  DELETE_SALES_REPS: "delete /sales-rep",
  GET_DRIVERS: "get /sales-rep/driver",

  GET_PURCHASES_ORDERS: "get /purchases-order",
  GET_PURCHASES_ORDER: "get /purchases-order/$_id $_id",
  CREATE_PURCHASES_ORDER: "post /purchases-order",
  UPDATE_PURCHASES_ORDER: "put /purchases-order/$_id $_id",
  DELETE_PURCHASES_ORDER: "delete /purchases-order/$_id $_id",
  SEND_PURCHASES_ORDER: "post /purchases-order/send",

  GET_GRNS: "get /grn",
  GET_GRN: "get /grn/$_id $_id",
  CREATE_GRN: "post /grn",
  UPDATE_GRN: "put /grn/$_id $_id",
  DELETE_GRN: "delete /grn/$_id $_id",
  GET_GRNS_BY_SUPPLIER: "post /grn/supplier",

  GET_GRN_PAYMENTS: "get /grn-payment",
  GET_GRN_PAYMENT: "get /grn-payment/$_id $_id",
  CREATE_GRN_PAYMENT: "post /grn-payment",
  UPDATE_GRN_PAYMENT: "put /grn-payment/$_id $_id",
  DELETE_GRN_PAYMENT: "delete /grn-payment/$_id $_id",
  GET_GRN_CHEQUES: "get /grn-payment/cheques",

  GET_PURCHASES_RETURNS: "get /purchases-return",
  GET_PURCHASES_RETURN: "get /purchases-return/$_id $_id",
  CREATE_PURCHASES_RETURN: "post /purchases-return",
  UPDATE_PURCHASES_RETURN: "put /purchases-return/$_id $_id",
  DELETE_PURCHASES_RETURN: "delete /purchases-return/$_id $_id",

  GET_ACCOUNTS: "get /account",
  CREATE_ACCOUNT: "post /account",
  UPDATE_ACCOUNT: "put /account/$_id $_id",
  DELETE_ACCOUNTS: "delete /account",

  GET_EXPENSES: "get /journal/expense",
  CREATE_EXPENSE: "post /journal/expense",
  GET_MANUAL_JOURNALS: "get /journal/manual",
  CREATE_MANUAL_JOURNAL: "post /journal/manual",
  DELETE_JOURNAL: "delete /journal/$_id $_id",

  GET_RECURRING_EXPENSES: "get /recurring-expense",
  GET_RECURRING_EXPENSE: "get /recurring-expense/$_id $_id",
  CREATE_RECURRING_EXPENSE: "post /recurring-expense",
  UPDATE_RECURRING_EXPENSE: "put /recurring-expense/$_id $_id",
  DELETE_RECURRING_EXPENSE: "delete /recurring-expense/$_id $_id",

  GET_SALES_ORDERS: "get /sales-order",
  GET_SALES_ORDER: "get /sales-order/$_id $_id",
  CREATE_SALES_ORDER: "post /sales-order",
  UPDATE_SALES_ORDER: "put /sales-order/$_id $_id",
  DELETE_SALES_ORDER: "delete /sales-order/$_id $_id",

  GET_INVOICES: "get /invoice",
  GET_INVOICE: "get /invoice/$_id $_id",
  CREATE_INVOICE: "post /invoice",
  UPDATE_INVOICE: "put /invoice/$_id $_id",
  DELETE_INVOICE: "delete /invoice/$_id $_id",
  GET_INVOICES_BY_CUSTOMER: "post /invoice/customer",

  GET_PAYMENTS: "get /payment",
  GET_PAYMENT: "get /payment/$_id $_id",
  CREATE_PAYMENT: "post /payment",
  UPDATE_PAYMENT: "put /payment/$_id $_id",
  DELETE_PAYMENT: "delete /payment/$_id $_id",
  GET_CHEQUES: "get /payment/cheques",
  UPDATE_CHEQUE: "post /payment/cheques",

  GET_SALES_RETURNS: "get /sales-return",
  GET_SALES_RETURN: "get /sales-return/$_id $_id",
  CREATE_SALES_RETURN: "post /sales-return",
  UPDATE_SALES_RETURN: "put /sales-return/$_id $_id",
  DELETE_SALES_RETURN: "delete /sales-return/$_id $_id",

  GET_LOAN_INVENTORY: "get /loan-inventory",

  GET_LOANS: "get /loan",
  GET_LOAN: "get /loan/$_id $_id",
  CREATE_LOAN: "post /loan",
  UPDATE_LOAN: "put /loan/$_id $_id",
  DELETE_LOAN: "delete /loan/$_id $_id",
  GET_PENDING_LOANS: "post /loan/pending",

  GET_LOAN_PAYMENTS: "get /loan-payment",
  GET_LOAN_PAYMENT: "get /loan-payment/$_id $_id",
  CREATE_LOAN_PAYMENT: "post /loan-payment",
  UPDATE_LOAN_PAYMENT: "put /loan-payment/$_id $_id",
  DELETE_LOAN_PAYMENT: "delete /loan-payment/$_id $_id",

  GET_TRANSFERS: "get /transfer",
  CREATE_TRANSFER: "post /transfer",
  UPDATE_TRANSFER: "put /transfer/$_id $_id",
  DELETE_TRANSFER: "delete /transfer/$_id $_id",
  COMPLETE_TRANSFER: "post /transfer/complete",

  GET_MRO_TRANSACTIONS: "get /mro-transaction",
  GET_MRO_TRANSACTION: "get /mro-transaction/$_id $_id",
  CREATE_MRO_TRANSACTION: "post /mro-transaction",
  UPDATE_MRO_TRANSACTION: "put /mro-transaction/$_id $_id",
  DELETE_MRO_TRANSACTION: "delete /mro-transaction/$_id $_id",

  GET_SCHEDULE: "post /schedule",
  UPDATE_SCHEDULE: "put /schedule",
  DELETE_SCHEDULE: "delete /schedule/$_id $_id",

  GET_DISTRIBUTIONS: "get /distribution",
  GET_DISTRIBUTION: "get /distribution/$_id $_id",
  CREATE_DISTRIBUTION: "post /distribution",
  UPDATE_DISTRIBUTION: "put /distribution/$_id $_id",
  DELETE_DISTRIBUTION: "delete /distribution/$_id $_id",
  COMPLETE_DISTRIBUTION: "post /distribution/complete",

  START_TASK: "post /task/start",
  COMPLETE_TASK: "post /task/complete",

  GET_PACKAGES: "get /package",
  GET_PACKAGE: "get /package/$_id $_id",
  CREATE_PACKAGE: "post /package",
  UPDATE_PACKAGE: "put /package/$_id $_id",
  DELETE_PACKAGE: "delete /package/$_id $_id",

  GET_CUSTOMER_ISSUES: "get /customer-issue",
  GET_CUSTOMER_ISSUE: "get /customer-issue/$_id $_id",
  CREATE_CUSTOMER_ISSUE: "post /customer-issue",
  UPDATE_CUSTOMER_ISSUE: "put /customer-issue/$_id $_id",
  DELETE_CUSTOMER_ISSUE: "delete /customer-issue/$_id $_id",
  RESOLVE_CUSTOMER_ISSUE: "post /customer-issue/resolve",

  GET_SUGGESTED_LOCATION: "post /check-in/suggested",

  GET_TASK_TIMELINE: "post /task/timeline",

  GET_BANKS: "get /dataset/bank",
  GET_BRANCHES: "get /dataset/branch",

  CREATE_PAYMENT_SESSION: "post /stripe/create-checkout-session",

  // Reports
  GET_MONTHLY_SALES: "post /report/monthly-sales",
  GET_MONTHLY_PURCHASES: "post /report/monthly-purchases",
  GET_JOURNAL_REPORT: "post /report/journal-report",
  GET_GENERAL_LEDGER: "post /report/general-ledger",
  GET_CUSTOMER_BALANCES: "post /report/customer-balances",
  GET_SUPPLIER_BALANCES: "post /report/supplier-balances",
  GET_AGING_SUMMARY: "post /report/aging-summary",
  GET_AGING_DETAILS: "post /report/aging-details",
  GET_INVOICE_DETAILS: "post /report/invoice-details",
  GET_SALES_RETURN_DETAILS: "post /report/sales-return-details",
  GET_PAYMENTS_RECEIVED: "post /report/payments-received",
  GET_GRN_DETAILS: "post /report/grn-details",
  GET_PAYMENTS_MADE: "post /report/payments-made",
  GET_SALES_BY_CUSTOMER: "post /report/sales-by-customer",
  GET_SALES_BY_PRODUCT: "post /report/sales-by-product",
  GET_INVENTORY_SUMMARY: "post /report/inventory-summary",
  GET_INVENTORY_VALUATION_SUMMARY: "post /report/inventory-valuation-summary",
  GET_ACTIVITY_LOG: "post /report/activity-log",
  GET_PROFIT_AND_LOSS: "post /report/profit-and-loss",
  GET_BALANCE_SHEET: "post /report/balance-sheet",
  GET_MONTHLY_PAYMENTS: "post /report/monthly-payments",
  GET_CUSTOMER_PROFILE: "post /report/customer-profile",
  GET_CUSTOMER_PROFILE_V2: "post /report/customer-profile-v2",
  GET_CUSTOMER_HISTORY: "post /report/customer-history",
  GET_CUSTOMER_TIME_TO_PAY: "post /report/customer-time-to-pay",
  GET_CUSTOMER_LAST_VISIT: "post /report/customer-last-visit",
  GET_BIN_CARD: "post /report/bin-card",
  GET_CHEQUE_RETURNS: "post /report/cheque-returns",
  GET_PRODUCT_ANALYSIS: "post /report/product-analysis",
  GET_OPERATOR_REPORT: "post /report/operator-report",
  GET_SALES_DETAILS: "post /report/sales-details",
  GET_USAGE_REPORT: "post /report/usage",
  GET_EXPENSES_REPORT: "post /report/expenses",
};

// GET_COUNTRIES: "get /rest/$version/alpha/$code $code $version",
// CREATE_COUNTRY: "post /countries",

// function gql(a) {
//   const args = a[0];
//   const lines = args.split("\n").filter((e) => e.trim().length > 0);
// }

// const d = gql`
// get
// /api/v1/books/$id $id
// Successfully fetched book
// Error fetching book
// `;

// const resources = ["boy", "girl"];

// const endpoints = {};

// resources.forEach((resource) => {
//   const name = resource.toUpperCase();

//   endpoints[`GET_${name}`] = `get /${resource}`;
//   endpoints[`CREATE_${name}`] = `post /${resource}`;
//   endpoints[`UPDATE_${name}`] = `put /${resource}/$_id $_id`;
//   endpoints[`DELETE_${name}`] = `delete /${resource}/$_id $_id`;
// });

export const API2 = {
  ...getEndpoints(),

  GET_CUSTOMERS: {
    method: "GET",
    url: "/customer",
    responseMapper: (res) => res,
  },
};

function getEndpoints() {
  const entities = ["customer", "supplier", "product"];
  const result = {};
  entities.forEach((entity) => {
    result[`GET_${entity.toUpperCase()}S`] = {
      method: "GET",
      url: `/${entity}`,
    };
    result[`CREATE_${entity.toUpperCase()}`] = {
      method: "POST",
      url: `/${entity}`,
    };
    result[`UPDATE_${entity.toUpperCase()}`] = {
      method: "PUT",
      url: `/${entity}`,
    };
    result[`DELETE_${entity.toUpperCase()}`] = {
      method: "DELETE",
      url: `/${entity}`,
    };
  });
  return result;
}
