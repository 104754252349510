import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

function Columns({ columns }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onChange = (e) => {};

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={onOpen}>
        <Icon>visibility</Icon>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div>
          {columns?.map((column, index) => (
            <div className={classes.row}>
              <Checkbox onChange={onChange} />
              <Typography variant="body2">{column?.label}</Typography>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
}

export default React.memo(Columns);
