import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Input } from "../../../components/Formik";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 800,
  },
  label: {
    marginLeft: 16,
    width: 400,
  },
  sample: {
    width: 400,
    marginBottom: 8,
  },
}));

export default function SchemaItems({ name, label, values }) {
  const classes = useStyles();

  const getSample = () => {
    const {
      prefix = "",
      suffix = "",
      digits = 0,
      next = 0,
    } = values?.[name] ?? {};
    const formattedNo = prefix + String(next).padStart(digits, "0") + suffix;
    return formattedNo;
  };

  return (
    <div className={classes.row}>
      <div className={classes.label}>
        <Typography variant="subtitle2">{label}</Typography>
      </div>

      <Input name={`${name}.prefix`} label="Prefix" width={200} right={8} />

      <Input name={`${name}.suffix`} label="Suffix" width={200} right={8} />

      <Input
        name={`${name}.digits`}
        label="digits"
        type="number"
        width={200}
        right={8}
      />

      <Input
        name={`${name}.next`}
        label="Next"
        type="number"
        width={200}
        right={8}
      />

      <div className={classes.sample}>
        <Typography variant="caption">{getSample()}</Typography>
      </div>
    </div>
  );
}
