import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Filter from "./Filter";
import Columns from "./Columns";
import Tabs from "./Tabs";
import { debounce } from "lodash";
import Title from "./Title";
import Search from "./Search";
import Actions from "./Actions";
import FilterArray from "./FilterArray";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    height: 72,
  },
}));

function PageControls({
  icon,
  title,
  onSearch,
  actions = [],
  tabs,
  onTabChange,
  columns,
  showColumnFilter,
  filters,
  onFiltersChange,
}) {
  const classes = useStyles();

  const firstTime = React.useRef(true);

  const [values, setValues] = React.useState(() => {
    return (
      filters?.reduce((a, b) => {
        a[b.name] = undefined;
        return a;
      }, {}) ?? {}
    );
  });

  const debounced = debounce((e) => onSearch?.(e), 50);

  React.useEffect(() => {
    const keys = Object.keys(values)?.filter((t) => !!values[t]);

    const result = [];

    for (let key of keys) {
      result.push(`${key}:${values[key]}`);
    }

    if (!firstTime.current) {
      const filterStr = result.join(",");
      onFiltersChange?.(filterStr);
    }

    firstTime.current = false;

    // eslint-disable-next-line
  }, [values]);

  const _setValues = (_values) => {
    setValues(_values);
  };

  const _onDelete = (item) => {
    setValues((prev) => ({ ...prev, [item]: undefined }));
  };

  return (
    <>
      {Boolean(tabs) && <Tabs tabs={tabs} onTabChange={onTabChange} />}

      {Boolean(title) && (
        <div className={classes.toolbar}>
          <Title icon={icon} title={title} />

          <Search onChange={debounced} />

          {filters && (
            <Filter filters={filters} values={values} setValues={_setValues} />
          )}

          {showColumnFilter && <Columns columns={columns} />}

          <Actions actions={actions} />
        </div>
      )}

      <FilterArray values={values} onDelete={_onDelete} filters={filters} />
    </>
  );
}

export default React.memo(PageControls);
