export function getAmount(item, priceKey = "cost") {
  if (!item) return 0;

  const price = item?.[priceKey] ?? 0;
  const quantity = item?.quantity ?? 0;
  const total = price * quantity;
  const discountType = item?.discountType ?? "percent";
  const discountValue = item?.discountValue ?? 0;
  const percentDiscount = total * (discountValue / 100);
  const valueDiscount = discountValue * quantity;
  const discount = discountType === "value" ? valueDiscount : percentDiscount;
  return total - discount;
}

export function getItemDiscount(item, priceKey) {
  if (!item) return 0;

  const price = item[priceKey] ?? 0;
  const quantity = item?.quantity ?? 0;
  const total = price * quantity;
  const discountType = item?.discountType ?? "percent";
  const discountValue = item?.discountValue ?? 0;
  const percentDiscount = total * (discountValue / 100);
  const valueDiscount = discountValue * quantity;
  const discount = discountType === "value" ? valueDiscount : percentDiscount;
  return discount;
}

export function getSubTotal(items, priceKey = "cost") {
  if (!items) return 0;

  return items?.reduce((a, b) => a + getAmount(b, priceKey), 0);
}

export function getDiscount(order, priceKey = "cost") {
  if (!order) return 0;

  const discountType = order?.discountType ?? "percent";
  const discountValue = order?.discountValue ?? 0;
  const subTotal = getSubTotal(order.items, priceKey);
  const percentDiscount = subTotal * (discountValue / 100);
  const valueDiscount = discountValue;
  const discount = discountType === "value" ? valueDiscount : percentDiscount;
  return discount;
}

export function getNetTotal(order, priceKey = "cost") {
  if (!order) return 0;

  return getSubTotal(order?.items, priceKey) - getDiscount(order, priceKey);
}
