import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, useQuery, API } from "../../../api";
import * as dateFns from "date-fns";
import AutoComplete from "../components/Auto";

export default function BinCard() {
  const [getReport, { data, loading }] = useLazyQuery(API.GET_BIN_CARD, {
    fetchPolicy: "network-only",
  });
  const { data: products } = useQuery(API.GET_PRODUCTS);

  const productOptions = React.useMemo(
    () =>
      products?.result?.map((t) => ({
        title: t?.code,
        subtitle: t?.description,
        value: t?._id,
      })) ?? [],
    [products]
  );

  const [filters, setFilters] = React.useState({
    product: "",
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const onProductChange = (e) => {
    setFilters({ ...filters, product: e?.value });
  };

  const columns = [
    {
      name: "date",
      label: "Date",
      options: { date: true, align: "center" },
    },
    {
      name: "number",
      label: "Number",
      options: { align: "center" },
    },
    { name: "type", label: "Type" },
    {
      name: "quantity",
      label: "Quantity",
      options: { align: "center" },
    },
    {
      name: "cost",
      label: "cost",
      options: { align: "right", money: true },
    },
    {
      name: "amount",
      label: "Amount",
      options: { align: "right", money: true },
    },
  ];

  return (
    <Report
      title="Bin Card"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={data ?? []}
      columns={columns}
    >
      <AutoComplete
        label="Select Product"
        options={productOptions}
        value={filters.product}
        onChangeComplete={onProductChange}
        width={300}
      />

      <Table data={data ?? []} columns={columns} isLoading={loading} />
    </Report>
  );
}
