import React from "react";
import { useQuery, API } from "../api";
import convert from "./convert-units";

// handles unit conversions

export default function useRecipeHook() {
  const { data: _products } = useQuery(API.GET_PRODUCTS);
  const { data: _recipes } = useQuery(API.GET_RECIPES);

  const products = React.useMemo(() => _products?.result ?? [], [_products]);
  const recipes = React.useMemo(() => _recipes?.result ?? [], [_recipes]);

  function getRecipeCost(recipe) {
    const found = recipes.find((t) => t._id === recipe);
    const items = found?.items ?? [];
    const otherExpenses = found?.otherExpenses ?? 0;
    const itemsCost =
      items?.reduce((a, b) => a + getItemCost(b) * (b?.quantity ?? 0), 0) ?? 0;
    return itemsCost + otherExpenses;
  }

  function getItemCost(item) {
    const found = products?.find((t) => t._id === item?.product);
    return found?.cost ?? 0;
  }

  function getItemUnit(item) {
    const found = products.find((t) => t._id === item?.product);
    return found?.unit ?? "";
  }

  function getAmount(item) {
    const cost = getItemCost(item);
    const toUnit = getItemUnit(item);
    const quantity = item?.quantity ?? 0;
    const fromUnit = item?.unit;
    const rate = convert(quantity).from(fromUnit).to(toUnit);
    return rate * cost;
  }

  function getCostofItems(recipe) {
    const items = recipe?.items ?? [];
    return items?.reduce((a, b) => a + getAmount(b), 0) ?? 0;
  }

  function getItemsInRecipe(recipe, mul = 1) {
    const found = recipes.find((t) => t._id === recipe);
    const items = found?.items ?? [];

    const result = [];

    items.forEach((item) => {
      const quantity = +item?.quantity * mul;
      const product = item?.product;
      const toUnit = getItemUnit(item);
      const fromUnit = item?.unit;
      const rate = convert(quantity).from(fromUnit).to(toUnit);
      result.push({ product, quantity: rate });
    });

    return result;
  }

  return {
    getItemCost,
    getAmount,
    getCostofItems,
    getRecipeCost,
    getItemsInRecipe,
  };
}
