import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../../components/VTable";
import Form from "./Form";
import Button from "@material-ui/core/Button";
import { useFetcher, useLazyQuery, API } from "../../api";
import { useAlert } from "../../providers/Alert";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    margin: theme.spacing(1, 0, 1, 2),
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

export default function Roles() {
  const { data, loading, hasMore, loadMore } = useFetcher(API.GET_ROLES, {
    params: { sort: "createdAt" },
  });
  const [deleteRole] = useLazyQuery(API.DELETE_ROLE, {
    refetchQueries: [{ query: API.GET_ROLES }],
    onCompleted: () =>
      enqueueSnackbar("Successfully Deleted Role", { variant: "success" }),
    onError: (err) => enqueueSnackbar(err?.message, { variant: "error" }),
  });

  const classes = useStyles();

  const form = useRef(null);
  const alert = useAlert();
  const { enqueueSnackbar } = useSnackbar();

  const onAdd = () => form.current.open({ name: "" });

  const onEdit = (e) => form.current.edit(e);

  const onDelete = async ({ _id }) => {
    try {
      const confirmed = await alert({
        title: "Are you sure?",
        message:
          "Are you sure you want to delete these role? This operation cannot be undone.",
        variant: "warning",
      });
      if (confirmed) {
        deleteRole({ params: { _id } });
      }
    } catch (e) {}
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        <Button variant="outlined" color="primary" onClick={onAdd}>
          Add Role
        </Button>
      </div>

      <div className={classes.tableContainer}>
        <Table
          isLoading={loading}
          hasMore={hasMore}
          loadMore={loadMore}
          data={data}
          columns={[{ name: "name", label: "Role Name" }]}
          actions={[
            { icon: "edit", onClick: onEdit },
            { icon: "delete", onClick: onDelete },
          ]}
        />
      </div>

      <Form ref={form} maxWidth="lg" />
    </>
  );
}
