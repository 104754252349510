import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Input, AutoComplete } from ".";
import { formatMoney } from "../../utils/formatters";
import { getAmount } from "../../utils/orderUtils";
import _ from "lodash";
import { usePrefs } from "../../providers/Prefs";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "180px auto 140px 60px 140px 140px 140px 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
    minHeight: 40,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
  selection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      background: "#EFEFEF",
      cursor: "pointer",
    },
  },
}));

export default function OrderItems({
  push,
  remove,
  form,
  products = [],
  priceKey,
  priceList = [],
}) {
  const classes = useStyles();

  const [discountPolicy, setDiscountPolicy] = React.useState("percent");

  React.useEffect(() => {
    const len = form.values?.items?.length ?? 0;
    for (let index = 0; index < len; index++) {
      form.setFieldValue(`items[${index}].discountType`, discountPolicy);
    }
    // eslint-disable-next-line
  }, [discountPolicy]);

  React.useEffect(() => {
    if (!form?.values?.customer) return;

    const len = form.values?.items?.length ?? 0;
    for (let index = 0; index < len; index++) {
      const product = form?.values?.items?.[index]?.product;
      const found = products?.find((t) => t._id === product);

      let salesPrice = found?.salesPrice;

      const pl = priceList?.find((t) => t?.product === product);
      if (pl?.customPrice) salesPrice = pl?.customPrice ?? 0;

      form.setFieldValue(`items[${index}].cost`, found?.cost);
      form.setFieldValue(`items[${index}].salesPrice`, salesPrice);
    }
    // eslint-disable-next-line
  }, [form?.values?.customer, products, priceKey]);

  const getSalesPrice = (productId) => {
    const found = priceList?.find((t) => t?.product === productId);
    if (found?.customPrice) {
      return found?.customPrice ?? 0;
    } else {
      const product = products?.find((t) => t?._id === productId);
      return _.get(product, priceKey) ?? 0;
    }
  };

  const onProductSelected = (product, index) => {
    const salesPrice = getSalesPrice(product?._id);
    form.setFieldValue(`items[${index}].cost`, product?.cost);
    form.setFieldValue(`items[${index}].salesPrice`, salesPrice);
    form.setFieldValue(`items[${index}].unit`, product?.unit);
    form.setFieldValue(`items[${index}].required`, true);
    form.setFieldValue(`items[${index}].discountType`, discountPolicy);
    form.setFieldValue(`items[${index}].discountValue`, 0);
  };

  const getValue = (item, key) => {
    const product = products.find((t) => t._id === item.product);
    return _.get(product, key) ?? "";
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button" className={classes.center}>
          Product Code
        </Typography>
        <Typography variant="button">Description</Typography>
        <Typography variant="button" className={classes.right}>
          Price
        </Typography>
        <Typography variant="button" className={classes.center}>
          Unit
        </Typography>
        <Typography variant="button" className={classes.center}>
          Qty
        </Typography>
        <DiscountPicker onChange={setDiscountPolicy} />
        <Typography variant="button" className={classes.right}>
          Amount
        </Typography>
      </div>

      <Divider />

      {form.values?.items?.map((item, index) => (
        <React.Fragment key={index}>
          <div className={classes.container}>
            <AutoComplete
              name={`items[${index}].product`}
              options={products}
              onChangeComplete={(e) => onProductSelected(e, index)}
              bottom={0}
            />
            <Typography>{getValue(item, "description")}</Typography>

            <Typography className={classes.right}>
              {formatMoney(getSalesPrice(item?.product))}
            </Typography>
            <Typography className={classes.center}>
              {getValue(item, "unit")}
            </Typography>

            <Input
              name={`items[${index}].quantity`}
              type="number"
              bottom={0}
              inputProps={{ style: { textAlign: "center" } }}
            />

            <Input
              name={`items[${index}].discountValue`}
              type="number"
              bottom={0}
              inputProps={{ style: { textAlign: "center" } }}
            />

            <Typography className={classes.right}>
              {formatMoney(getAmount(item, priceKey))}
            </Typography>

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => remove(index)}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </div>

          <Divider />
        </React.Fragment>
      ))}

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() =>
            push({
              quantity: 0,
              discountType: discountPolicy,
              discountValue: 0,
            })
          }
          startIcon={<AddIcon />}
        >
          Add Lines
        </Button>
      </div>
    </div>
  );
}

function DiscountPicker({ onChange }) {
  const classes = useStyles();
  const prefs = usePrefs();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedPolicy, setSelectedPolicy] = React.useState("percent");

  const label = selectedPolicy === "value" ? "Disc" : "Disc (%)";

  React.useEffect(() => {
    if (prefs) setSelectedPolicy(prefs?.discountPolicy);
  }, [prefs]);

  React.useEffect(() => {
    if (selectedPolicy) onChange?.(selectedPolicy);
    // eslint-disable-next-line
  }, [selectedPolicy]);

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSelected = (value) => {
    setSelectedPolicy(value);
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.selection} onClick={onOpen}>
        <Typography variant="button">{label}</Typography>
        <Icon>arrow_drop_down</Icon>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <List dense disablePadding>
          <ListItem button onClick={() => onSelected("percent")}>
            <ListItemText>Percentage</ListItemText>
          </ListItem>
          <Divider />
          <ListItem button onClick={() => onSelected("value")}>
            <ListItemText>Value</ListItemText>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
