import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API } from "../../../api";
import * as dateFns from "date-fns";

export default function ChequeReturns() {
  const [getReport, { data, loading }] = useLazyQuery(API.GET_CHEQUE_RETURNS, {
    fetchPolicy: "network-only",
  });

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const columns = [
    {
      name: "date",
      label: "Payment Date",
      options: { align: "center", date: true },
    },
    {
      name: "paymentNo",
      label: "Payment #",
      options: { align: "center" },
    },
    {
      name: "customer.name",
      label: "Customer Name",
      options: { align: "left", width: 250 },
    },
    {
      name: "cheque.chequeNo",
      label: "Cheque No",
      options: { align: "center" },
    },
    {
      name: "cheque.bank",
      label: "Bank",
      options: { align: "center" },
    },
    {
      name: "cheque.branch",
      label: "Branch",
      options: { align: "center" },
    },
    {
      name: "cheque.chequeDate",
      label: "Cheque Date",
      options: { align: "center", date: true },
    },
    {
      name: "cheque.depositedDate",
      label: "Deposited Date",
      options: { align: "center", date: true },
    },
    {
      name: "cheque.returnedDate",
      label: "Returned Date",
      options: { align: "center", date: true },
    },
    {
      name: "cheque.amount",
      label: "Amount",
      options: { align: "right", money: true, showTotal: true },
    },
  ];

  return (
    <Report
      title="Cheque Returns"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={data}
      columns={columns}
    >
      <Table data={data} columns={columns} showTotals isLoading={loading} />
    </Report>
  );
}
