import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import {
  formatMoney,
  formatDate,
  formatDateTime,
  formatMonth,
  toSentenceCase,
} from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
  },
  column: {
    flex: "1 1 auto",
    padding: "0 8px",
  },
}));

export default function InnerRow({ index, style, data, columns = [] }) {
  const classes = useStyles();

  const row = data?.[index];

  const getValue = (column) => {
    const { name, options } = column;

    const value = _.get(row, name) ?? "";

    if (options?.getValue) return options?.getValue?.(value, row);
    if (options?.sentenceCase) return toSentenceCase(value);
    if (options?.date) return formatDate(value);
    if (options?.datetime) return formatDateTime(value);
    if (options?.month) return formatMonth(value);
    if (options?.money) return formatMoney(value);

    return value;
  };

  const getAlign = (options = {}) => {
    const { align, money } = options;
    if (money) return "right";
    return align ?? "left";
  };

  return (
    <div style={style}>
      <div className={classes.row}>
        {columns?.map((column, index) => (
          <div
            key={index}
            className={classes.column}
            style={{
              width: column?.options?.width ?? 100,
              textAlign: getAlign(column?.options),
            }}
          >
            <Typography variant="body2" noWrap component="div">
              {getValue(column)}
            </Typography>
          </div>
        ))}
      </div>

      <Divider />
    </div>
  );
}
