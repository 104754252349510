import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export const SALES = [
  { label: "Sales Order", value: "/sales/sales-orders" },
  { label: "Invoice", value: "/sales/invoices" },
  { label: "Payment", value: "/sales/payments" },
];

export const PURCHASES = [
  { label: "Purchases Order", value: "/purchases/purchases-orders" },
  { label: "GRN", value: "/purchases/grns" },
  { label: "Payment", value: "/purchases/payments" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    color: theme.palette.primary.main,
  },
  content: {
    minWidth: 400,
    padding: theme.spacing(1),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
  },
}));

export default function QuickAdd() {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onItemClick = (path) => {
    history.push(path, { open: true, _id: undefined });
    onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.root}>
      <Tooltip title="Quick create">
        <IconButton
          size="small"
          className={classes.iconButton}
          onClick={onOpen}
        >
          <Icon>add_circle</Icon>
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <div className={classes.content}>
          <Grid container className={classes.content}>
            <Grid item md={6} sm={12} xs={12}>
              <Block
                title="Sales"
                icon="attach_money"
                list={SALES}
                onClick={onItemClick}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Block
                title="Purchases"
                icon="shop"
                list={PURCHASES}
                onClick={onItemClick}
              />
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
}

function Block({ title, icon, list = [], onClick }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.row}>
        <Icon className={classes.icon}>{icon}</Icon>
        <Typography variant="button">{title}</Typography>
      </div>

      <List dense>
        {list.map((t, i) => (
          <ListItem key={i} button onClick={() => onClick?.(t.value)}>
            <ListItemText primary={t.label} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
