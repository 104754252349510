import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import { useField } from "formik";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  sortableItem: {
    zIndex: theme.zIndex.modal + 100,
    background: "#fff",
  },
  dragIcon: {
    "&:hover": {
      cursor: "grab",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  number: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1.5),
  },
  name: {
    flex: 1,
  },
  chip: {
    background: "#EE4123",
    color: "#fff",
    borderRadius: 100,
  },
}));

const DragHandle = sortableHandle(() => {
  const classes = useStyles();

  return <DragIndicatorIcon className={classes.dragIcon} />;
});

const SortableContainer = sortableContainer(({ children }) => (
  <div>{children}</div>
));

const SortableItem = sortableElement(({ value, idx, customers }) => {
  const classes = useStyles();

  const name = customers?.[value]?.name ?? "";
  const index = idx + 1;
  const hasLocation = customers?.[value]?.location ?? false;

  return (
    <div className={classes.sortableItem}>
      <div className={classes.row}>
        <DragHandle />
        <Typography className={classes.number}>{index}</Typography>
        <Typography noWrap className={classes.name}>
          {name}
        </Typography>
        {!hasLocation && (
          <Tooltip title="Set Location">
            <IconButton size="small">
              <LocationOffIcon fontSize="small" className={classes.chip} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Divider />
    </div>
  );
});

export default function CustomerItems({ customers = [], onChange, ...props }) {
  const [{ value }, meta, { setValue }] = useField(props);

  const _map = customers.reduce((a, b) => {
    if (!a[b._id]) a[b._id] = b;
    return a;
  }, {});

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newArr = arrayMove(value, oldIndex, newIndex);
    setValue(newArr);
    onChange?.(newArr);
  };

  const hasError = meta.touched && meta.error;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div style={{ overflow: "auto", height: height, width: width }}>
          <Typography>{hasError}</Typography>

          <SortableContainer useDragHandle onSortEnd={onSortEnd}>
            {value?.map((value, index) => (
              <SortableItem
                key={index}
                index={index}
                value={value}
                idx={index}
                customers={_map}
              />
            ))}
          </SortableContainer>
        </div>
      )}
    </AutoSizer>
  );
}
