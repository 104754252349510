import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Report from "../components/Report";
import { formatMoney } from "../../../utils/formatters";
import * as dateFns from "date-fns";
import { useLazyQuery, API } from "../../../api";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
  },
  header: {
    borderTop: "1px solid #e4e4e4",
    borderBottom: "1px solid #e4e4e4",
    padding: theme.spacing(0.5, 0, 0.5, 0),
    color: "#999",
    marginBottom: theme.spacing(2),
  },
  col: {
    flex: 1,
    textAlign: "right",
  },
  bold: {
    fontWeight: "bold",
  },
}));

export default function ProfitAndLoss() {
  const [getReport, { data }] = useLazyQuery(API.GET_PROFIT_AND_LOSS, {
    fetchPolicy: "network-only",
  });

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
  });

  const classes = useStyles();

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const getBalance = (name) => {
    const found = data?.find((t) => t.account === name);
    const type = found?.type;
    let balance = found?.balance ?? 0;
    if (type === "INCOME") {
      balance *= -1;
    }
    return balance;
  };

  const getSubAccounts = (subType) => {
    return data?.filter((t) => t.subType === subType) ?? [];
  };

  const getSubAccountsTotal = (subType) => {
    const subAccounts = getSubAccounts(subType);
    return subAccounts.reduce((a, b) => a + getBalance(b.account), 0);
  };

  const sales = getBalance("Sales");
  const costOfSales = getBalance("Cost Of Sales");
  const grossProfit = sales - costOfSales;
  const otherIncome = getSubAccountsTotal("OTHER_INCOME");
  const administrativeExpenses = getSubAccountsTotal("ADMINISTRATIVE_EXPENSE");
  const salesAndDistributionExpenses = getSubAccountsTotal(
    "SALES_AND_DISTRIBUTION_EXPENSE"
  );
  const financialExpenses = getSubAccountsTotal("FINANCIAL_EXPENSE");
  const totalNonOperatingExpenses =
    administrativeExpenses + salesAndDistributionExpenses + financialExpenses;
  const netProfit = grossProfit + otherIncome - totalNonOperatingExpenses;
  const depreciation = 0;
  const cashProfit = netProfit + depreciation;

  // Accounts
  const otherIncomeAccounts = getSubAccounts("OTHER_INCOME");
  const administrativeExpenseAccounts = getSubAccounts(
    "ADMINISTRATIVE_EXPENSE"
  );
  const salesAndDistributionExpenseAccounts = getSubAccounts(
    "SALES_AND_DISTRIBUTION_EXPENSE"
  );
  const financialExpenseAccounts = getSubAccounts("FINANCIAL_EXPENSE");

  return (
    <Report
      title="Profit And Loss"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
    >
      <Container maxWidth="lg">
        <Paper className={classes.content} elevation={0}>
          <Header />
          <Item label="Sales" col3={sales} bold />
          <Item label="(-) Cost of Sales" col3={costOfSales} bracket />
          <br />
          <Item label="Gross Profit" col3={grossProfit} bold />
          <br />
          <Item label="(+) Other Income" />
          {otherIncomeAccounts?.map((t, i) => (
            <Item key={i} label={t.account} col3={getBalance(t.account)} />
          ))}
          <br />
          <Item label="(-) Administrative Expenses" bold />
          {administrativeExpenseAccounts?.map((t, i) => (
            <Item
              key={i}
              label={t.account}
              col1={getBalance(t.account)}
              col2={
                i === administrativeExpenseAccounts.length - 1
                  ? administrativeExpenses
                  : undefined
              }
              bracket
            />
          ))}
          <br />
          <Item label="(-) Sales and Distribution Expenses" bold />
          {salesAndDistributionExpenseAccounts?.map((t, i) => (
            <Item
              key={i}
              label={t.account}
              col1={getBalance(t.account)}
              col2={
                i === salesAndDistributionExpenseAccounts.length - 1
                  ? salesAndDistributionExpenses
                  : undefined
              }
              bracket
            />
          ))}
          <br />
          <Item label="(-) Financial Expenses" bold />
          {financialExpenseAccounts?.map((t, i) => (
            <Item
              key={i}
              label={t.account}
              col1={getBalance(t.account)}
              col2={
                i === financialExpenseAccounts.length - 1
                  ? financialExpenses
                  : undefined
              }
              bracket
            />
          ))}
          <br />
          <Item
            label="Total Non Operating Expenses"
            col3={totalNonOperatingExpenses}
            bracket
            bold
          />
          <br />
          <Item label="Net Profit" col3={netProfit} bold />
          <br />
          <Item label="(+) Depreciation" col3={depreciation} />
          <br />
          <Item label="Cash Profit" col3={cashProfit} bold />
        </Paper>
      </Container>
    </Report>
  );
}

function Header() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.row, classes.header)}>
      <Typography className={classes.text1}>Description</Typography>
    </div>
  );
}

function Item({ label, col1, col2, col3, bracket, bold }) {
  const classes = useStyles();

  const getText = (c) => {
    if (c === undefined || c === null) return "";
    const value = formatMoney(c);
    return bracket ? `(${value})` : value;
  };

  const getTextStyle = () => {
    if (bold) {
      return classes.bold;
    }
  };

  return (
    <div className={classes.row}>
      <div style={{ flex: 1 }}>
        <Typography className={getTextStyle()}>{label}</Typography>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className={classes.col}>
          <Typography className={getTextStyle()}>{getText(col1)}</Typography>
        </div>
        <div className={classes.col}>
          <Typography className={getTextStyle()}>{getText(col2)}</Typography>
        </div>
        <div className={classes.col}>
          <Typography className={getTextStyle()}>{getText(col3)}</Typography>
        </div>
      </div>
    </div>
  );
}
