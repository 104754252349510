import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import Toolbar from "./Toolbar";
import Header from "./Header";
import Row from "./Row";
import Empty from "./Empty";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 16,
    background: "#fff",
    borderRadius: 10,
    overflow: "hidden",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

export default function VTable({
  isLoading,
  hasMore,
  loadMore,
  data = [],
  columns = [],
  actions = [],
  batchActions = [],
  headerComponent,
  rowHeight = 42,
  emptyMessage,
  emptyAction,
}) {
  const classes = useStyles();

  const itemCount = hasMore ? data.length + 20 : data.length;

  const isItemLoaded = (index) => !hasMore || index < data.length;

  const selectable = batchActions?.length > 0;

  const [selected, setSelected] = React.useState([]);

  const onSelect = (rowId) => {
    const selectedIndex = selected.indexOf(rowId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const onSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.filter((n) => !n.system).map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Paper className={classes.root} elevation={0}>
      {headerComponent}

      <Toolbar
        numSelected={selected.length}
        selected={selected}
        batchActions={batchActions}
      />

      <Header
        columns={columns}
        actions={actions}
        selectable={selectable}
        onSelectAll={onSelectAll}
        numSelected={selected.length}
        rowCount={data?.length ?? 0}
      />

      {itemCount === 0 ? (
        <Empty emptyMessage={emptyMessage} emptyAction={emptyAction} />
      ) : (
        <div className={classes.table}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMore}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    ref={ref}
                    onItemsRendered={onItemsRendered}
                    height={height}
                    itemCount={itemCount}
                    itemSize={rowHeight}
                    width={width}
                  >
                    {(props) => (
                      <Row
                        {...props}
                        data={data}
                        columns={columns}
                        actions={actions}
                        selectable={selectable}
                        onSelect={onSelect}
                        isSelected={isSelected}
                        isItemLoaded={isItemLoaded}
                      />
                    )}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      )}
    </Paper>
  );
}
