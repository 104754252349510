import React from "react";
import AlertDialog from "./AlertDialog";

const AlertContext = React.createContext(Promise.reject);

export const useAlert = () => React.useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alertState, setAlertState] = React.useState(null);

  const awaitingPromisRef = React.useRef();

  const openAlert = (options) => {
    setAlertState(options);
    return new Promise((resolve, reject) => {
      awaitingPromisRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromisRef.current) {
      awaitingPromisRef.current.reject(false);
    }

    setAlertState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromisRef.current) {
      awaitingPromisRef.current.resolve(true);
    }

    setAlertState(null);
  };

  return (
    <>
      <AlertContext.Provider value={openAlert} children={children} />

      <AlertDialog
        open={Boolean(alertState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...alertState}
      />
    </>
  );
};
