import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    height: 200,
    overflow: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export default function ListBox({ title, options = {} }) {
  const classes = useStyles();

  const _onChange = (e) => {
    // const checked = e.target.checked;
    // const value = e.target.value;
  };

  return (
    <div className={classes.root}>
      <span>{title}</span>

      <div className={classes.content}>
        {Object.keys(options).map((key, i) => {
          const _values = options[key];
          return (
            <div className={classes.row}>
              <span key={i}>{_values.label}</span>
              <input
                type="checkbox"
                value={_values.value}
                onChange={_onChange}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
