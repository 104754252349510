import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Maintenance() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>Coming Soon!</Typography>
    </div>
  );
}
