import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import { Input, AutoComplete } from ".";
import _ from "lodash";
import { formatMoney, formatNumber } from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "200px auto 60px 140px 140px 140px 140px 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
}));

export default function StockItems({
  push,
  remove,
  form,
  products = [],
  warehouse,
}) {
  const classes = useStyles();

  const isDefined = (e) => {
    return e !== undefined && e !== null;
  };

  const getValue = (item, key) => {
    const product = products.find((t) => t._id === item?.product);
    return _.get(product, key);
  };

  const onProductSelected = (product, index) => {
    const systemStock = _.get(product, `inStock.${warehouse}.default`);
    form.setFieldValue(`items[${index}].unit`, product?.unit);
    form.setFieldValue(`items[${index}].cost`, product?.cost);
    form.setFieldValue(`items[${index}].systemStock`, systemStock);
    form.setFieldValue(`items[${index}].physicalStock`, undefined);
    form.setFieldValue(`items[${index}].change`, undefined);
  };

  const getChange = (item) => {
    const systemStock = item?.systemStock;
    const physicalStock = item?.physicalStock;
    if (isDefined(physicalStock) && isDefined(systemStock)) {
      return physicalStock - systemStock;
    }
    return 0;
  };

  const getAmount = (item) => {
    const change = getChange(item);
    const cost = item?.cost ?? 0;
    return change * cost;
  };

  const onPhysicalStockChange = (e, index) => {
    const item = form?.values?.items?.[index];
    const systemStock = item?.systemStock ?? 0;
    const change = Number(e) - systemStock;
    form.setFieldValue(`items[${index}].change`, change);
  };

  const onDifferenceChange = (e, index) => {
    const item = form?.values?.items?.[index];
    const systemStock = item?.systemStock ?? 0;
    const physicalStock = Number(e) + systemStock;
    form.setFieldValue(`items[${index}].physicalStock`, physicalStock);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button" className={classes.center}>
          Product Code
        </Typography>
        <Typography variant="button">Description</Typography>
        <Typography variant="button" className={classes.center}>
          Unit
        </Typography>
        <Typography variant="button" className={classes.center}>
          System Stock
        </Typography>
        <Typography variant="button">Physical Stock</Typography>
        <Typography variant="button" className={classes.center}>
          Change
        </Typography>
        <Typography variant="button" className={classes.right}>
          Amount
        </Typography>
      </div>

      <Divider />

      {form.values?.items?.map((item, index) => (
        <React.Fragment key={index}>
          <div key={index} className={classes.container}>
            <AutoComplete
              name={`items[${index}].product`}
              options={products}
              onChangeComplete={(e) => onProductSelected(e, index)}
              bottom={0}
            />

            <Typography>{getValue(item, "description")}</Typography>

            <Typography className={classes.center}>
              {getValue(item, "unit")}
            </Typography>

            <Typography className={classes.center}>
              {formatNumber(getValue(item, `inStock.${warehouse}.default`))}
            </Typography>

            <Input
              name={`items[${index}].physicalStock`}
              type="number"
              onChangeText={(e) => onPhysicalStockChange(e, index)}
              bottom={0}
            />

            <Input
              name={`items[${index}].change`}
              type="number"
              onChangeText={(e) => onDifferenceChange(e, index)}
              bottom={0}
            />

            <Typography className={classes.right}>
              {formatMoney(getAmount(item))}
            </Typography>

            <IconButton color="inherit" onClick={() => remove(index)}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </div>

          <Divider />
        </React.Fragment>
      ))}
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() => push({ product: undefined })}
          startIcon={<AddIcon />}
        >
          Add Lines
        </Button>
      </div>
    </div>
  );
}
