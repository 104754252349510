import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Input, AutoComplete } from ".";
import _ from "lodash";
import { formatMoney } from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "180px auto 120px 120px 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
    minHeight: 40,
    background: "#fff",
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
}));

export default function PriceListItems({ form, push, remove, products = [] }) {
  const classes = useStyles();

  const items = form.values?.items ?? [];

  const getValue = (item, key) => {
    const product = products.find((t) => t._id === item?.product);
    return _.get(product, key);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button" className={classes.center}>
          Product Code
        </Typography>
        <Typography variant="button">Description</Typography>
        <Typography variant="button" className={classes.right}>
          Default Price
        </Typography>
        <Typography variant="button" className={classes.center}>
          Custom Price
        </Typography>
      </div>

      <Divider />

      {items?.map((item, index) => (
        <React.Fragment key={index}>
          <div className={classes.container}>
            <AutoComplete
              name={`items[${index}].product`}
              options={products}
              bottom={0}
            />
            <Typography>{getValue(item, "description")}</Typography>
            <Typography className={classes.right}>
              {formatMoney(getValue(item, "salesPrice"))}
            </Typography>
            <Input
              name={`items[${index}].customPrice`}
              type="number"
              bottom={0}
            />
          </div>

          <Divider />
        </React.Fragment>
      ))}

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() => push({ product: undefined, customPrice: 0 })}
          startIcon={<AddIcon />}
        >
          Add Lines
        </Button>
      </div>
    </div>
  );
}
