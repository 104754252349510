import React from "react";
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import { LABEL_OPTIONS, COLUMN_OPTIONS } from "./constants";
import _ from "lodash";
import font1 from "../../assets/fonts/receipt.ttf";
import font2 from "../../assets/fonts/bank.ttf";
import font3 from "../../assets/fonts/merchant.ttf";
import font4 from "../../assets/fonts/calibri.ttf";
import font5 from "../../assets/fonts/times-new-roman.ttf";

Font.register({ family: "Receipt", src: font1 });
Font.register({ family: "Bank", src: font2 });
Font.register({ family: "Merchant", src: font3 });
Font.register({ family: "Calibri", src: font4 });
Font.register({ family: "TimesNewRoman", src: font5 });

const styles = StyleSheet.create({
  root: {
    position: "relative",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

export default function InvoicePrint({ config, invoice, isPreview }) {
  const DEFAULT_PAGE_SIZE = "A4";
  const FONT_SIZE = config?.fontSize ?? 10;
  const fontFamily = config?.font ?? "Receipt";

  const pageSize = config?.pageSize ?? DEFAULT_PAGE_SIZE;

  const labels = config?.labels ?? [];
  const container = config?.container;
  const columns = config?.columns ?? [];

  const items = isPreview ? [{}] : invoice?.items ?? [{}];

  const getLabelValue = (item) => {
    if (item?.isCustom) return item?.customText;

    const found = LABEL_OPTIONS.find((t) => t.value === item.name);
    if (isPreview) return found?.label ?? "";

    const name = item?.name;
    let value = _.get(invoice, name) ?? "";
    if (found?.map) value = found?.map(value, invoice);

    if (item?.isMultiplied) {
      const multiplier = item?.multiplier ?? 1;
      value = value * multiplier;
    }

    if (found?.format) value = found?.format(value, invoice);

    return value;
  };

  const getColumnValue = (item, index1) => {
    if (item?.isCustom) return item?.customText;

    const found = COLUMN_OPTIONS.find((t) => t.value === item.name);
    if (isPreview) return found?.label ?? "";

    const name = item?.name;
    const _item = invoice?.items?.[index1];
    let value = _.get(_item, name) ?? "";
    if (found?.map) value = found?.map(value, _item, invoice);
    if (found?.format) value = found?.format(value, _item, invoice);
    return value;
  };

  const _getCustomDiscountLabel = () => {
    const discountValue = invoice?.discountValue ?? 0;
    if (discountValue <= 0) return "";

    let disc = `${discountValue}%`;
    let subTotal = getLabelValue({ isCustom: false, name: "subTotal" });
    return `Less Discount ${disc} on ${subTotal}`;
  };

  const _getCustomDiscount = () => {
    const discountValue = invoice?.discountValue ?? 0;
    if (discountValue <= 0) return "";

    return `(${getLabelValue({ isCustom: false, name: "discount" })})`;
  };

  return (
    <Document>
      <Page size={pageSize}>
        <View style={styles.root}>
          {labels?.map((t, i) => (
            <View
              key={i}
              style={{
                position: "absolute",
                top: `${t.top}mm`,
                left: `${t.left}mm`,
                width: `${t.width}mm`,
                fontSize: t.fontSize ?? FONT_SIZE,
                fontFamily,
                border: isPreview && "1px solid black",
                borderRadius: isPreview && 2,
              }}
            >
              <Text style={{ textAlign: `${t.align}` }}>
                {getLabelValue(t)}
              </Text>
            </View>
          ))}
        </View>

        <View
          style={{
            position: "absolute",
            top: `${container?.top}mm`,
            left: `${container?.left}mm`,
            width: `${container?.width}mm`,
            height: `${container?.height}mm`,
            fontSize: FONT_SIZE,
            fontFamily,
            border: isPreview && "1px solid black",
            borderRadius: isPreview && 2,
          }}
        >
          {items.map((item, index1) => (
            <View key={index1} style={styles.row}>
              {columns.map((col, index2) => (
                <Text
                  key={index2}
                  wrap={false}
                  style={{
                    width: `${col.width}mm`,
                    textAlign: `${col.align}`,
                    padding: "0mm 2mm",
                    marginBottom: `${0}mm`,
                    border: isPreview && "1px solid black",
                    borderRadius: isPreview && 2,
                    // whiteSpace: "nowrap",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                  }}
                >
                  {getColumnValue(col, index1)}
                </Text>
              ))}
            </View>
          ))}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingLeft: 6,
              paddingRight: 5.75,
            }}
          >
            <Text style={{ marginTop: 4, paddingTop: 6 }}>Sub Total</Text>
            <Text
              style={{
                borderTopWidth: 1,
                marginTop: 4,
                paddingTop: 6,
              }}
            >
              {getLabelValue({ isCustom: false, name: "subTotal" })}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingLeft: 6,
              paddingRight: 2,
            }}
          >
            <Text style={{ marginTop: 4 }}>{_getCustomDiscountLabel()}</Text>
            <Text style={{ marginTop: 4 }}>{_getCustomDiscount()}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
