import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 36,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  column: {
    flex: "1 1 auto",
    padding: "0 8px",
  },
}));

export default function Header({ columns = [] }) {
  const classes = useStyles();

  const getAlign = (options = {}) => {
    const { align, money } = options;
    if (money) return "right";
    return align ?? "left";
  };

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {columns.map((column, index) => (
          <div
            key={index}
            className={classes.column}
            style={{
              width: column?.options?.width ?? 100,
              textAlign: getAlign(column?.options),
            }}
          >
            <Typography noWrap variant="subtitle2">
              {column?.label}
            </Typography>
          </div>
        ))}
      </div>
      <Divider />
    </div>
  );
}
