import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { formatDate } from "../../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 2),
  },
}));

export default function ReportHeader({ title, startDate, endDate }) {
  const classes = useStyles();

  const getDateString = () => {
    if (!startDate) return `As of ${formatDate(endDate ?? new Date())}`;

    return `From ${formatDate(startDate)} to ${formatDate(endDate)}`;
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{getDateString()}</Typography>
    </div>
  );
}
