import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { formatMoney } from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: "right",
  },
}));

export default function BalanceDue({ contact }) {
  const classes = useStyles();

  const balance = contact?.balance ?? 0;
  const credit = contact?.credit ?? 0;

  return (
    <div className={classes.root}>
      <Typography variant="caption">BALANCE DUE</Typography>
      <Typography variant="h4">Rs {formatMoney(balance)}</Typography>
      {credit > 0 && (
        <Typography variant="body1">
          +Rs {formatMoney(credit)} Credit
        </Typography>
      )}
    </div>
  );
}
