import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import { useAuth } from "../../providers/Auth";

const getUrlParameter = (name) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "linear-gradient(147deg, #000000 0%, #2c3e50 100%)",
  },
  paper: {
    padding: theme.spacing(4),
    background: "#fff",
  },
  content: {
    width: "360px",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Verify() {
  const classes = useStyles();

  const history = useHistory();
  const auth = useAuth();

  const onLogin = () => {
    history.push("/login");
  };

  React.useEffect(() => {
    const isRoot = getUrlParameter("isRoot");
    const accessToken = getUrlParameter("accessToken");
    auth.signIn(accessToken, isRoot);
  }, [auth]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <Typography className={classes.title} variant="h6">
            Successfully verified your account!
          </Typography>

          <Typography className={classes.message} variant="body2">
            Please{" "}
            <Link color="inherit" onClick={onLogin}>
              login
            </Link>{" "}
            to your account
          </Typography>
        </div>
      </Paper>
    </div>
  );
}
