import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import { AutoComplete, Select, Input, DatePicker } from "../Formik";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  formContent: {
    padding: theme.spacing(2),
    width: 400,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    margin: theme.spacing(0, 0.5),
  },
}));

function Filter({ filters, values, setValues }) {
  const [open, setOpen] = React.useState(false);

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  const onSubmit = (_values) => {
    setValues?.(_values);
    onClose();
  };

  const onClear = () => {
    setValues(
      filters?.reduce((a, b) => {
        a[b.name] = null;
        return a;
      }, {}) ?? {}
    );
  };

  return (
    <>
      <Tooltip title="Filter">
        <IconButton size="medium" color="primary" onClick={onOpen}>
          <Icon>tune_rounded</Icon>
        </IconButton>
      </Tooltip>

      <Drawer anchor="right" open={open} onClose={onClose}>
        <MyForm
          filters={filters}
          initialValues={values}
          onSubmit={onSubmit}
          onClear={onClear}
        />
      </Drawer>
    </>
  );
}

export default React.memo(Filter);

function MyForm({ filters = [], initialValues, onSubmit, onClear }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form>
          <div className={classes.formContent}>
            <Typography variant="h5" className={classes.title}>
              Filters
            </Typography>

            {filters?.map((filter, index) => (
              <React.Fragment key={index}>
                {filter.type === "text" && (
                  <Input name={filter.name} label={filter.label} />
                )}

                {filter.type === "number" && (
                  <Input
                    name={filter.name}
                    label={filter.label}
                    type="number"
                  />
                )}

                {filter.type === "date" && (
                  <DatePicker name={filter.name} label={filter.label} />
                )}

                {filter.type === "autoComplete" && (
                  <AutoComplete
                    name={filter.name}
                    label={filter.label}
                    options={filter?.options}
                  />
                )}

                {filter.type === "select" && (
                  <Select
                    name={filter.name}
                    label={filter.label}
                    options={filter?.options}
                  />
                )}
              </React.Fragment>
            ))}

            <div className={classes.row}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                onClick={onClear}
              >
                Clear
              </Button>
              <div className={classes.spacer} />
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
