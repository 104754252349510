import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API } from "../../../api";

export default function SupplierBalances() {
  const [getReport, { data, loading }] = useLazyQuery(
    API.GET_SUPPLIER_BALANCES,
    {
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
    getReport();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: "code",
      label: "Supplier Code",
      options: { align: "center" },
    },
    {
      name: "name",
      label: "Supplier Name",
      options: { align: "left", width: 600 },
    },
    {
      name: "balance",
      label: "Outstanding Balance",
      options: { align: "right", money: true, showTotal: true },
    },
  ];

  return (
    <Report title="Supplier Balances" data={data} columns={columns}>
      <Table data={data} columns={columns} showTotals isLoading={loading} />
    </Report>
  );
}
