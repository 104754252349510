import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, useQuery, API } from "../../../api";

export default function CustomerBalances() {
  const [getReport, { data, loading }] = useLazyQuery(
    API.GET_CUSTOMER_BALANCES,
    { fetchPolicy: "network-only" }
  );
  const { data: routes } = useQuery(API.GET_ROUTES);

  const routeOptions = React.useMemo(
    () => routes?.result?.map((t) => ({ title: t?.name, value: t?._id })),
    [routes]
  );

  const [filters, setFilters] = React.useState({ route: "" });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const columns = [
    {
      name: "code",
      label: "Customer Code",
      options: { align: "center" },
    },
    {
      name: "name",
      label: "Customer Name",
      options: { align: "left", width: 600 },
    },
    {
      name: "balance",
      label: "Outstanding Balance",
      options: { align: "right", money: true, showTotal: true },
    },
  ];

  return (
    <Report
      title="Customer Balances"
      data={data}
      columns={columns}
      filters={[
        {
          type: "autocomplete",
          name: "route",
          label: "Route",
          options: routeOptions,
          value: filters?.route,
        },
      ]}
      onFiltersChange={(e) => setFilters((x) => ({ ...x, ...e }))}
    >
      <Table data={data} columns={columns} showTotals isLoading={loading} />
    </Report>
  );
}
