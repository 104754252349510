import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API } from "../../../api";

export default function CustomerLastVisit() {
  const [getReport, { data, loading }] = useLazyQuery(
    API.GET_CUSTOMER_LAST_VISIT,
    { fetchPolicy: "network-only" }
  );

  React.useEffect(() => {
    getReport();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: "code",
      label: "Customer Code",
      options: { align: "center" },
    },
    {
      name: "name",
      label: "Customer Name",
      options: { align: "left", width: 400 },
    },
    {
      name: "visitedAgo",
      label: "Visited Ago (Days)",
      options: { align: "center" },
    },
    {
      name: "lastVisited",
      label: "Last Visited On",
      options: { align: "center", date: true },
    },
    {
      name: "invoicedAgo",
      label: "Invoiced Ago (Days)",
      options: { align: "center" },
    },
    {
      name: "lastInvoiced",
      label: "Last Invoiced On",
      options: { align: "center", date: true },
    },
  ];

  return (
    <Report title="Customer Last Visit" data={data} columns={columns}>
      <Table data={data} columns={columns} showTotals isLoading={loading} />
    </Report>
  );
}
