import React from "react";
import decode from "jwt-decode";
import { api } from "../../api/request";

const AuthContext = React.createContext(null);

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [accessToken, setAccessToken] = React.useState(null);
  const [isRootUser, setIsRootUser] = React.useState(null);

  React.useEffect(() => {
    const onMount = async () => {
      const config = JSON.parse(localStorage.getItem("config"));

      try {
        await api("/user/auth");

        // TODO*** validate the access token properly
        const { exp } = decode(config?.accessToken);
        if (exp < Date.now() / 1000) {
          // the token has expired
          localStorage.clear();
          setIsLoading(false);
          return;
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          // unauthorized
          localStorage.clear();
        }

        setIsLoading(false);
        return;
      }

      setAccessToken(config?.accessToken);
      setIsRootUser(config?.isRootUser);

      setIsLoading(false);
    };
    onMount();
  }, []);

  const signIn = (accessToken, isRootUser) => {
    const config = { accessToken, isRootUser };
    localStorage.setItem("config", JSON.stringify(config));
    setAccessToken(config.accessToken);
    setIsRootUser(config.isRootUser);

    // TODO
    window.location.href = "/";
  };

  const signOut = () => {
    setAccessToken(null);
    setIsRootUser(null);
    localStorage.clear();
  };

  const value = React.useMemo(
    () => ({
      isLoading,
      accessToken,
      isRootUser,

      signIn,
      signOut,
    }),
    [isLoading, accessToken, isRootUser]
  );

  return <AuthContext.Provider value={value} children={children} />;
};
