import { ExportToCsv } from "export-to-csv";
import { formatDateTime, toParamCase, toCapitalCase } from "./formatters";

export const exportToCSV = ({ data = [], filename = "report" }) => {
  if (data.length === 0) return;

  const _date = formatDateTime(new Date(), "yyyy-MM-dd-HH-mm-ss");
  const _filename = toParamCase(`${filename}-${_date}`);

  const headers = Object.keys(data?.[0]).map((t) => toCapitalCase(t));

  const options = {
    filename: _filename,
    showLabels: true,
    headers,
    // useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(data);
};
