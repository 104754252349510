import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const tabHeight = 36;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0, 2),
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight,
  },
}));

function MyTabs({ tabs = [], onTabChange }) {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, index) => {
    setValue(index);
    onTabChange?.(tabs?.[index]?.value);
  };

  return (
    <Paper square className={classes.paper}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
        classes={{ root: classes.tabsRoot }}
      >
        {tabs?.map((tab, index) => (
          <Tab
            key={index}
            label={tab?.label}
            classes={{ root: classes.tabRoot }}
          />
        ))}
      </Tabs>
    </Paper>
  );
}

export default React.memo(MyTabs);
