import React from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "./Popper";

function Select({
  label,
  options = [],
  onChangeComplete,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = undefined,
  ...props
}) {
  const [{ value, onChange }, meta] = useField(props);

  const ref = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (meta.touched && meta.error) {
      setAnchorEl(ref.current);
    } else {
      setAnchorEl(null);
    }
  }, [meta]);

  const _onChange = (...e) => {
    onChange(...e);
    onChangeComplete?.(e?.[0]?.target?.value);
  };

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <TextField
        ref={ref}
        select
        fullWidth
        label={label}
        variant="outlined"
        size="small"
        value={value ?? ""}
        onChange={_onChange}
        {...props}
        error={(meta.touched && meta.error && true) || false}
        // helperText={meta.touched && meta.error}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option?.value}>
            {option?.label}
          </MenuItem>
        ))}
      </TextField>
      <Popper anchorEl={anchorEl} message={meta.error} />
    </div>
  );
}

export default Select;

Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
};
