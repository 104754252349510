import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useAuth } from "./providers/Auth";
import { usePrefs } from "./providers/Prefs";
import { Splash, Fallback } from "./components/Fallback";
import Console from "./layouts/Console";

import Login from "./auth/Login";
import Register from "./auth/Register";
import Forgot from "./auth/Forgot";
import Reset from "./auth/Reset";
import Setup from "./auth/Setup";
import Validate from "./auth/Validate";
import { Terms, Privacy } from "./auth/Legal";

import Reports, {
  JournalReport,
  GeneralLedger,
  CustomerBalances,
  SupplierBalances,
  AgingSummary,
  AgingDetails,
  InvoiceDetails,
  GRNDetails,
  PaymentsReceived,
  PaymentsMade,
  SalesByCustomer,
  SalesByProduct,
  ProductSalesReport,
  ProfitAndLoss,
  ActivityLog,
  CustomerProfile,
  InventorySummary,
  InventoryValuationSummary,
  CustomerTimeToPay,
  CustomerLastVisit,
  OperatorReport,
  BinCard,
  CustomerHistory,
  ChequeReturns,
  ProductAnalysis,
  SalesReturnDetails,
  SalesDetails,
  BalanceSheet,
  CashFlowStatement,
  ExpensesReport,
} from "./pages/Reports";

const Dashboard = lazy(() => import("./pages/Dashboard"));

const FinishedGoods = lazy(() => import("./pages/Inventory/FinishedGoods"));
const WorkInProcess = lazy(() => import("./pages/Inventory/WorkInProcess"));
const RawMaterials = lazy(() => import("./pages/Inventory/RawMaterials"));
const MROInventory = lazy(() => import("./pages/Inventory/MROInventory"));
const PriceLists = lazy(() => import("./pages/Inventory/PriceLists"));
const Transfers = lazy(() => import("./pages/Inventory/Transfers"));
const InventoryAdjustments = lazy(() =>
  import("./pages/Inventory/Adjustments")
);

const Customers = lazy(() => import("./pages/Contacts/Customers"));
const CustomerMap = lazy(() => import("./pages/Contacts/CustomerMap"));
const Suppliers = lazy(() => import("./pages/Contacts/Suppliers"));
const AdjustCustomerBalance = lazy(() =>
  import("./pages/Contacts/AdjustCustomerBalance")
);
const AdjustSupplierBalance = lazy(() =>
  import("./pages/Contacts/AdjustSupplierBalance")
);

const SalesOrders = lazy(() => import("./pages/Sales/SalesOrders"));
const Invoices = lazy(() => import("./pages/Sales/Invoices"));
const Payments = lazy(() => import("./pages/Sales/Payments"));
const Cheques = lazy(() => import("./pages/Sales/Cheques"));
const SalesReturns = lazy(() => import("./pages/Sales/SalesReturns"));
const PurchasesOrders = lazy(() => import("./pages/Purchases/PurchasesOrders"));
const GRNs = lazy(() => import("./pages/Purchases/GRNs"));
const GRNPayments = lazy(() => import("./pages/Purchases/GRNPayments"));
const PurchasesReturns = lazy(() =>
  import("./pages/Purchases/PurchasesReturns")
);

const Accounts = lazy(() => import("./pages/Accounting/Accounts"));
const ManualJournals = lazy(() => import("./pages/Accounting/ManualJournals"));
const SalesTeam = lazy(() => import("./pages/Manage/SalesTeam"));
const Warehouses = lazy(() => import("./pages/Manage/Warehouses"));
const Routes = lazy(() => import("./pages/Manage/Routes"));
const Alarms = lazy(() => import("./pages/Manage/Alarms"));
const Recipes = lazy(() => import("./pages/Manufacturing/Recipes"));
const Workflows = lazy(() => import("./pages/Manufacturing/Workflows"));
const Vehicles = lazy(() => import("./pages/Fleet/Vehicles"));
const Tracking = lazy(() => import("./pages/Fleet/Tracking"));
const SalesOnTrust = lazy(() => import("./pages/Operations/SalesOnTrust"));
const Schedule = lazy(() => import("./pages/Operations/Schedule"));
const Packages = lazy(() => import("./pages/Operations/Packages"));
const TaskMonitor = lazy(() => import("./pages/Operations/TaskMonitor"));
const Expenses = lazy(() => import("./pages/Operations/Expenses"));
const MRO = lazy(() => import("./pages/Operations/MRO"));
const ControlPanel = lazy(() => import("./pages/Operations/ControlPanel"));
const CustomerIssues = lazy(() => import("./pages/Operations/CustomerIssues"));

const AuthRoute = ({ component: Component, isRoot, ...rest }) => {
  const { accessToken, isRootUser } = useAuth();
  const { hasPermission } = usePrefs();
  const hasAccess = isRoot ? isRootUser && accessToken : accessToken;

  const arr = rest?.path?.slice(1)?.split("/") ?? [];
  const _module = arr?.shift();
  const _subModule = arr?.pop();
  const _hasPermission = hasPermission(_module, _subModule, "read");

  const canAccess = hasAccess && _hasPermission;

  return (
    <Console>
      <Suspense fallback={<Fallback />}>
        <Route
          {...rest}
          render={(props) =>
            canAccess ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      </Suspense>
    </Console>
  );
};

export default function App() {
  const auth = useAuth();

  if (auth.isLoading) return <Splash />;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot-password" component={Forgot} />
        <Route exact path="/reset-password" component={Reset} />
        <Route exact path="/setup-account" component={Setup} />
        <Route exact path="/validate" component={Validate} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />

        <AuthRoute exact path="/" component={Dashboard} />
        <AuthRoute
          exact
          path="/inventory/finished-goods"
          component={FinishedGoods}
        />
        <AuthRoute
          exact
          path="/inventory/work-in-process"
          component={WorkInProcess}
        />
        <AuthRoute
          exact
          path="/inventory/raw-materials"
          component={RawMaterials}
        />
        <AuthRoute
          exact
          path="/inventory/mro-inventory"
          component={MROInventory}
        />
        <AuthRoute exact path="/inventory/price-lists" component={PriceLists} />
        <AuthRoute exact path="/inventory/transfers" component={Transfers} />
        <AuthRoute
          exact
          path="/inventory/adjustments"
          component={InventoryAdjustments}
        />
        <AuthRoute exact path="/contacts/customers" component={Customers} />
        <AuthRoute
          exact
          path="/contacts/customers/map"
          component={CustomerMap}
        />
        <AuthRoute
          exact
          path="/contacts/customers/adjust-balance"
          component={AdjustCustomerBalance}
        />
        <AuthRoute exact path="/contacts/suppliers" component={Suppliers} />
        <AuthRoute
          exact
          path="/contacts/suppliers/adjust-balance"
          component={AdjustSupplierBalance}
        />
        <AuthRoute exact path="/sales/sales-orders" component={SalesOrders} />
        <AuthRoute exact path="/sales/invoices" component={Invoices} />
        <AuthRoute exact path="/sales/payments" component={Payments} />
        <AuthRoute exact path="/sales/payments/cheques" component={Cheques} />
        <AuthRoute exact path="/sales/sales-returns" component={SalesReturns} />
        <AuthRoute
          exact
          path="/purchases/purchases-orders"
          component={PurchasesOrders}
        />
        <AuthRoute exact path="/purchases/grns" component={GRNs} />
        <AuthRoute exact path="/purchases/payments" component={GRNPayments} />
        <AuthRoute
          exact
          path="/purchases/purchases-returns"
          component={PurchasesReturns}
        />
        <AuthRoute exact path="/accounting/accounts" component={Accounts} />
        <AuthRoute
          exact
          path="/accounting/manual-journals"
          component={ManualJournals}
        />
        <AuthRoute exact path="/manage/warehouses" component={Warehouses} />
        <AuthRoute exact path="/manage/routes" component={Routes} />
        <AuthRoute exact path="/manage/sales-team" component={SalesTeam} />
        <AuthRoute exact path="/manage/alarms" component={Alarms} />
        <AuthRoute exact path="/reports" component={Reports} />
        <AuthRoute exact path="/fleet/vehicles" component={Vehicles} />
        <AuthRoute exact path="/fleet/tracking" component={Tracking} />
        <AuthRoute exact path="/manufacturing/recipes" component={Recipes} />
        <AuthRoute
          exact
          path="/manufacturing/workflows"
          component={Workflows}
        />
        <AuthRoute exact path="/operations/schedule" component={Schedule} />
        <AuthRoute exact path="/operations/packages" component={Packages} />
        <AuthRoute
          exact
          path="/operations/task-monitor"
          component={TaskMonitor}
        />
        <AuthRoute exact path="/operations/expenses" component={Expenses} />
        <AuthRoute exact path="/operations/mro" component={MRO} />
        <AuthRoute
          exact
          path="/operations/sales-on-trust"
          component={SalesOnTrust}
        />
        <AuthRoute
          exact
          path="/operations/control-panel"
          component={ControlPanel}
        />
        <AuthRoute
          exact
          path="/operations/customer-issues"
          component={CustomerIssues}
        />

        {/* Reports */}
        <AuthRoute
          exact
          path="/reports/journal-report"
          component={JournalReport}
        />
        <AuthRoute
          exact
          path="/reports/general-ledger"
          component={GeneralLedger}
        />
        <AuthRoute
          exact
          path="/reports/customer-balances"
          component={CustomerBalances}
        />
        <AuthRoute
          exact
          path="/reports/supplier-balances"
          component={SupplierBalances}
        />
        <AuthRoute
          exact
          path="/reports/aging-summary"
          component={AgingSummary}
        />
        <AuthRoute
          exact
          path="/reports/aging-details"
          component={AgingDetails}
        />
        <AuthRoute
          exact
          path="/reports/invoice-details"
          component={InvoiceDetails}
        />
        <AuthRoute exact path="/reports/grn-details" component={GRNDetails} />
        <AuthRoute
          exact
          path="/reports/payments-received"
          component={PaymentsReceived}
        />
        <AuthRoute
          exact
          path="/reports/payments-made"
          component={PaymentsMade}
        />
        <AuthRoute
          exact
          path="/reports/sales-by-customer"
          component={SalesByCustomer}
        />
        <AuthRoute
          exact
          path="/reports/sales-by-product"
          component={SalesByProduct}
        />
        <AuthRoute
          exact
          path="/reports/product-sales-report"
          component={ProductSalesReport}
        />
        <AuthRoute
          exact
          path="/reports/profit-and-loss"
          component={ProfitAndLoss}
        />
        <AuthRoute exact path="/reports/activity-log" component={ActivityLog} />
        <AuthRoute
          exact
          path="/reports/customer-profile"
          component={CustomerProfile}
        />
        <AuthRoute
          exact
          path="/reports/inventory-summary"
          component={InventorySummary}
        />
        <AuthRoute
          exact
          path="/reports/inventory-valuation-summary"
          component={InventoryValuationSummary}
        />
        <AuthRoute
          exact
          path="/reports/customer-time-to-pay"
          component={CustomerTimeToPay}
        />
        <AuthRoute
          exact
          path="/reports/customer-last-visit"
          component={CustomerLastVisit}
        />
        <AuthRoute
          exact
          path="/reports/operator-report"
          component={OperatorReport}
        />
        <AuthRoute exact path="/reports/bin-card" component={BinCard} />
        <AuthRoute
          exact
          path="/reports/customer-history"
          component={CustomerHistory}
        />
        <AuthRoute
          exact
          path="/reports/cheque-returns"
          component={ChequeReturns}
        />
        <AuthRoute
          exact
          path="/reports/product-analysis"
          component={ProductAnalysis}
        />
        <AuthRoute
          exact
          path="/reports/sales-return-details"
          component={SalesReturnDetails}
        />
        <AuthRoute
          exact
          path="/reports/sales-details"
          component={SalesDetails}
        />
        <AuthRoute
          exact
          path="/reports/balance-sheet"
          component={BalanceSheet}
        />
        <AuthRoute
          exact
          path="/reports/cash-flow-statement"
          component={CashFlowStatement}
        />
        <AuthRoute
          exact
          path="/reports/expenses-report"
          component={ExpensesReport}
        />

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}
