import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import { formatMoney } from "../../utils/formatters";
import { getNetTotal } from "../../utils/orderUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: 300,
    background: "#fff",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    border: "2px solid #EE4123",
  },
}));

function OverReturn({ values, dueAmount = 0 }) {
  const classes = useStyles();

  const netTotal = getNetTotal(values, "salesPrice");
  const overReturn = Math.max(netTotal - dueAmount, 0);

  return (
    <div className={classes.root}>
      <Grow in={overReturn > 0}>
        <div className={classes.row}>
          <Typography variant="h6">Excess Return</Typography>

          <Typography variant="h6">{formatMoney(overReturn)}</Typography>
        </div>
      </Grow>
    </div>
  );
}

export default React.memo(OverReturn);
