import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ListBox from "./ListBox";
import { Container } from "./Container";
import Attributes from "./Attributes";
import { CONFIG } from "./mapping";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function Printing() {
  const classes = useStyles();

  const [config, setConfig] = React.useState({});
  const [selectedKey, setSelectedKey] = React.useState(null);

  const onMouseDown = (e) => {
    setSelectedKey(e);
  };

  const onChange = (e) => {
    setConfig(e);
  };

  const onChangeAttribute = (e) => {
    setConfig({ ...config, [selectedKey]: e });
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item md={3}>
        <ListBox title="Labels" options={CONFIG?.labels} />
        {/* <ListBox title="Items" options={config} /> */}
      </Grid>
      <Grid item md={6}>
        <DndProvider backend={HTML5Backend}>
          <Container
            config={config}
            onChange={onChange}
            onMouseDown={onMouseDown}
            selectedKey={selectedKey}
          />
        </DndProvider>
      </Grid>
      <Grid item md={3}>
        <Attributes initialValues={null} onChange={onChangeAttribute} />
      </Grid>
    </Grid>
  );
}
