import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useFormikContext } from "formik";
import { AutoComplete, Input, Select } from ".";
import convert from "../../utils/convert-units";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "180px auto 140px 140px 200px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
}));

export default function OrderItems({ products = [], warehouses = [] }) {
  const classes = useStyles();

  const { setFieldValue, values } = useFormikContext();

  const getValue = (key) => {
    const found = products?.find((t) => t._id === values?.output);
    return found?.[key];
  };

  const getDescription = () => {
    return getValue("description") ?? "";
  };

  const getPossibilities = () => {
    const unit = getValue("unit");

    if (!unit) return [];

    return convert()
      .listUnits(unit)
      .map((t) => ({
        label: `${t.abbr} (${t.singular})`,
        value: t.abbr,
      }));
  };

  const onOutputSelected = (e) => {
    const cost = e?.cost;
    setFieldValue("outputCost", cost);
  };

  const getWarehouseOptions = (item) => {
    const type = getValue("type");
    return warehouses?.filter((t) => t.type === type);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button" className={classes.center}>
          Code
        </Typography>
        <Typography variant="button">Description</Typography>
        <Typography variant="button" className={classes.center}>
          Output Qty
        </Typography>
        <Typography variant="button" className={classes.center}>
          Unit
        </Typography>
        <Typography variant="button" className={classes.center}>
          Warehouse
        </Typography>
      </div>

      <Divider />

      <div className={classes.container}>
        <AutoComplete
          name="output"
          options={products}
          onChangeComplete={onOutputSelected}
          bottom={0}
        />

        <Typography>{getDescription()}</Typography>

        <Input name="quantity" type="number" bottom={0} />

        <Select name="unit" options={getPossibilities()} bottom={0} />

        <AutoComplete
          name="warehouse"
          options={getWarehouseOptions()}
          bottom={0}
        />
      </div>
    </div>
  );
}
