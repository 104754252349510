import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Input, AutoComplete, Select } from ".";
import convert from "../../utils/convert-units";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "200px auto 140px 140px 200px 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
}));

export default function WorkflowItems({
  push,
  remove,
  form,
  recipes = [],
  outputProducts = [],
  warehouses = [],
}) {
  const classes = useStyles();

  const items = form.values?.items ?? [];

  React.useEffect(() => {
    if (form?.values?.items?.length === 0) return;

    const allRawMaterials = [];

    form?.values?.items?.forEach((item) => {
      const recipe = recipes?.find((t) => t._id === item?.recipe);

      const requiredOutputQuantity = item?.quantity ?? 0;
      const requiredOutputUnit = item?.unit;

      const recipeOutputQuantity = recipe?.quantity;
      const recipeOutputUnit = recipe?.unit;

      const rate =
        convert(requiredOutputQuantity)
          .from(requiredOutputUnit)
          .to(recipeOutputUnit) / recipeOutputQuantity;

      recipe?.items?.forEach((item) => {
        allRawMaterials.push({ ...item, quantity: item.quantity * rate });
      });
    });

    const reducedRawMaterials = Object.values(
      allRawMaterials.reduce((a, b) => {
        if (!a[b.product]) {
          a[b.product] = b;
        } else {
          a[b.product].quantity += b.quantity;
        }
        return a;
      }, {})
    );

    form.setFieldValue("inputs", reducedRawMaterials);
    // eslint-disable-next-line
  }, [form?.values?.items, recipes]);

  const getOutputProductValue = (item, key) => {
    const found = outputProducts?.find((t) => t._id === item?.product);
    return _.get(found, key) ?? "";
  };

  const onProductSelected = (product, index) => {
    form.setFieldValue(`items[${index}].recipe`, product?.recipe);
    form.setFieldValue(`items[${index}].warehouse`, product?.warehouse);
  };

  const getPossibilities = (item) => {
    const unit = getOutputProductValue(item, "unit");

    if (!unit) return [];

    return convert()
      .listUnits(unit)
      .map((t) => ({
        label: `${t.abbr} (${t.singular})`,
        value: t.abbr,
      }));
  };

  const getWarehouseOptions = (item) => {
    // TODO check product exist in warehouse
    const type = getOutputProductValue(item, "type");
    return warehouses?.filter((t) => t.type === type);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button" className={classes.center}>
          Output Product
        </Typography>
        <Typography variant="button">Description</Typography>
        <Typography variant="button" className={classes.center}>
          Output Quantity
        </Typography>
        <Typography variant="button" className={classes.center}>
          Unit
        </Typography>
        <Typography variant="button" className={classes.center}>
          Output Warehouse
        </Typography>
      </div>

      <Divider />

      {items?.map((item, index) => (
        <React.Fragment key={index}>
          <div className={classes.container}>
            <AutoComplete
              name={`items[${index}].product`}
              options={outputProducts}
              onChangeComplete={(e) => onProductSelected(e, index)}
              bottom={0}
            />

            <Typography>
              {getOutputProductValue(item, "description")}
            </Typography>

            <Input
              name={`items[${index}].quantity`}
              type="number"
              bottom={0}
              inputProps={{ style: { textAlign: "center" } }}
            />

            <Select
              name={`items[${index}].unit`}
              options={getPossibilities(item)}
              bottom={0}
            />

            <AutoComplete
              name={`items[${index}].warehouse`}
              options={getWarehouseOptions(item)}
              bottom={0}
            />

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => remove(index)}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </div>

          <Divider />
        </React.Fragment>
      ))}

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() => push({ recipe: undefined, quantity: 0 })}
          startIcon={<AddIcon />}
        >
          Add Lines
        </Button>
      </div>
    </div>
  );
}
