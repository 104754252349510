import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InnerHeader from "./InnerHeader";
import InnerRow from "./InnerRow";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
}));

export default function VCTable({ data = [], columns = [], rowHeight = 42 }) {
  const classes = useStyles();

  const itemCount = data.length;

  const listRef = React.useRef();

  return (
    <div className={classes.root}>
      <InnerHeader columns={columns} />

      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={listRef}
            height={height}
            itemCount={itemCount}
            itemSize={rowHeight}
            width={width}
          >
            {(props) => (
              <InnerRow
                {...props}
                data={data}
                columns={columns}
                rowHeight={rowHeight}
              />
            )}
          </List>
        )}
      </AutoSizer>
    </div>
  );
}
