import React from "react";
import Register from "./Register";
import Verify from "./Verify";
import Gmail from "./Gmail";

const getUrlParameter = (name) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export default function Validate() {
  switch (getUrlParameter("type")) {
    case "register":
      return <Register />;
    case "verify":
      return <Verify />;
    case "gmail":
      return <Gmail />;

    default:
      return null;
  }
}
