import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogHoc from "../../layouts/DialogHoc";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input } from "../../components/Formik";
import { Actions } from "../../components/Dialogs";
import { useLazyQuery, API } from "../../api";
import qrcode from "qrcode";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflowY: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  col1: {
    display: "flex",
    flexDirection: "column",
    width: 200,
    justifyContent: "center",
    alignItems: "center",
  },
  col2: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    margin: theme.spacing(4, 0, 1, 0),
  },
  image: {
    width: 200,
  },
}));

function Enable2FA({ onClose, initialValues, onKeyDown, snSuccess, snError }) {
  const [verify2FA, { data }] = useLazyQuery(API.VERIFY_2FA, {
    refetchQueries: [{ query: API.GET_AUTH_USER }],
    onCompleted: () => snSuccess("Successfully Enabled 2FA"),
    onError: (err) => snError(err?.message),
  });

  const classes = useStyles();

  const [imageURL, setImageURL] = React.useState(null);

  React.useEffect(() => {
    if (data) onClose();
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    if (!initialValues) return;

    qrcode.toDataURL(initialValues.otpauth_url, (error, data) => {
      setImageURL(data);
    });
  }, [initialValues]);

  const onSubmit = (values) => {
    verify2FA({ data: values });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        code: Yup.number().required("Required"),
      })}
    >
      {({ dirty, isValid }) => (
        <Form onKeyDown={onKeyDown}>
          <DialogContent className={classes.content}>
            <Typography variant="h4">
              Enable Two-Factor Authentication
            </Typography>

            <div className={classes.row}>
              <div className={classes.col1}>
                <Typography variant="body1" className={classes.text}>
                  Open Google Authenticator App and scan this QR code.
                </Typography>
                <img src={imageURL} alt="qrcode" className={classes.image} />
              </div>

              <div className={classes.col2}>
                <Input name="code" label="Enter code to verify" type="number" />
              </div>
            </div>
          </DialogContent>

          <Actions
            onClose={onClose}
            dirty={dirty}
            isValid={isValid}
            okLabel="Verify"
          />
        </Form>
      )}
    </Formik>
  );
}

export default DialogHoc(Enable2FA);
