import React from "react";
import { useField } from "formik";
import { DatePicker as KeyboardDatePicker } from "@material-ui/pickers";
import Popper from "./Popper";

function DatePicker({
  label,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = undefined,
  disableFuture,
  disablePast,
  ...props
}) {
  const [{ value }, meta, { setValue }] = useField(props);

  const ref = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (meta.touched && meta.error) {
      setAnchorEl(ref.current);
    } else {
      setAnchorEl(null);
    }
  }, [meta]);

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <KeyboardDatePicker
        innerRef={ref}
        label={label}
        variant="inline"
        inputVariant="outlined"
        placeholder="30/06/2020"
        format="dd MMM yyyy"
        size="small"
        autoOk={true}
        value={value || null}
        onChange={(d) => setValue(d)}
        error={(meta.touched && meta.error && true) || false}
        style={{ width: "100%" }}
        disableFuture={disableFuture}
        disablePast={disablePast}
      />
      <Popper anchorEl={anchorEl} message={meta.error} />
    </div>
  );
}

export default DatePicker;
