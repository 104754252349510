import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Input from "./components/Input";
import Select from "./components/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
}));

const AutoSubmit = () => {
  const { values, submitForm } = useFormikContext();
  React.useEffect(() => {
    if (values) submitForm();
  }, [values, submitForm]);
  return null;
};

export default function Attributes({ initialValues: values, onChange }) {
  const classes = useStyles();

  const onSubmit = (e) => {
    onChange?.(e);
  };

  return (
    <Formik
      initialValues={{
        title: values?.title ?? "",
        top: values?.top ?? "",
        left: values?.left ?? "",
        width: values?.width ?? "",
        align: values?.align ?? "",
      }}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        top: Yup.number(),
        left: Yup.number(),
        width: Yup.number(),
        align: Yup.string(),
      })}
    >
      <Form className={classes.root}>
        <Input name="top" label="Top" type="number" />
        <Input name="left" label="Left" type="number" />
        <Input name="width" label="Width" type="number" />
        <Select
          name="align"
          label="Align"
          options={[
            { label: "Left", value: "left" },
            { label: "Center", value: "center" },
            { label: "Right", value: "right" },
          ]}
        />
        <AutoSubmit />
      </Form>
    </Formik>
  );
}
