import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import { formatMoney } from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: 300,
    background: "#fff",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    border: "2px solid #EE4123",
  },
}));

function Overpayment({ values, contact }) {
  const classes = useStyles();

  const items = values?.items ?? [];
  const settlements = values?.settlements ?? [];
  const paidTotal = items.reduce((a, b) => a + (b?.amount ?? 0), 0);
  const dueAmount = contact?.balance ?? 0;
  const netDiscounts = settlements.reduce((a, b) => a + (b?.discount ?? 0), 0);
  const netPayable = dueAmount - netDiscounts;
  const overpayment = Math.max(paidTotal - netPayable, 0);

  return (
    <div className={classes.root}>
      <Grow in={overpayment > 0}>
        <div className={classes.row}>
          <Typography variant="h6">Overpayment</Typography>

          <Typography variant="h6">{formatMoney(overpayment)}</Typography>
        </div>
      </Grow>
    </div>
  );
}

export default React.memo(Overpayment);
