import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 2, 1, 2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));

export default function Options({ options = [], value, onChange }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {options?.map((t, i) => (
        <Chip
          key={i}
          label={t?.label}
          clickable
          onClick={() => onChange(t?.value)}
          style={{
            background: value === t?.value && "#122C44",
            color: value === t?.value && "#fff",
            marginRight: 8,
            minWidth: 100,
          }}
        />
      ))}
    </div>
  );
}
