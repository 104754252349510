import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FindInPageTwoToneIcon from "@material-ui/icons/FindInPageTwoTone";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "75%",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    fontSize: 40,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
}));

export default function Empty({
  emptyMessage = "No Results Found",
  emptyAction,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FindInPageTwoToneIcon className={classes.icon} />
      <Typography variant="body2" color="textSecondary">
        {emptyMessage}
      </Typography>

      {Boolean(emptyAction) && <Button>{emptyAction}</Button>}
    </div>
  );
}
