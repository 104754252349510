import React from "react";
import Action from "./Action";
import ActionGroup from "./ActionGroup";

export default function Actions({ actions }) {
  return (
    <>
      {actions.map((action, index) => {
        if (action?.extraOptions) {
          return <ActionGroup key={index} {...action} />;
        } else {
          return <Action key={index} {...action} />;
        }
      })}
    </>
  );
}
