import React from "react";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API } from "../../../api";
import * as dateFns from "date-fns";

export default function InventorySummary() {
  const [getReport, { data, loading }] = useLazyQuery(
    API.GET_INVENTORY_SUMMARY,
    { fetchPolicy: "network-only" }
  );

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const getData = () => {
    if (!data) return [];
    return data;
  };

  const columns = [
    {
      name: "code",
      label: "Item Code",
      options: { align: "center" },
    },
    {
      name: "description",
      label: "Description",
      options: { align: "left", width: 300 },
    },
    {
      name: "qtyOrdered",
      label: "Quantity Ordered",
      options: { align: "center" },
    },
    {
      name: "qtyIn",
      label: "Quantity In",
      options: { align: "center" },
    },
    {
      name: "qtyOut",
      label: "Quantity Out",
      options: { align: "center" },
    },
    {
      name: "stockOnHand",
      label: "Stock On Hand",
      options: { align: "center" },
    },
  ];

  return (
    <Report
      title="Inventory Summary"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={getData()}
      columns={columns}
    >
      <Table
        data={getData()}
        columns={columns}
        showTotals
        isLoading={loading}
      />
    </Report>
  );
}
