import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Chart from "../../../components/Chart";
import { formatDate } from "../../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    borderRadius: 8,
    marginBottom: theme.spacing(2),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  blur: {
    filter: "blur(2px)",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function BarChart({ data, hoverColor, isLoading }) {
  const classes = useStyles();

  const labels = data?.map((t) => t.label);
  const values = data?.map((t) => t.value);

  const _data = {
    labels,
    datasets: [
      {
        // type: "line",
        label: "Qty Sold",
        backgroundColor: "#1BBC9B",
        borderColor: "#1BBC9B",
        borderWidth: 1,
        hoverBackgroundColor: hoverColor,
        hoverBorderColor: hoverColor,
        data: values,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          suggestedMin: 0,
          autoSkipPadding: 10,
        },
      },
      x: {
        type: "time",
        time: { unit: "month", displayFormats: { day: "d" } },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            return formatDate(context?.[0]?.parsed?.x, "MMM yyyy");
          },
          label: (context) => {
            let label = context?.dataset?.label ?? "";
            if (label) {
              label += ": ";
            }
            label += context?.parsed?.y;
            return label;
          },
        },
      },
    },
  };

  return (
    <Paper
      elevation={0}
      className={clsx(classes.root, isLoading && classes.blur)}
    >
      <div className={classes.content}>
        <Chart type="bar" data={_data} options={options} />
      </div>
    </Paper>
  );
}
