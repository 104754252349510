import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Form } from "formik";
import { AutoComplete } from "../../../components/Formik";
import NumberRange from "./NumberRange";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    width: 500,
    padding: theme.spacing(1, 2),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
}));

export default function ReportCustomizer({ filters = [], onFiltersChange }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const formRef = React.useRef();

  const initialValues = filters?.reduce((a, b) => {
    if (b.type === "range") {
      a[b.name] = {
        from: "",
        to: "",
      };
    } else {
      a[b.name] = "";
    }

    return a;
  }, {});

  const onOpen = () => setOpen(true);

  const onClose = () => setOpen(false);

  const onAutoCompleteChange = (e, filter) => {
    if (!e) {
      formRef.current.setFieldValue(filter?.name, "");
    }
  };

  return (
    <>
      <Button className={classes.button} variant="outlined" onClick={onOpen}>
        <Typography variant="caption">Customize Report</Typography>
      </Button>

      <Drawer anchor="right" open={open} onClose={onClose}>
        <div className={classes.root}>
          <div className={classes.header}>
            <Typography variant="h6">Customize Report</Typography>

            <IconButton edge="start" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <div>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={onFiltersChange}
            >
              <Form>
                {filters?.map((filter, index) => (
                  <div key={index}>
                    {filter?.type === "autocomplete" && (
                      <AutoComplete
                        name={filter?.name}
                        label={filter?.label}
                        options={filter?.options}
                        onChangeComplete={(e) =>
                          onAutoCompleteChange(e, filter)
                        }
                        width={300}
                      />
                    )}

                    {filter?.type === "range" && (
                      <NumberRange
                        name={filter?.name}
                        label={filter?.label}
                        width={300}
                      />
                    )}
                  </div>
                ))}

                <Button>Reset</Button>

                <Button type="submit">Apply</Button>
              </Form>
            </Formik>
          </div>
        </div>
      </Drawer>
    </>
  );
}
