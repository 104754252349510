import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Report from "../components/Report";
import { formatMoney } from "../../../utils/formatters";
import * as dateFns from "date-fns";
import { useLazyQuery, API } from "../../../api";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
  },
  header: {
    borderTop: "1px solid #e4e4e4",
    borderBottom: "1px solid #e4e4e4",
    padding: theme.spacing(0.5, 0, 0.5, 0),
    color: "#999",
    marginBottom: theme.spacing(2),
  },
  col: {
    flex: 1,
    textAlign: "right",
  },
  bold: {
    fontWeight: "bold",
  },
}));

export default function CashFlowStatement() {
  const [getReport, { data }] = useLazyQuery(API.GET_PROFIT_AND_LOSS, {
    fetchPolicy: "network-only",
  });

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
  });

  const classes = useStyles();

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  console.log(data);

  return (
    <Report
      title="Cash Flow Statement"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
    >
      <Container maxWidth="lg">
        <Paper className={classes.content} elevation={0}>
          <Header />
          <Item label="Beginning Cash Balance" bold />
          <br />
          <br />

          <Item label="A. Cash flow from operating activities" />
          <br />
          <Item label="Net cash provided by Operating Activities" bold />
          <br />
          <br />

          <Item label="B. Cash Flow from Investing Activities" />
          <br />
          <Item label="Net cash provided by Investing Activities" bold />
          <br />
          <br />

          <Item label="C. Cash Flow from Financing Activities" />
          <br />
          <Item label="Net cash provided by Financing Activities" bold />
          <br />
          <br />

          <Item label="Ending Cash Balance" bold />
        </Paper>
      </Container>
    </Report>
  );
}

function Header() {
  const classes = useStyles();

  return (
    <div className={clsx(classes.row, classes.header)}>
      <Typography className={classes.text1}>Description</Typography>
    </div>
  );
}

function Item({ label, col1, col2, col3, bracket, bold }) {
  const classes = useStyles();

  const getText = (c) => {
    if (c === undefined || c === null) return "";
    const value = formatMoney(c);
    return bracket ? `(${value})` : value;
  };

  const getTextStyle = () => {
    if (bold) {
      return classes.bold;
    }
  };

  return (
    <div className={classes.row}>
      <div style={{ flex: 1 }}>
        <Typography className={getTextStyle()}>{label}</Typography>
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <div className={classes.col}>
          <Typography className={getTextStyle()}>{getText(col1)}</Typography>
        </div>
        <div className={classes.col}>
          <Typography className={getTextStyle()}>{getText(col2)}</Typography>
        </div>
        <div className={classes.col}>
          <Typography className={getTextStyle()}>{getText(col3)}</Typography>
        </div>
      </div>
    </div>
  );
}
