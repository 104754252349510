import React from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { formatDate, toSentenceCase } from "../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    marginTop: -20,
  },
  chip: {
    background: theme.palette.primary.main,
    color: "#fff",
  },
}));

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function FilterArray({ values, onDelete, filters }) {
  const classes = useStyles();

  const keys = Object.keys(values)?.filter((t) => !!values[t]);

  if (keys.length === 0) {
    return null;
  }

  const getValue = (key) => {
    const filter = filters?.find((t) => t.name === key);

    let value = values?.[key];

    if (filter?.type === "autoComplete") {
      const option = filter?.options?.find((t) => t.value === value);
      value = option.title;
    }

    if (value instanceof Date) {
      value = formatDate(value);
    }

    return `${toSentenceCase(key)} "${value}"`;
  };

  return (
    <div className={classes.root}>
      {keys?.map((key, index) => (
        <ListItem key={index}>
          <Chip
            label={getValue(key)}
            className={classes.chip}
            onDelete={() => onDelete?.(key)}
          />
        </ListItem>
      ))}
    </div>
  );
}
