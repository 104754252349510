import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Input, Select, AutoComplete } from ".";
import { formatMoney } from "../../utils/formatters";
import useRecipeHook from "../../utils/recipeUtils";
import convert from "../../utils/convert-units";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  items: {
    background: "#fff",
  },
  costing: {
    background: "#fff",
    marginTop: theme.spacing(1),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "180px auto 140px 140px 140px 140px 200px 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
  },
  container2: {
    display: "grid",
    gridTemplateColumns: "auto 140px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
    minHeight: 40,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
}));

export default function RecipeItems({
  push,
  remove,
  form,
  products = [],
  warehouses = [],
}) {
  const classes = useStyles();

  const { getItemCost, getAmount, getCostofItems } = useRecipeHook();

  const getValue = (item, key) => {
    const found = products.find((t) => t._id === item?.product);
    return _.get(found, key) ?? "";
  };

  const getCostPerUnit = (item) => {
    const found = products.find((t) => t?._id === item?.product);
    const unit = found?.unit ?? "";
    const cost = formatMoney(getItemCost(item));
    return `${cost}/${unit}`;
  };

  const getPossibilities = (item) => {
    const found = products.find((t) => t?._id === item?.product);
    const unit = found?.unit ?? "";

    if (!unit) return [];

    return convert()
      .listUnits(unit)
      .map((t) => ({
        label: `${t.abbr} (${t.singular})`,
        value: t.abbr,
      }));
  };

  const getItemsCost = () => {
    return getCostofItems(form?.values);
  };

  const getNetRecipeCost = () => {
    const itemsCost = getItemsCost();
    const otherExpenses = form?.values?.otherExpenses ?? 0;
    return itemsCost + otherExpenses;
  };

  const getWarehouseOptions = (item) => {
    const type = getValue(item, "type");
    return warehouses?.filter((t) => t.type === type);
  };

  return (
    <div>
      <div className={classes.items}>
        <div className={classes.container}>
          <Typography variant="button" className={classes.center}>
            Code
          </Typography>
          <Typography variant="button">Description</Typography>
          <Typography variant="button" className={classes.right}>
            Cost/Unit
          </Typography>
          <Typography variant="button" className={classes.center}>
            Input Qty
          </Typography>
          <Typography variant="button" className={classes.center}>
            Unit
          </Typography>
          <Typography variant="button" className={classes.right}>
            Amount
          </Typography>
          <Typography variant="button" className={classes.center}>
            Warehouse
          </Typography>
        </div>

        <Divider />

        {form.values?.items?.map((item, index) => (
          <React.Fragment key={index}>
            <div className={classes.container}>
              <AutoComplete
                name={`items[${index}].product`}
                options={products}
                bottom={0}
              />

              <Typography>{getValue(item, "description")}</Typography>

              <Typography className={classes.right}>
                {getCostPerUnit(item)}
              </Typography>

              <Input
                name={`items[${index}].quantity`}
                type="number"
                bottom={0}
              />

              <Select
                name={`items[${index}].unit`}
                options={getPossibilities(item)}
                bottom={0}
              />

              <Typography className={classes.right}>
                {formatMoney(getAmount(item))}
              </Typography>

              <AutoComplete
                name={`items[${index}].warehouse`}
                options={getWarehouseOptions(item)}
                bottom={0}
              />

              <IconButton
                color="inherit"
                edge="start"
                onClick={() => remove(index)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </div>

            <Divider />
          </React.Fragment>
        ))}

        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            onClick={() =>
              push({
                product: undefined,
                quantity: 0,
                unit: undefined,
                warehouse: undefined,
              })
            }
            startIcon={<AddIcon />}
          >
            Add Lines
          </Button>
        </div>
      </div>

      <div className={classes.costing}>
        <div className={classes.container2}>
          <Typography>Cost of Items</Typography>
          <Typography className={classes.right}>
            {formatMoney(getItemsCost())}
          </Typography>
        </div>

        <Divider />

        <div className={classes.container2}>
          <div>
            <Typography>Other Expenses</Typography>
            <Typography variant="caption">
              (Other expenses such as Electricity, Fuel, Transportation etc.)
            </Typography>
          </div>
          <Input name="otherExpenses" type="number" bottom={0} />
        </div>

        <Divider />

        <div className={classes.container2}>
          <Typography>Net Recipe Cost</Typography>
          <Typography className={classes.right}>
            {formatMoney(getNetRecipeCost())}
          </Typography>
        </div>
      </div>
    </div>
  );
}
