import React from "react";
import { api } from "./request";
import { useCache } from "./useCache";
import { getRequestParams } from "./utils";

type Options = {
  fetchPolicy?: "cache-first" | "network-only" | "no-cache",
  pollInterval?: number,
  onCompleted?: () => void,
  onError?: () => void,
  refetchQueries?: Array,
};

type Result = [(string) => void, { data: any, loading: boolean, error: any }];

export const useLazyQuery = (query, options: Options): Result => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [error, setError] = React.useState(null);

  const cache = useCache();

  const fetchPolicy = options?.fetchPolicy ?? "cache-first";
  const onCompleted = options?.onCompleted;
  const onError = options?.onError;
  const refetchQueries = options?.refetchQueries ?? [];

  const call = async ({ params, data: body } = {}) => {
    setData(null);
    setError(null);
    setLoading(true);

    try {
      const { type, ...rest } = getRequestParams(query, params);

      let result = null;

      if (type === "query") {
        result = await executeQuery(rest);
      } else {
        result = await executeMutation({ ...rest, data: body });
      }

      setData(result);
      onCompleted?.(result);

      handleRefetchQueries();
    } catch (err) {
      setError(err?.response?.data);
      onError?.(err?.response?.data);
    }

    setLoading(false);
  };

  const executeQuery = async ({ key, url, method }) => {
    let result = null;

    if (fetchPolicy === "cache-first") {
      result = cache.get(key);

      if (!result) {
        result = await api(url, method);
        cache.set(key, result);
      }
    } else if (fetchPolicy === "network-only") {
      result = await api(url, method);
      cache.set(key, result);
    } else if (fetchPolicy === "no-cache") {
      result = await api(url, method);
    }

    return result;
  };

  const executeMutation = async ({ url, method, data }) => {
    let result = await api(url, method, data);
    return result;
  };

  const handleRefetchQueries = () => {
    refetchQueries.forEach(async (req) => {
      const { key, url, method } = getRequestParams(req.query, []);
      const result = await api(url, method);
      cache.set(key, result);
    });
  };

  return [call, { data, loading, error }];
};
