import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 36,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  actions: {
    minWidth: 64,
    padding: "0 8px",
  },
  column: {
    flex: "1 1 auto",
    padding: "0 8px",
  },
}));

export default function Header({
  columns = [],
  actions = [],
  selectable,
  onSelectAll,
  numSelected,
  rowCount,
}) {
  const classes = useStyles();

  const getAlign = (options = {}) => {
    const { align, money } = options;
    if (money) return "right";
    return align ?? "left";
  };

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {selectable && (
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAll}
          />
        )}
        {actions.length > 0 && (
          <div className={classes.actions}>
            <Typography variant="subtitle2" noWrap>
              Actions
            </Typography>
          </div>
        )}
        {columns.map((column, index) => (
          <div
            key={index}
            className={classes.column}
            style={{
              width: column?.options?.width ?? 100,
              textAlign: getAlign(column?.options),
            }}
          >
            <Typography variant="subtitle2" noWrap>
              {column?.label}
            </Typography>
          </div>
        ))}
      </div>
      <Divider />
    </div>
  );
}
