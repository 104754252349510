import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageControls from "../../components/PageControls";
import Grid from "@material-ui/core/Grid";
import {
  ANALYTICS,
  SALES,
  BUSINESS_OVERVIEW,
  PAYABLES,
  RECEIVABLES,
  INVENTORY,
  ACTIVITY,
  ACCOUNTANT,
} from "./constants";
import Block from "./components/Block";
import { filter } from "../../utils/filters";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
}));

export default function Reports({ history }) {
  const classes = useStyles();

  const [query, setQuery] = React.useState("");

  const onNavigate = (path) => {
    history.push(`/reports${path}`);
  };

  return (
    <div>
      <PageControls icon="assessment" title="Reports" onSearch={setQuery} />

      <Grid container className={classes.content}>
        <Grid item md={4} sm={4} xs={6}>
          <Block
            title="Business Overview"
            icon="public"
            list={filter(BUSINESS_OVERVIEW, query)}
            onClick={onNavigate}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={6}>
          <Block
            title="Sales"
            icon="trending_up"
            list={filter(SALES, query)}
            onClick={onNavigate}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={6}>
          <Block
            title="Inventory"
            icon="store"
            list={filter(INVENTORY, query)}
            onClick={onNavigate}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.content}>
        <Grid item md={4} sm={4} xs={6}>
          <Block
            title="Receivables"
            icon="local_atm"
            list={filter(RECEIVABLES, query)}
            onClick={onNavigate}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={6}>
          <Block
            title="Payables"
            icon="monetization_on"
            list={filter(PAYABLES, query)}
            onClick={onNavigate}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={6}>
          <Block
            title="Analytics"
            icon="whatshot"
            list={filter(ANALYTICS, query)}
            onClick={onNavigate}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.content}>
        <Grid item md={4} sm={4} xs={6}>
          <Block
            title="Accountant"
            icon="menu_book"
            list={filter(ACCOUNTANT, query)}
            onClick={onNavigate}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={6}>
          <Block
            title="Activity"
            icon="apps"
            list={filter(ACTIVITY, query)}
            onClick={onNavigate}
          />
        </Grid>
      </Grid>
    </div>
  );
}
