import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogHoc from "../../layouts/DialogHoc";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input, AutoComplete } from "../../components/Formik";
import { Actions } from "../../components/Dialogs";
import { useQuery, useLazyQuery, API } from "../../api";
import { isUsernameExists } from "../../utils/validators";

const useStyles = makeStyles((theme) => ({
  formContent: {
    overflowY: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

function MyForm({
  onClose,
  initialValues,
  newItem,
  onKeyDown,
  snSuccess,
  snError,
}) {
  const [registerUser, { data: data1, loading }] = useLazyQuery(
    API.REGISTER_IAM,
    {
      refetchQueries: [{ query: API.GET_USERS }],
      onCompleted: () => snSuccess("Successfully created new user"),
      onError: (err) => snError(err?.message),
    }
  );
  const [updateUser, { data: data2 }] = useLazyQuery(API.UPDATE_USER, {
    refetchQueries: [{ query: API.GET_USERS }],
    onCompleted: () => snSuccess("Successfully updated user"),
    onError: (err) => snError(err?.message),
  });

  const { data: roles } = useQuery(API.GET_ROLES);
  const { data: salesReps } = useQuery(API.GET_SALES_REPS);

  const roleOptions = React.useMemo(
    () =>
      roles?.result
        ?.filter((t) => t.type !== "ADMIN")
        ?.map((t) => ({ ...t, title: t.name, value: t._id })) ?? [],
    [roles]
  );

  const salesRepOptions = React.useMemo(
    () =>
      salesReps?.result?.map((t) => ({ title: t.name, value: t._id })) ?? [],
    [salesReps]
  );

  const data = data1 || data2;

  const [selectedRole, setSelectedRole] = React.useState(null);

  React.useEffect(() => {
    if (initialValues?.role) {
      const found = roles?.result?.find((t) => t._id === initialValues?.role);
      setSelectedRole(found);
    }
  }, [initialValues?.role, roles]);

  const classes = useStyles();

  React.useEffect(() => {
    if (data) onClose?.();
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = ({ _id, system, createdAt, ...values }) => {
    if (newItem) {
      registerUser({ data: values });
    } else {
      updateUser({ params: { _id }, data: values });
    }
  };

  return (
    <Formik
      initialValues={Object.assign(
        {
          username: "",
          role: undefined,
          salesRep: undefined,
          firstName: "",
          lastName: "",
          email: "",
        },
        initialValues
      )}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnChange={true}
      validationSchema={Yup.object({
        username: Yup.string()
          .required()
          .test("username", "Username is already taken", async (username) => {
            const isExists = await isUsernameExists({ username });
            if (newItem) return !isExists;

            const currentUsername = initialValues?.username;
            return !isExists || username === currentUsername;
          }),
        role: Yup.string().required("Required"),
        salesRep:
          selectedRole?.type === "SALES_REP"
            ? Yup.string().required("Required")
            : undefined,
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        inviteEmail: Yup.string().email("Invaild email"),
      })}
    >
      {({ dirty }) => (
        <Form onKeyDown={onKeyDown}>
          <DialogTitle>
            {newItem ? "Register New User" : "Edit User Details"}
          </DialogTitle>

          <DialogContent className={classes.formContent}>
            <DialogContentText>Account details</DialogContentText>

            <Input label="Username" name="username" width={300} />

            <div className={classes.row}>
              <AutoComplete
                label="Role"
                name="role"
                options={roleOptions}
                onChangeComplete={setSelectedRole}
                width={300}
                right={16}
              />

              {selectedRole?.type === "SALES_REP" && (
                <AutoComplete
                  label="Sales Rep"
                  name="salesRep"
                  options={salesRepOptions}
                  width={300}
                />
              )}
            </div>

            <DialogContentText>Personal details</DialogContentText>

            <div className={classes.row}>
              <Input
                label="First Name"
                name="firstName"
                width={300}
                right={16}
              />
              <Input label="Last Name" name="lastName" width={300} />
            </div>

            {newItem && (
              <>
                <DialogContentText>
                  Invite user by sending an invitation link to the following
                  email address (optional)
                </DialogContentText>
                <Input
                  label="Invitation email"
                  name="inviteEmail"
                  width={300}
                />
              </>
            )}
          </DialogContent>

          <Actions
            newItem={newItem}
            onClose={onClose}
            dirty={dirty}
            isSubmitting={loading}
          />
        </Form>
      )}
    </Formik>
  );
}

export default DialogHoc(MyForm);
