import React from "react";
import Typography from "@material-ui/core/Typography";
import Report from "../components/Report";
import Table from "../../../components/TableX";
import { useLazyQuery, API, useQuery } from "../../../api";
import * as dateFns from "date-fns";

export default function InvoiceDetails() {
  const [getReport, { data, loading }] = useLazyQuery(API.GET_INVOICE_DETAILS, {
    fetchPolicy: "network-only",
  });
  const { data: salesReps } = useQuery(API.GET_SALES_REPS);

  const salesRepOptions = React.useMemo(
    () =>
      salesReps?.result?.map((t) => ({
        title: t?.name,
        value: t?._id,
      })) ?? [],
    [salesReps]
  );

  const [filters, setFilters] = React.useState({
    startDate: dateFns.startOfMonth(new Date()),
    endDate: dateFns.endOfMonth(new Date()),
    salesRep: "",
  });

  React.useEffect(() => {
    getReport({ data: filters });
    // eslint-disable-next-line
  }, [filters]);

  const onDateRangeChange = ({ startDate, endDate }) => {
    setFilters({ ...filters, startDate, endDate });
  };

  const getStatus = (row) => {
    const { netTotal, dueAmount } = row;
    let value = "Unpaid";
    if (dueAmount > 0.01 && dueAmount < netTotal) {
      value = "Partial";
    } else if (dueAmount < 0.01) {
      value = "Paid";
    }
    if (netTotal < 0.01) {
      value = "Returned";
    }
    return value;
  };

  const renderStatus = (value) => {
    let color = "#EE4123";
    if (value === "Partial") {
      color = "#FAAD14";
    } else if (value === "Paid") {
      color = "#4CAF50";
    }
    if (value === "Returned") {
      color = "#999999";
    }
    return (
      <Typography style={{ color, fontSize: 13 }} variant="button">
        {value.toUpperCase()}
      </Typography>
    );
  };

  const getData = () => {
    if (!data) return [];
    return data.map((t) => ({ ...t, status: getStatus(t) }));
  };

  const columns = [
    {
      name: "date",
      label: "Invoice Date",
      options: { align: "center", date: true },
    },
    {
      name: "salesOrderNo",
      label: "Sales Order #",
      options: { align: "center" },
    },
    {
      name: "invoiceNo",
      label: "Invoice #",
      options: { align: "center" },
    },
    {
      name: "customer.name",
      label: "Customer Name",
      options: { align: "left", width: 400 },
    },
    {
      name: "netTotal",
      label: "Total",
      options: { align: "right", money: true, showTotal: true },
    },
    {
      name: "dueAmount",
      label: "Balance",
      options: { align: "right", money: true, showTotal: true },
    },
    {
      name: "status",
      label: "Status",
      options: {
        align: "center",
        customBodyRender: (v) => renderStatus(v),
      },
    },
  ];

  return (
    <Report
      title="Invoice Details"
      useDateRange
      onDateRangeChange={onDateRangeChange}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
      data={getData()}
      columns={columns}
      filters={[
        {
          type: "range",
          name: "range",
          label: "Invoice Number Range",
          value: filters?.range,
        },
        {
          type: "autocomplete",
          name: "salesRep",
          label: "Sales Rep",
          options: salesRepOptions,
          value: filters?.salesRep,
        },
      ]}
      onFiltersChange={(e) => setFilters((x) => ({ ...x, ...e }))}
    >
      <Table
        data={getData()}
        columns={columns}
        showTotals
        isLoading={loading}
      />
    </Report>
  );
}
