// @flow
import React from "react";
import axios from "axios";
import { api2 } from "./request";
import { getRequestParams2 } from "./utils";
import Emitter from "./emitter";
import { debounce } from "lodash";

export const useFetcher = (uri, options) => {
  const initialParams = options?.params ?? {};
  const stopInitialLoad = options?.stopInitialLoad ?? false;

  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [total, setTotal] = React.useState(-1);
  const [params, setParams] = React.useState(() => initialParams);

  const isFetching = React.useRef(false);
  const source = React.useRef(false);

  const offset = data.length;
  const limit = 20;
  const hasMore = total > data.length || (!stopInitialLoad && total === -1);

  const { url, cacheKey } = getRequestParams2(uri, null, {
    ...params,
    offset,
    limit,
  });

  const _fetch = React.useCallback(async () => {
    try {
      isFetching.current = true;

      const CancelToken = axios.CancelToken;
      source.current = CancelToken.source();

      let response = await api2(url, "GET", source.current);

      let result = response?.result ?? [];
      const total = response?.total ?? 0;

      setTotal(total);
      setData((prev) => {
        if (prev.length < total) {
          return prev.concat(result);
        }
        return prev;
      });

      isFetching.current = false;
    } catch (err) {
      isFetching.current = false;
      setError(err);
    }
  }, [url]);

  const loadMore = React.useCallback(() => {
    if (!isFetching.current) _fetch();
  }, [_fetch]);

  React.useEffect(() => {
    Emitter.on("cacheUpdated", (updatedKey) => {
      if (updatedKey === cacheKey) {
        setData([]);
        setTotal(1);
      }
    });
    return () => Emitter.off("cacheUpdated");
  }, [cacheKey]);

  // initial loading
  React.useEffect(() => {
    if (total === -1 && data.length === 0 && !stopInitialLoad) {
      loadMore();
    }
  }, [total, data.length, loadMore, stopInitialLoad]);

  React.useEffect(() => {
    if (total === 1 && data.length === 0) {
      loadMore();
    }
  }, [total, data, loadMore]);

  const _changeParams = React.useCallback((_params) => {
    setParams((prev) => ({ ...prev, ..._params }));
    source.current?.cancel?.("Operation canceled by the user.");
    isFetching.current = false;
    setData([]);
    setTotal(1);
  }, []);

  const changeParams = debounce(_changeParams, 300);

  const refetch = React.useCallback(() => {
    setData([]);
    setTotal(1);
  }, []);

  return { data, error, hasMore, loadMore, changeParams, refetch };
};
