import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  title: {
    minWidth: 240,
  },
}));

export default function Title({ icon, title }) {
  const classes = useStyles();

  return (
    <>
      <Icon className={classes.icon}>{icon}</Icon>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
    </>
  );
}
