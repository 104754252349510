import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  formatDate,
  formatMoney,
  formatTime,
  formatDateTime,
} from "../../utils/formatters";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
      background: "#f1f2f3",
    },
  },
  column: {
    flex: "1 1 auto",
    padding: theme.spacing(0, 1),
  },
  skeleton: {
    height: 24,
    flex: 1,
  },
}));

export default function Row({
  style,
  index,
  isLoading,
  data = [],
  columns = [],
}) {
  const classes = useStyles();

  const row = data?.[index];

  const getValue = ({ name, options = {} }) => {
    const value = _.get(row, name) ?? "";

    if (options?.getValue) return options?.getValue?.(row);
    if (options?.money) return formatMoney(value);
    if (options?.date) return formatDate(value);
    if (options?.time) return formatTime(value);
    if (options?.datetime) return formatDateTime(value);

    if (options?.customBodyRender) return options.customBodyRender(value, row);

    return value;
  };

  const getAlign = (options = {}) => {
    const { align, money } = options;
    if (money) return "right";
    return align ?? "left";
  };

  if (isLoading) {
    return (
      <div style={style}>
        <div className={classes.row}>
          <Skeleton className={classes.skeleton} />
        </div>
        <Divider />
      </div>
    );
  }

  return (
    <div style={style}>
      <div className={classes.row}>
        {columns.map((column, index) => (
          <div
            key={index}
            className={classes.column}
            style={{
              width: column?.options?.width ?? 100,
              textAlign: getAlign(column?.options),
            }}
          >
            <Typography variant="body2" noWrap component="div">
              {getValue(column)}
            </Typography>
          </div>
        ))}
      </div>
      <Divider />
    </div>
  );
}
