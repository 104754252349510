import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(4, 2),
  },
  col1: {
    width: 400,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Section({ title, description, children }) {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2} className={classes.grid}>
        <Grid item md={6}>
          <div className={classes.col1}>
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1">{description}</Typography>
          </div>
        </Grid>
        <Grid item md={6}>
          {children}
        </Grid>
      </Grid>

      <Divider />
    </>
  );
}
