import axios from "axios";

let baseURL = "http://localhost:8080";

if (process.env.NODE_ENV === "production") {
  // TODO move this to an env variable
  baseURL = "https://app.inflaate.com/api";
}

export const api = async (url, method = "GET", data) => {
  const config = JSON.parse(localStorage.getItem("config"));

  const response = await axios({
    url,
    method,
    data,
    baseURL,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${config?.accessToken}`,
    },
  });
  return response.data;
};

export const api2 = async (url, method = "GET", source) => {
  try {
    const config = JSON.parse(localStorage.getItem("config"));

    const response = await axios({
      url,
      method,
      baseURL,
      cancelToken: source.token,
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${config?.accessToken}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};
