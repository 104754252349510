import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomCellRenderer from "./CustomCellRenderer";
import {
  formatMoney,
  formatDate,
  formatDateTime,
  formatMonth,
  toSentenceCase,
  toCapitalCase,
  formatNumber,
} from "../../utils/formatters";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    "&:hover": {
      cursor: "pointer",
      background: "#f1f2f3",
    },
  },
  actions: {
    minWidth: 64,
    textAlign: "center",
    padding: theme.spacing(0, 1),
  },
  column: {
    flex: "1 1 auto",
    padding: theme.spacing(0, 1),
  },
  skeleton: {
    height: 24,
    flex: 1,
  },
}));

export default function Row({
  index,
  style,
  data,
  columns = [],
  actions = [],
  selectable,
  onSelect,
  isSelected,
  isItemLoaded,
}) {
  const classes = useStyles();

  const row = data?.[index];

  const isItemSelected = isSelected?.(row?._id);

  const getValue = ({ name, options = {}, render }) => {
    const value = _.get(row, name) ?? "";

    if (render) {
      return <CustomCellRenderer value={value} row={row} render={render} />;
    }

    if (options?.getValue) return options?.getValue?.(value, row);
    if (options?.sentenceCase) return toSentenceCase(value);
    if (options?.capitalCase) return toCapitalCase(value);
    if (options?.date) return formatDate(value);
    if (options?.datetime) return formatDateTime(value);
    if (options?.month) return formatMonth(value);
    if (options?.money) return formatMoney(value);
    if (options?.number) return formatNumber(value);

    return value;
  };

  const getAlign = (options = {}) => {
    const { align, money } = options;
    if (money) return "right";
    return align ?? "left";
  };

  if (!isItemLoaded(index)) {
    return (
      <div style={style}>
        <div className={classes.row}>
          <Skeleton className={classes.skeleton} />
        </div>
        <Divider />
      </div>
    );
  }

  return (
    <div style={style}>
      <div className={classes.row}>
        {selectable && (
          <Checkbox
            checked={isItemSelected}
            onClick={() => onSelect(row?._id)}
            // disabled={row?.system}
          />
        )}
        {actions.length > 0 && (
          <div className={classes.actions}>
            {actions.map((action, index) => (
              <IconButton
                key={index}
                size="small"
                color="primary"
                onClick={() => action?.onClick?.(row)}
                // disabled={row?.system}
              >
                <Icon className={classes.icon}>{action.icon}</Icon>
              </IconButton>
            ))}
          </div>
        )}
        {columns.map((column, index) => (
          <div
            key={index}
            className={classes.column}
            style={{
              width: column?.options?.width ?? 100,
              textAlign: getAlign(column?.options),
            }}
          >
            <Typography variant="body2" noWrap component="div">
              {getValue(column)}
            </Typography>
          </div>
        ))}
      </div>
      <Divider />
    </div>
  );
}
