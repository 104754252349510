import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormHoc from "../../layouts/FormHoc";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Input, Checkbox } from "../../components/Formik";
import { FormActions } from "../../components/Forms";
import { useLazyQuery, API } from "../../api";
import { usePrefs } from "../../providers/Prefs";
import {
  INVENTORY,
  CONTACTS,
  SALES,
  PURCHASES,
  OPERATIONS,
  ACCOUNTING,
  MANAGE,
  MANUFACTURING,
  FLEET,
} from "./modules";

const useStyles = makeStyles((theme) => ({
  formContent: {
    background: "#F3F5F8",
    height: `calc(100vh - 60px)`,
    overflowY: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
  },
}));

function MyForm({
  onClose,
  initialValues,
  newItem,
  onKeyDown,
  snSuccess,
  snError,
}) {
  const [create, { data: data1 }] = useLazyQuery(API.CREATE_ROLE, {
    refetchQueries: [{ query: API.GET_ROLES }],
    onCompleted: () => snSuccess("Successfully Created Role"),
    onError: (err) => snError(err?.message),
  });
  const [update, { data: data2 }] = useLazyQuery(API.UPDATE_ROLE, {
    refetchQueries: [{ query: API.GET_ROLES }],
    onCompleted: () => snSuccess("Successfully Updated Role"),
    onError: (err) => snError(err?.message),
  });

  const data = data1 || data2;

  const classes = useStyles();
  const { withManufacturing, withFleetTracking } = usePrefs();

  React.useEffect(() => {
    if (data) onClose?.();
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = ({ _id, system, ...values }) => {
    if (newItem) {
      create({ data: values });
    } else {
      update({ params: { _id }, data: values });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={true}
      enableReinitialize={true}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
        fullAccess: Yup.object().shape({
          inventory: Yup.bool(),
          contacts: Yup.bool(),
          sales: Yup.bool(),
          purchases: Yup.bool(),
          accounting: Yup.bool(),
          manage: Yup.bool(),
          manufacturing: Yup.bool(),
          fleet: Yup.bool(),
          reports: Yup.bool(),
        }),
      })}
    >
      {({ dirty, isValid, values }) => (
        <Form onKeyDown={onKeyDown}>
          <div className={classes.formContent}>
            <DialogTitle>{`${newItem ? "New" : "Edit"} Role`}</DialogTitle>

            <DialogContent>
              <Input label="Role Name" name="name" width={300} />

              <Group name="Inventory">
                <Checkbox
                  name="fullAccess.inventory"
                  label="Enable full access for Inventory"
                  bottom={0}
                />
                {!values?.fullAccess?.inventory && (
                  <CrudGroup module={INVENTORY} />
                )}
              </Group>
              <Group name="Contacts">
                <Checkbox
                  name="fullAccess.contacts"
                  label="Enable full access for Contacts"
                  bottom={0}
                />
                {!values?.fullAccess?.contacts && (
                  <CrudGroup module={CONTACTS} />
                )}
              </Group>
              <Group name="Sales">
                <Checkbox
                  name="fullAccess.sales"
                  label="Enable full access for Sales"
                  bottom={0}
                />
                {!values?.fullAccess?.sales && <CrudGroup module={SALES} />}
              </Group>
              <Group name="Purchases">
                <Checkbox
                  name="fullAccess.purchases"
                  label="Enable full access for Purchases"
                  bottom={0}
                />
                {!values?.fullAccess?.purchases && (
                  <CrudGroup module={PURCHASES} />
                )}
              </Group>
              {withManufacturing && (
                <Group name="Manufacturing">
                  <Checkbox
                    name="fullAccess.manufacturing"
                    label="Enable full access for Manufacturing"
                    bottom={0}
                  />
                  {!values?.fullAccess?.manufacturing && (
                    <CrudGroup module={MANUFACTURING} />
                  )}
                </Group>
              )}
              {withFleetTracking && (
                <Group name="Fleet">
                  <Checkbox
                    name="fullAccess.fleet"
                    label="Enable full access for Fleet"
                    bottom={0}
                  />
                  {!values?.fullAccess?.fleet && <CrudGroup module={FLEET} />}
                </Group>
              )}
              <Group name="Operations">
                <Checkbox
                  name="fullAccess.operations"
                  label="Enable full access for Operations"
                  bottom={0}
                />
                {!values?.fullAccess?.operations && (
                  <CrudGroup module={OPERATIONS} />
                )}
              </Group>
              <Group name="Accounting">
                <Checkbox
                  name="fullAccess.accounting"
                  label="Enable full access for Accounting"
                  bottom={0}
                />
                {!values?.fullAccess?.accounting && (
                  <CrudGroup module={ACCOUNTING} />
                )}
              </Group>
              <Group name="Manage">
                <Checkbox
                  name="fullAccess.manage"
                  label="Enable full access for Manage"
                  bottom={0}
                />
                {!values?.fullAccess?.manage && <CrudGroup module={MANAGE} />}
              </Group>
              <Group name="Reports">
                <Checkbox
                  name="fullAccess.reports"
                  label="Enable full access for all reports"
                  bottom={0}
                />
              </Group>
              <Group name="Settings">
                <Checkbox
                  name="modules.settings.general.access"
                  label="General Settings"
                  bottom={0}
                />
                <Checkbox
                  name="modules.settings.numberFormats.access"
                  label="Number formats"
                  bottom={0}
                />
                <Checkbox
                  name="modules.settings.integrations.access"
                  label="Manage integrations"
                  bottom={0}
                />
                <Checkbox
                  name="modules.settings.features.access"
                  label="Manage features"
                  bottom={0}
                />
                <Checkbox
                  name="modules.settings.printing.access"
                  label="Printing"
                  bottom={0}
                />
              </Group>
            </DialogContent>
          </div>

          <FormActions
            newItem={newItem}
            onClose={onClose}
            dirty={dirty}
            isValid={isValid}
          />
        </Form>
      )}
    </Formik>
  );
}

export default FormHoc(MyForm);

const useStyles2 = makeStyles((theme) => ({
  group: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "240px 80px 80px 80px 80px",
    alignItems: "center",
    columnGap: 16,
  },
  center: {
    textAlign: "center",
  },
}));

function Group({ name, children }) {
  const classes = useStyles2();

  return (
    <Paper className={classes.group}>
      <Typography variant="button">{name}</Typography>
      <Divider className={classes.divider} />
      {children}
    </Paper>
  );
}

function CrudGroup({ module }) {
  const classes = useStyles2();

  return (
    <div>
      <div className={classes.container}>
        <Typography></Typography>
        <Typography variant="button" className={classes.center}>
          Read
        </Typography>
        <Typography variant="button" className={classes.center}>
          Create
        </Typography>
        <Typography variant="button" className={classes.center}>
          Update
        </Typography>
        <Typography variant="button" className={classes.center}>
          Delete
        </Typography>
      </div>
      {module?.map((item, index) => (
        <CrudPremission key={index} name={item.value} label={item.title} />
      ))}
    </div>
  );
}

function CrudPremission({ name, label }) {
  const classes = useStyles2();

  const { setFieldValue } = useFormikContext();

  const onReadChange = (e) => {
    if (!e.target.checked) {
      setFieldValue(`modules.${name}.create`, false);
      setFieldValue(`modules.${name}.update`, false);
      setFieldValue(`modules.${name}.delete`, false);
    }
  };

  const onCreateChange = (e) => {
    if (e.target.checked) {
      setFieldValue(`modules.${name}.read`, true);
    } else {
      setFieldValue(`modules.${name}.update`, false);
      setFieldValue(`modules.${name}.delete`, false);
    }
  };

  const onUpdateChange = (e) => {
    if (e.target.checked) {
      setFieldValue(`modules.${name}.read`, true);
      setFieldValue(`modules.${name}.create`, true);
    } else {
      setFieldValue(`modules.${name}.delete`, false);
    }
  };
  const onDeleteChange = (e) => {
    if (e.target.checked) {
      setFieldValue(`modules.${name}.read`, true);
      setFieldValue(`modules.${name}.create`, true);
      setFieldValue(`modules.${name}.update`, true);
    }
  };

  return (
    <div>
      <div className={classes.container}>
        <Typography>{label}</Typography>
        <div className={classes.center}>
          <Checkbox
            name={`modules.${name}.read`}
            onChange={onReadChange}
            bottom={0}
            left={16}
          />
        </div>
        <div className={classes.center}>
          <Checkbox
            name={`modules.${name}.create`}
            onChange={onCreateChange}
            bottom={0}
            left={16}
          />
        </div>
        <div className={classes.center}>
          <Checkbox
            name={`modules.${name}.update`}
            onChange={onUpdateChange}
            bottom={0}
            left={16}
          />
        </div>
        <div className={classes.center}>
          <Checkbox
            name={`modules.${name}.delete`}
            onChange={onDeleteChange}
            bottom={0}
            left={16}
          />
        </div>
      </div>
    </div>
  );
}
