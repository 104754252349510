import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { Checkbox } from ".";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  header: {
    height: 40,
  },
  items: {
    height: 40,
    border: "1px solid black",
  },
  center: {
    textAlign: "center",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
    paddingRight: theme.spacing(2),
  },
  border: {
    whiteSpace: "nowrap",
    borderTop: "1px solid #eee",
  },
}));

export default function ViewItems({ form, onChange, type = "sd" }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr className={classes.header}>
            <th>
              <Typography variant="button">Product Code</Typography>
            </th>
            <th className={classes.left}>
              <Typography variant="button">Description</Typography>
            </th>
            <th className={classes.center}>
              <Typography variant="button">In Stock</Typography>
            </th>
            <th>
              <Typography variant="button">Ordered Qty</Typography>
            </th>
            <th>
              <Typography variant="button">{`${type} Qty`}</Typography>
            </th>
            <th className={classes.center}>
              <Typography variant="button">Remaining Qty</Typography>
            </th>
            <th className={classes.center}>
              <Typography variant="button">Required</Typography>
            </th>
          </tr>
        </thead>

        <tbody>
          {form.values?.items?.map((item, index) => (
            <tr key={index} className={classes.items}>
              <td className={clsx(classes.center, classes.border)}>
                <Typography>{item?.product?.code}</Typography>
              </td>
              <td className={clsx(classes.border)}>
                <Typography>{item?.product?.description}</Typography>
              </td>
              <td className={clsx(classes.center, classes.border)}>
                <Typography>{item?.inStock ?? 0}</Typography>
              </td>
              <td className={clsx(classes.center, classes.border)}>
                <Typography>{item?.quantity ?? 0}</Typography>
              </td>
              <td className={clsx(classes.center, classes.border)}>
                <Typography>{item?.billedQty ?? 0}</Typography>
              </td>
              <td className={clsx(classes.center, classes.border)}>
                <Typography>
                  {(item?.quantity ?? 0) - (item?.billedQty ?? 0)}
                </Typography>
              </td>
              <td className={clsx(classes.right, classes.border)}>
                <Checkbox
                  name={`items[${index}].required`}
                  onChange={onChange}
                  disabled={(item?.billedQty ?? 0) >= (item?.quantity ?? 0)}
                  bottom={0}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
