import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Input } from ".";
import { formatMoney } from "../../utils/formatters";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "180px auto 140px 140px 140px 140px 40px",
    alignItems: "center",
    columnGap: 16,
    padding: 4,
    minHeight: 40,
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: 4,
  },
}));

export default function ReturnItems({ remove, form, products = [], priceKey }) {
  const classes = useStyles();

  const getValue = (index, key) => {
    const item = form.values.items[index];
    const product = products.find((t) => t._id === item.product);
    return _.get(product, key) ?? "";
  };

  const getBilledValue = (index, key) => {
    const item = form.values.items[index];
    return item[key];
  };

  const getDiscountLabel = () => {
    const type = form?.values?.items?.[0]?.discountType;
    return type === "value" ? "Disc" : "Disc (%)";
  };

  const getAmount = (index) => {
    const item = form.values.items[index];
    const key = priceKey?.split(".")?.[0];
    const price = _.get(item, key) ?? 0;
    const quantity = item.quantity ?? 0;
    const total = price * quantity;
    const discountType = item.discountType ?? "percent";
    const discountValue = item.discountValue ?? 0;
    const discount =
      discountType === "value" ? discountValue : total * (discountValue / 100);
    return total - discount;
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="button" className={classes.center}>
          Product Code
        </Typography>
        <Typography variant="button">Description</Typography>
        <Typography variant="button" className={classes.right}>
          Price
        </Typography>
        <Typography variant="button" className={classes.center}>
          Qty
        </Typography>
        <Typography variant="button" className={classes.center}>
          {getDiscountLabel()}
        </Typography>
        <Typography variant="button" className={classes.right}>
          Amount
        </Typography>
      </div>

      <Divider />

      {form.values?.items?.map((item, index) => (
        <React.Fragment key={index}>
          <div className={classes.container}>
            <Typography className={classes.center}>
              {getValue(index, "code")}
            </Typography>

            <Typography>{getValue(index, "description")}</Typography>

            <Typography className={classes.right}>
              {formatMoney(getBilledValue(index, priceKey))}
            </Typography>

            <Input
              name={`items[${index}].quantity`}
              type="number"
              bottom={0}
              inputProps={{ style: { textAlign: "center" } }}
            />

            <Input
              name={`items[${index}].discountValue`}
              type="number"
              bottom={0}
              inputProps={{ style: { textAlign: "center" } }}
            />

            <Typography className={classes.right}>
              {formatMoney(getAmount(index))}
            </Typography>

            <IconButton
              color="inherit"
              edge="start"
              onClick={() => remove(index)}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </div>

          <Divider />
        </React.Fragment>
      ))}

      <div className={classes.buttonContainer}>
        <Button color="primary" startIcon={<ClearAllIcon />}>
          Reset
        </Button>
      </div>
    </div>
  );
}
