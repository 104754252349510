import React from "react";
import { useField } from "formik";
import { KeyboardTimePicker } from "@material-ui/pickers";

function TimePicker({
  label,
  right = 0,
  bottom = 16,
  left = 0,
  top = 0,
  width = undefined,
  disableFuture,
  ...props
}) {
  const [{ value }, meta, { setValue }] = useField(props);

  return (
    <div
      style={{
        marginRight: right,
        marginBottom: bottom,
        marginLeft: left,
        marginTop: top,
        width,
      }}
    >
      <KeyboardTimePicker
        label={label}
        variant="inline"
        inputVariant="outlined"
        placeholder="08:00 AM"
        format="hh:mm a"
        mask="__:__ _M"
        size="small"
        autoOk={true}
        value={value}
        onChange={(d) => setValue(d)}
        error={(meta.touched && meta.error && true) || false}
        helperText={meta.touched && meta.error}
        style={{ width: "100%" }}
        disableFuture={disableFuture}
      />
    </div>
  );
}

export default TimePicker;
