import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubItem from "./SubItem";

const useStyles = makeStyles((theme) => ({
  item: {
    height: 40,
    color: "#D2D6DB",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
  },
  icon: {
    color: "#fff",
    fontSize: 20,
  },
  active: {
    color: "#669DF6",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function Item({ open, path, icon, label, subMenus = [] }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasSubMenu = subMenus?.length > 0;
  const isSamePath = location?.pathname === path;

  React.useEffect(() => {
    const _pathname = location?.pathname?.split("/")?.[1];
    const _path = path?.split("/")?.[1];
    if (_pathname === _path) setIsExpanded(true);
  }, [location, path]);

  const handleClick = (event) => {
    if (hasSubMenu) {
      if (open) {
        setIsExpanded((e) => !e);
        setAnchorEl(null);
      } else {
        handleOpen(event);
      }
    } else {
      history.push(path);
    }
  };

  const onMenuItemClick = (subPath) => {
    history.push(`${path}${subPath}`);
    handleClose();
  };

  return (
    <>
      <ListItem
        dense
        button
        onClick={handleClick}
        className={clsx(classes.item, isSamePath && classes.active)}
      >
        <ListItemIcon>
          <Icon className={classes.icon}>{icon}</Icon>
        </ListItemIcon>

        {open && (
          <ListItemText>
            <Typography variant="subtitle2">{label}</Typography>
          </ListItemText>
        )}
      </ListItem>

      {hasSubMenu && (
        <>
          {open ? (
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <List>
                {subMenus?.map((menu) => (
                  <SubItem {...menu} rootPath={path} key={menu.path} />
                ))}
              </List>
            </Collapse>
          ) : (
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {subMenus?.map((menu, index) => {
                const isSame = location?.pathname === `${path}${menu.path}`;

                return (
                  <MenuItem
                    key={index}
                    onClick={() => onMenuItemClick(menu?.path)}
                    selected={isSame}
                  >
                    {menu.label}
                  </MenuItem>
                );
              })}
            </Menu>
          )}
        </>
      )}
    </>
  );
}
