import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  icon_open: {
    color: "#fff",
    fontSize: 18,
    transform: "rotate(0deg)",
    transition: "0.3s",
  },
  icon_close: {
    color: "#fff",
    fontSize: 18,
    transform: "rotate(180deg)",
    transition: "0.3s",
  },
}));

export default function Footer({ open, toggle }) {
  const classes = useStyles();

  return (
    <IconButton onClick={toggle}>
      <Icon className={open ? classes.icon_open : classes.icon_close}>
        arrow_back_ios
      </Icon>
    </IconButton>
  );
}
