const MEASURES = ["unit", "mass", "volume", "length"];

const UNITS = [
  {
    abbr: "unit",
    measure: "unit",
    system: "metric",
    singular: "Unit",
    plural: "Units",
    factor: 1,
  },
  {
    abbr: "box",
    measure: "unit",
    system: "metric",
    singular: "Box",
    plural: "Boxes",
    factor: 1,
  },
  {
    abbr: "pack",
    measure: "unit",
    system: "metric",
    singular: "Pack",
    plural: "Packs",
    factor: 1,
  },
  {
    abbr: "pcs",
    measure: "unit",
    system: "metric",
    singular: "Piece",
    plural: "Pieces",
    factor: 1,
  },
  {
    abbr: "g",
    measure: "mass",
    system: "metric",
    singular: "Gram",
    plural: "Grams",
    factor: 1,
  },
  {
    abbr: "kg",
    measure: "mass",
    system: "metric",
    singular: "Kilogram",
    plural: "Kilograms",
    factor: 1000,
  },
  {
    abbr: "oz",
    measure: "mass",
    system: "imperial",
    singular: "Ounce",
    plural: "Ounces",
    factor: 28.3495,
  },
  {
    abbr: "lb",
    measure: "mass",
    system: "imperial",
    singular: "Pound",
    plural: "Pounds",
    factor: 453.592,
  },
  {
    abbr: "ml",
    measure: "volume",
    system: "metric",
    singular: "Milliliter",
    plural: "Milliliters",
    factor: 1 / 1000,
  },
  {
    abbr: "l",
    measure: "volume",
    system: "metric",
    singular: "Liter",
    plural: "Liters",
    factor: 1,
  },
  {
    abbr: "tsp",
    measure: "volume",
    system: "imperial",
    singular: "Teaspoon",
    plural: "Teaspoons",
    factor: 0.00591939,
  },
  {
    abbr: "Tbs",
    measure: "volume",
    system: "imperial",
    singular: "Tablespoon",
    plural: "Tablespoon",
    factor: 0.0177582,
  },
  {
    abbr: "fl-oz",
    measure: "volume",
    system: "imperial",
    singular: "Fluid Ounce",
    plural: "Fluid Ounces",
    factor: 0.0284131,
  },
  {
    abbr: "cup",
    measure: "volume",
    system: "imperial",
    singular: "Cup",
    plural: "Cups",
    factor: 0.284131,
  },
  {
    abbr: "pnt",
    measure: "volume",
    system: "imperial",
    singular: "Pint",
    plural: "Pints",
    factor: 0.568261,
  },
  {
    abbr: "qt",
    measure: "volume",
    system: "imperial",
    singular: "Quart",
    plural: "Quarts",
    factor: 1.13652,
  },
  {
    abbr: "gal",
    measure: "volume",
    system: "imperial",
    singular: "Gallon",
    plural: "Gallons",
    factor: 4.54609,
  },
  {
    abbr: "m",
    measure: "length",
    system: "metric",
    singular: "Meter",
    plural: "Meters",
    factor: 1,
  },
  {
    abbr: "cm",
    measure: "length",
    system: "metric",
    singular: "Centimeter",
    plural: "Centimeters",
    factor: 1,
  },
  {
    abbr: "in",
    measure: "length",
    system: "metric",
    singular: "Inch",
    plural: "Inches",
    factor: 0.00254,
  },
];

const getFactor = (unit) => {
  const found = UNITS.find((t) => t.abbr === unit);
  return (found && found.factor) || 0;
};

const isSameMeasure = (u, v) => {
  const unit1 = UNITS.find((t) => t.abbr === u);
  const unit2 = UNITS.find((t) => t.abbr === v);
  if (unit1?.measure && unit2?.measure) {
    return unit1?.measure === unit2?.measure;
  }
  return false;
};

const getPossibleUnits = (unit) => {
  const found = UNITS.find((t) => t.abbr === unit);
  return (
    (found &&
      UNITS.filter(
        (t) => t.measure === found.measure && t.system === found.system
      )) ||
    []
  );
};

const convert = (value, u, v) => {
  if (!isSameMeasure(u, v)) {
    return null;
  }
  const a = getFactor(u);
  const b = getFactor(v);
  const r = (value * a) / b;
  return r;
};

const getMeasureFromUnit = (unit) => {
  const found = UNITS.find((t) => t.abbr === unit);
  return found && found.measure;
};

const _convert = (value = 0) => ({
  measures: () => {
    return MEASURES;
  },

  units: () => {
    return UNITS;
  },

  list: (measure) => {
    if (measure) {
      return UNITS.filter((t) => t.measure === measure);
    } else {
      return UNITS;
    }
  },

  describe: (unit) => {
    return UNITS.find((t) => t.abbr === unit);
  },

  possibilities: (measure) => {
    if (measure) {
      return UNITS.filter((t) => t.measure === measure).map((t) => t.abbr);
    } else {
      return UNITS.map((t) => t.abbr);
    }
  },

  listUnits: (unit) => {
    const found = UNITS.find((t) => t.abbr === unit);
    return UNITS.filter((t) => t.measure === found?.measure);
  },

  from: (u) => ({
    to: (v) => {
      if (!isSameMeasure(u, v)) {
        return null;
      }
      const a = getFactor(u);
      const b = getFactor(v);
      const r = (value * a) / b;
      return r;
    },

    toBest: (options) => {
      const measure = getMeasureFromUnit(u);
      if (measure === "unit") {
        return { unit: u, val: value };
      }

      const p_units = getPossibleUnits(u);
      let unit = u;
      let val = value;
      for (let v of p_units) {
        const j = convert(value, u, v.abbr);
        const k = Math.abs(j);

        if (k > 1 && k < 1000) {
          unit = v.abbr;
          val = j;
          break;
        }
      }
      return { unit, val };
    },
  }),
});

export default _convert;
